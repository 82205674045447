<div *ngIf="!isPricesReady || hasChoices()" class="rmc-card rmc-card--thumbnail">
    <div class="rmc-card__thumbnail rmc-placeholder">
        <img *ngIf="isPricesReady" class="rmc-card__thumbnail-image" src="/images/airport-transfer.jpg">
    </div>

    <div class="rmc-card__price" *ngIf="isPricesReady && !hasSelection()">
        <div class="price-label">FROM</div>
        <div class="rmc-price">
            <span class="rmc-price__value">{{ getMinimumRate() | envCurrency }}</span>
            <span class="rmc-price__currency">{{currency.code}}</span>
        </div>
    </div>
    
    <div class="rmc-card__price--selected" *ngIf="isPricesReady && hasSelection()">
        {{ getTotalRate() | envCurrency }} {{currency.code}}
    </div>
    
    <div class="rmc-card__info">
        <div *ngIf="!isPricesReady" class="rmc-card__title rmc-placeholder rmc-placeholder--max-20">&nbsp;</div>
        <div *ngIf="isPricesReady" class="rmc-card__title">
            <rmc-phrase label="HEADLINE_CONFIGURE_TRANSFERS"></rmc-phrase>
        </div>
    
        <div *ngIf="!isPricesReady" class="rmc-card__description rmc-placeholder">&nbsp;</div>
        <div *ngIf="isPricesReady" class="rmc-card__description">
            <rmc-phrase label="BODY_CONFIGURE_TRANSFERS"></rmc-phrase>
        </div>
    
        <div *ngIf="!isPricesReady" class="rmc-placeholder rmc-placeholder--button">&nbsp;</div>
        <button *ngIf="isPricesReady" class="rmc-button rmc-button--neutral" type="button" (click)="openTransfers()">
            <rmc-phrase label="ACTION_CONFIGURE_TRANSFERS"></rmc-phrase>
        </button>
    </div>
</div>
