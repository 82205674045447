<!--
<div class="slds-p-bottom--medium">
    <div class="rmc-heading rmc-heading--m slds-m-bottom--medium">
        In-Travel Contact Information
    </div>

    <div class="help-text slds-m-bottom--medium">
        We need this information, in the unlikely event there is a change to your itinerary during travel.
    </div>

    <div class="rmc-form">
        <div class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.phone_number"
                label="Mobile Number While Travelling"
                [loader]="loaders.phone_number"
                [required]="requiredSchema.phone_number"
                [submitted]="submitted"
                [value]="bookingDetails.phone_number"
            >
                <input
                    class="rmc-input"
                    name="phone_number"
                    placeholder="+1 000-000-0000"
                    type="text"
		    [disabled]="billing_input_disabled"
                    [(ngModel)]="bookingDetails.phone_number"
                    (blur)="onChange('phone_number')"
                />
            </rmc-form-element>
        </div>
    </div>
</div>
-->
<div class="slds-p-bottom--medium">
    <div class="rmc-heading rmc-heading--m slds-m-bottom--medium">
        Billing Address
    </div>

    <div class="rmc-form">
        <div class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.first_name"
                label="First Name"
                [loader]="loaders.first_name"
                [required]="requiredSchema.first_name"
                [submitted]="submitted"
                [value]="bookingDetails.first_name"
            >
                <input
                    class="rmc-input"
                    name="first_name"
		    [disabled]="billing_input_disabled"
                    type="text"
                    [(ngModel)]="bookingDetails.first_name"
                    (blur)="onChange('first_name')"
                />
            </rmc-form-element>
        </div>

        <div class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.last_name"
                label="Last Name"
                [loader]="loaders.last_name"
                [required]="requiredSchema.last_name"
                [submitted]="submitted"
                [value]="bookingDetails.last_name"
            >
                <input
                    class="rmc-input"
                    name="last_name"
		    [disabled]="billing_input_disabled"
                    type="text"
                    [(ngModel)]="bookingDetails.last_name"
                    (blur)="onChange('last_name')"
                />
            </rmc-form-element>
        </div>

        <div class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.email"
                label="Email"
                [loader]="loaders.email"
                [required]="requiredSchema.email"
                [submitted]="submitted"
                [value]="bookingDetails.email"
            >
                <input
                    class="rmc-input"
                    type="text"
		    [disabled]="billing_input_disabled"
                    [(ngModel)]="bookingDetails.email"
                    (blur)="onChange('email')"
                />
            </rmc-form-element>
        </div>

        <div class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.street"
                label="Address"
                [loader]="loaders.address"
                [required]="requiredSchema.street"
                [submitted]="submitted"
                [value]="bookingDetails.street"
            >
                <input
                    class="rmc-input"
                    name="street"
                    type="text"
		    [disabled]="billing_input_disabled"
                    [(ngModel)]="bookingDetails.street"
                    (blur)="onChange('street')"
                />
            </rmc-form-element>
        </div>

        <div class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.city"
                label="City"
                [loader]="loaders.city"
                [required]="requiredSchema.city"
                [submitted]="submitted"
                [value]="bookingDetails.city"
            >
                <input
                    class="rmc-input"
                    name="city"
                    type="text"
		    [disabled]="billing_input_disabled"
                    [(ngModel)]="bookingDetails.city"
                    (blur)="onChange('city')"
                />
            </rmc-form-element>
        </div>

        <div class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.postal_code"
                label="Zip or Postal Code"
                [loader]="loaders.postal_code"
                [required]="requiredSchema.postal_code"
                [submitted]="submitted"
                [value]="bookingDetails.postal_code"
            >
                <input
                    class="rmc-input"
                    name="postal_code"
                    type="text"
		    [disabled]="billing_input_disabled"
                    [(ngModel)]="bookingDetails.postal_code"
                    (blur)="onChange('postal_code')"
                />
            </rmc-form-element>
        </div>

        <div class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.country"
                label="Country"
                [loader]="loaders.country"
                [required]="requiredSchema.country"
                [submitted]="submitted"
                [value]="bookingDetails.country"
            >
                <rmc-picklist
		    [disabled]="billing_input_disabled"
                    [options]="countryOptions"
                    [(value)]="bookingDetails.country"
                    (valueChange)="onCountryChange()"
                ></rmc-picklist>
            </rmc-form-element>
        </div>

        <div *ngIf="provinceOptions && provinceOptions.length" class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.province"
                label="Province or State"
                [loader]="loaders.province"
                [required]="isProvinceRequired()"
                [submitted]="submitted"
                [value]="bookingDetails.province"
            >
                <rmc-picklist
		    [disabled]="billing_input_disabled"
                    [options]="provinceOptions"
                    [(value)]="bookingDetails.province"
                    (valueChange)="onChange('province')"
                ></rmc-picklist>
            </rmc-form-element>
        </div>
    </div>
</div>
