<div *ngIf="isReady" class="rmc-card rmc-card--primary slds-m-bottom--medium">
    <div *ngIf="hasRequests()" class="slds-m-bottom--x-large">
        <div class="rmc-heading rmc-heading--m slds-m-bottom--medium">
            <rmc-phrase label="LABEL_REQUESTS"></rmc-phrase>
        </div>

        <div class="details">
            <div *ngFor="let room of rooms; let roomIndex = index; trackBy: trackByIndex" style="padding-bottom: 5px;">
                <div *ngIf="cruiseDetailsExists(cruiseDetails[roomIndex])" class="">
                    ROOM #{{roomIndex + 1}}
                </div>

                <div *ngIf="cruiseDetails[roomIndex]?.bedding">
                    <rmc-phrase label="LABEL_BEDDING_REQUEST"></rmc-phrase>:
                    {{cruiseDetails[roomIndex].bedding}}
                </div>
                <div *ngIf="cruiseDetails[roomIndex]?.cabin">
                    <rmc-phrase label="LABEL_CRUISE_CABIN_REQUEST"></rmc-phrase>:
                    {{cruiseDetails[roomIndex].cabin}}
                </div>
                <div *ngIf="cruiseDetails[roomIndex]?.meal_seating">
                    <rmc-phrase label="LABEL_CRUISE_MEAL_SEATING_REQUEST"></rmc-phrase>:
                    {{cruiseDetails[roomIndex].meal_seating}}
                </div>
            </div>
        </div>

        <div class="actions slds-m-top--x-small">
            <a href="javascript:void(0)" (click)="openDetails()">
                <rmc-phrase label="LABEL_EDIT_ROOM_AND_CRUISE_PREFERENCES"></rmc-phrase>
            </a>
        </div>
    </div>

    <div *ngFor="let room of rooms; let roomIndex = index; trackBy: trackByIndex">
        <rmc-review-details-room [guests]="room" [roomIndex]="roomIndex"></rmc-review-details-room>
    </div>

    <div class="slds-p-bottom--x-large">
        <div class="rmc-heading rmc-heading--m">
            <rmc-phrase label="LABEL_CONTACT_DETAILS"></rmc-phrase>
        </div>

        <div class="details slds-m-top--medium">
            <div *ngIf="bookingDetails.email">Email Address: {{bookingDetails.email}}</div>
            <div *ngIf="bookingDetails.phone_number">Phone Number: {{bookingDetails.phone_number}}</div>

            <div class="slds-m-top--small">
                <a href="javascript:void(0)" (click)="openDetails()">
                    <rmc-phrase label="LABEL_EDIT_CONTACT_DETAILS"></rmc-phrase>
                </a>
            </div>
        </div>
    </div>

    <div class="slds-p-bottom--x-large">
        <div class="rmc-heading rmc-heading--m">
            <rmc-phrase label="LABEL_ITINERARY"></rmc-phrase>
        </div>

        <div class="details slds-m-top--medium">
            <div>{{tourName}}</div>
            <div *ngIf="departureDate">{{departureDate | date: dateFormat}} – {{arrivalDate | date: dateFormat}}</div>
            <div>{{serviceLevelLabel}}</div>

            <div class="slds-m-top--small">
                <a href="javascript:void(0)" (click)="openItineraryModal()">
                    Review Full Itinerary
                </a>
            </div>
        </div>
    </div>

    <div *ngIf="isPricesReady && getPrices() && (getPrices().getDeposit() != getPrices().getTotal())" class="slds-p-bottom--medium">
        <div class="rmc-heading rmc-heading--m">
            <rmc-phrase label="LABEL_PAYMENT_OPTIONS"></rmc-phrase>
        </div>

        <div class="payment-options slds-m-top--medium">
            <div
                class="payment-option"
                [ngClass]="{
                    'payment-option--selected': (paymentDetails.deposit_type == 'minimum')
                }"
                (click)="selectDeposit()"
            >
                <div class="payment-option-name">
                    <rmc-phrase label="LABEL_PAY_DEPOSIT"></rmc-phrase>
                </div>
                <div class="payment-option-price">
                    <span class="payment-option-value">{{(getPrices() ? getPrices().getDeposit() : 0) | envCurrency}}</span>
                    <span class="payment-option-currency">{{currency.code}}</span>
                </div>
            </div>

            <div
                class="payment-option"
                [ngClass]="{
                    'payment-option--selected': (paymentDetails.deposit_type == 'full')
                }"
                (click)="selectTotal()"
            >
                <div class="payment-option-name">
                    <rmc-phrase label="LABEL_PAY_TOTAL"></rmc-phrase>
                </div>
                <div class="payment-option-price">
                    <span class="payment-option-value">{{(getPrices() ? getPrices().getTotal() : 0) | envCurrency}}</span>
                    <span class="payment-option-currency">{{currency.code}}</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="aeroplanStatus">
        <div><img src="https://viewfromthewing.com/wp-content/uploads/2020/08/Aeroplan_Primary_Logo_CMYK.jpg" style="height: 24px;" alt=""></div>
        <br />
        <div>Your Aeroplan membership has been confirmed and the Aeroplan points earned for your journey will be reflected in your Aeroplan account after your journey has been completed.</div>
        <br />
        <div *ngIf="aeroplanStatus == 'elite' || aeroplanStatus == 'premium'" style="color: orange;">
            <div *ngIf="aeroplanStatus == 'premium'">Your Aeroplan Elite Status or Aeroplan Premium credit card has qualified you for a rail upgrade.</div>
            <br />
            <div>Please reserve your booking below, and a Vacation Consultant will apply the Aeroplan upgrade offer and send you a confirmation email within 24 hours. This will show you your updated price breakdown as well as a  link to complete payment.</div>
        </div>

    </div>

    <br />

    <div class="slds-p-bottom--x-large">
        <div class="rmc-heading rmc-heading--m">
            <rmc-phrase label="HEADER_TERMS_AND_CONDITIONS"></rmc-phrase>
        </div>

        <div class="slds-m-top--medium">
            <rmc-form-element
                [required]="true"
                requiredMessage="You must accept terms and conditions to continue."
                [submitted]="isSubmitted()"
                [value]="conditionsCheckbox"
            >
                <rmc-checkbox [(value)]="conditionsCheckbox" (valueChange)="setConditions()">
                    I accept Rocky Mountaineer's <a [href]="conditionsUrl" target="_blank">Terms & Conditions</a>
                </rmc-checkbox>
            </rmc-form-element>
        </div>

        <div class="slds-m-top--medium">
            <rmc-form-element [required]="true">
                <rmc-checkbox [(value)]="offersCheckbox">
                    <rmc-phrase label="CHECKBOX_SEND_SPECIAL_OFFERS"></rmc-phrase>
                </rmc-checkbox>
            </rmc-form-element>
        </div>
    </div>

    <div *ngIf="aeroplanStatus != 'elite' && aeroplanStatus != 'premium'" class="slds-p-bottom--x-large">
        <button class="rmc-button rmc-button--brand" (click)="submit()">
            <rmc-button-bouncer *ngIf="isLoading()" [inline]="isCheckingAvailability()"></rmc-button-bouncer>
            <span *ngIf="isCheckingAvailability()">Checking Availabilities</span>

            <rmc-phrase *ngIf="!isLoading()" label="CTA_PAYMENT"></rmc-phrase>
        </button>
    </div>

    <div *ngIf="aeroplanStatus == 'elite' || aeroplanStatus == 'premium'" class="slds-p-bottom--x-large">
        <button class="rmc-button rmc-button--brand" (click)="reserve()">
            <rmc-button-bouncer *ngIf="isLoading()" [inline]="isCheckingAvailability()"></rmc-button-bouncer>
            <span *ngIf="isCheckingAvailability()">Checking Availabilities</span>

            <rmc-phrase *ngIf="!isLoading()" label="RESERVE MY BOOKING"></rmc-phrase>
        </button>
    </div>

</div>
