import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
    selector: 'rmc-input-date',
    templateUrl: './input-date.component.html',
    styleUrls: ['./input-date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateComponent implements OnChanges {

    @Input()
    disabled = false;

    @Input()
    maxYear: number = null;

    @Input()
    value: string;
    @Output()
    valueChange: EventEmitter<string> = new EventEmitter<string>();

    day: number;
    month: number;
    year: number;

    constructor() { }

    ngOnChanges() {
        this.init();
    }

    private buildDate(): string {
        const yearString = this.year != null ? String(this.year).padStart(4, '0') : null;
        const monthString = this.month != null ? String(this.month).padStart(2, '0') : null;
        const dayString = this.day != null ? String(this.day).padStart(2, '0') : null;

        return `${yearString}-${monthString}-${dayString}`;
    }

    private init(): void {
        if (this.value) {
            const [year, month, day] = this.value.split('-');
            this.year = Number(year);
            this.month = Number(month);
            this.day = Number(day);
            if (isNaN(this.year)) this.year = null;
            if (isNaN(this.month)) this.month = null;
            if (isNaN(this.day)) this.day = null;
        }
    }

    onChange(): void {
        if (this.year != null || this.month != null || this.day != null) {
            const value: string = this.buildDate();
            this.setValue(value);
        } else {
            this.setValue(null);
        }
    }

    private setValue(value): void {
        if (value !== this.value) {
            this.value = value;
            this.valueChange.emit(this.value);
        }
    }
}
