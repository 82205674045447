import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Account } from '../../helpers/auth';

import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'rmc-app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

    account: Account;
    fallbackRedirectLocation: string = environment.fallbackRedirectLocation;
    isMobileMenuOpen: boolean;
    isReady = false;
    isUserMenuOpen: boolean;

    constructor(
        private appService: AppService,
        private authService: AuthService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;
        this.account = this.authService.getAccount();
    }

    closeMobileMenu(): void {
        this.isMobileMenuOpen = false;
    }

    closeUserMenu(): void {
        this.isUserMenuOpen = false;
    }

    login(): void {
        this.authService.login();
    }

    logout(): void {
        this.authService.logout();
    }

    openMobileMenu(): void {
        this.isMobileMenuOpen = true;
    }

    openUserMenu(): void {
        this.isUserMenuOpen = true;
    }
}
