import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import Pusher from 'pusher-js';

import {environment} from '../../environments/environment';
import {
    Booking,
    BookingError,
    BookingPrices,
    BookingStatusUpdate,
    BookingUpdate,
    BookingUpdateEvent,
} from '../helpers/booking';
import {Choice, Choices} from '../helpers/choices';
import {dateStringToDate} from '../helpers/date';
import {ActivityItem, PackagePromotion, Transfer} from '../helpers/extensions';
import {formatEnumeration, UrlRoom} from '../helpers/occupancy';
import {ApiPackage, Package, packageApiToLocal} from '../helpers/package';
import {Currency, Prices} from '../helpers/prices';
import {
    BookingDetails,
    CruiseDetails,
    getObjectUpdate,
    PaymentDetails,
    SelectionCommons,
    Selections,
    SelectionCommonsError,
} from '../helpers/selections';
import {Traveller, TravellerCount} from '../helpers/traveller';
import { removeEmptyFields } from '../helpers/validation';

import {ApiService} from './api.service';
import {AuthService} from './auth.service';
import {GeoService} from './geo.service';

@Injectable({
    providedIn: 'root'
})
export class BookingService {

    public saveInProgress = false;

    private booking: Booking;
    private choices: Choices;
    private currency: Currency;
    private eventObserver: Subject<Array<BookingUpdateEvent>> = new Subject<Array<BookingUpdateEvent>>();
    private package: Package;
    private priceInitPromise: Promise<void>;
    private prices: Prices;
    private pusher: Pusher = new Pusher(environment.pusherKey, environment.pusherConfig);
    private serviceLevelsById: Map<string, any> = new Map<string, any>();

    private noSeatsModalSubject: Subject<void> = new Subject<void>();
    private noSeatsModalObservable: Observable<void> = this.noSeatsModalSubject.asObservable();

    public packagePromotionsFromChoices: PackagePromotion[] = [];
    public packagePromotionsFromBooking: PackagePromotion[] = [];
    public packagePromotionManualCodeWarning = 'a0';

    public promotionCode: string;
    public promotionCodeFromBooking: string;
    public waitingForPromotion = false;
    public isPromoCodeInvalid = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private authService: AuthService,
        private geoService: GeoService,
    ) {
        console.log('v0.020');
    }

    /**
     * Checks if seats are available for this booking.
     * @returns If seats are available for this booking.
     */
    async checkAvailability(): Promise<void> {
        await this.apiService.get(`${environment.apiUrl}bookings/bookings/${this.booking.id}/has-inventory/`);
    }

    /**
     * Parses URL query and creates a new booking according to the params passed.
     */
    private async createBooking(): Promise<void> {
        const packageId: string = this.getQueryParam('package');

        // If no package is defined, we redirect the user to the provider's
        // website.
        if (!packageId) {
            return Promise.reject('No package provided via `?package=`, redirect to homepage...');
        }

        const commons: SelectionCommons = {};
        const departureDate: string = this.getQueryParam('date');
        const serviceLevel: string = this.getQueryParam('service_level');
        const selections: Selections = {
            package: {
                package_id: packageId,
                service_level_id: serviceLevel,
                departure_date: departureDate
            }
        };


        const taxProfile: string = this.getQueryParam('tax_profile');
        if (taxProfile) {
            commons.tax_profile_id = taxProfile;
        }

        const currency: string = this.getQueryParam('currency');
        if (currency) {
            commons.currency = currency;
        }

        const country_code: string = this.getQueryParam('country_code');
        if (country_code) {
            commons.country_code = country_code;
        }

        const promo_code: string = this.getQueryParam('promo_code');
        commons.promotion_code = promo_code ? promo_code : null;


        const occupancy: string = this.getQueryParam('occupancy');
        if (occupancy) {
            const rooms: Array<Array<Traveller>> = this.parseUrlOccupancy(occupancy);
            commons.occupancies = rooms;
        }

        if (Object.keys(commons).length) {
            selections.commons = commons;
        }

        this.booking = await this.apiService.post(`${environment.apiUrl}bookings/bookings/`, {
            selections,
        });
    }

    /**
     * String representation for number of guests.
     * @param guestProfile Number of guests by their type.
     * @returns Number of guests string.
     */
    formatRoomOccupancy(guestProfile: TravellerCount): string {
        const stringParts: Array<string> = [];

        if (guestProfile.adults === 1) {
            stringParts.push('1 Adult');
        } else if (guestProfile.adults > 1) {
            stringParts.push(`${guestProfile.adults} Adults`);
        }

        return formatEnumeration(stringParts);
    }

    /**
     * Returns booking ID if booking is already initialized.
     * @returns Booking ID.
     */
    getBookingId() {
        return this.booking ? this.booking.id : null;
    }

    /**
     * Returns trip arrival date.
     * @param includeExtraNights If extra nights are included.
     * @returns Trip arrival date.
     */
    getArrivalDate(includeExtraNights: boolean = false): Date {
        const departureDate: Date = this.getDepartureDate();
        const arrivalDate: Date = new Date(
            departureDate.getFullYear(),
            departureDate.getMonth(),
            departureDate.getDate() + this.package.length,
        );

        if (includeExtraNights && this.booking.selections.extra_post_night) {
            const postNights: number = this.booking.selections.extra_post_night.count;

            return new Date(
                arrivalDate.getFullYear(),
                arrivalDate.getMonth(),
                arrivalDate.getDate() + postNights,
            );
        } else {
            return arrivalDate;
        }
    }

    /**
     * Returns booking object.
     * @returns Booking object.
     */
    getBooking(): Booking {
        return this.booking;
    }

    /**
     * Returns billing details.
     * @returns Billing details.
     */
    getBookingDetails(): BookingDetails {
        return this.getSelectionCommons().booking_details || {};
    }

    /**
     * Returns billing details.
     * @returns Billing details.
     */
     getAeroplanStatus(): string {
	/**
	 * If the package name doesn't match a YYYY RGR to start then we will allow the aeroplan
	 * details if present to render
	 */
        if(!/^[0-9]{4} RGR/.test(this.package.name)) {
        	return this.getSelectionCommons().aeroplan_summary_status;
	}
	return undefined;
    }

    /**
     * Returns booking extras options.
     * @returns Booking extras options.
     */
    getChoices(): Choices {
        return this.choices;
    }

    /**
     * Returns booking cruise details.
     * @returns Booking cruise details.
     */
    getCruiseDetails(): Array<CruiseDetails> {
        return this.getSelectionCommons().cruise_details_by_room || [];
    }

    /**
     * Returns payment currency.
     * @returns Payment currency.
     */
    getCurrency(): Currency {
        return this.currency;
    }

    /**
     * Returns trip departure date.
     * @param includeExtraNights If extra nights are included.
     * @returns Trip departure date.
     */
    getDepartureDate(includeExtraNights: boolean = false): Date {
        const departureDate: Date = dateStringToDate(this.booking.selections.package.departure_date);

        if (includeExtraNights && this.booking.selections.extra_pre_night) {
            const preNights: number = this.booking.selections.extra_pre_night.count;

            return new Date(
                departureDate.getFullYear(),
                departureDate.getMonth(),
                departureDate.getDate() - preNights,
            );
        } else {
            return departureDate;
        }
    }

    /**
     * Returns pre-night options.
     * @returns Pre-night options.
     */
    async getExtraPreNightChoices(): Promise<Array<Choice>> {
        return await this.apiService.get(
            `${environment.apiUrl}bookings/bookings/${this.booking.id}/extra-pre-night/`
        )
    }

    /**
     * Returns post-night options.
     * @returns Post-night options.
     */
    async getExtraPostNightChoices(): Promise<Array<Choice>> {
        return await this.apiService.get(
            `${environment.apiUrl}bookings/bookings/${this.booking.id}/extra-post-night/`
        )
    }

    /**
     * Returns insurance plan.
     * @returns Insurance plan.
     */
    getInsurancePlan(): string {
        const selections: Selections = this.getSelections();

        return selections.insurance ? selections.insurance.insurance_type : null;
    }

    /**
     * Returns list of guests by rooms.
     * @returns List of guests by rooms.
     */
    getOccupancy(): Array<Array<Traveller>> {
        return this.getSelectionCommons().occupancies || [];
    }

    /**
     * Returns package object.
     * @returns Package object.
     */
    getPackage(): Package {
        return this.package;
    }

    /**
     * Returns package ID.
     * @returns Package ID.
     */
    getPackageId(): string {
        return this.booking.selections.package.package_id;
    }

    /**
     * Returns paid amount value.
     * @returns Paid amount value.
     */
    getPaidAmount(): number {
        let result = 0;

        for (let payment of this.booking.payments) {
            if (payment.status != 200 && payment.status != 300) {
                continue;
            }
            if (payment.amount !== null) result += payment.amount;
        }

        return result;
    }

    /**
     * Returns payment details.
     * @returns Payment details.
     */
    getPaymentDetails(): PaymentDetails {
        return this.getSelectionCommons().payment_details;
    }

    /**
     * Returns payment URL.
     * @returns Payment URL.
     */
    getPaymentUrl(): string {
        return this.booking.payment_url;
    }

    /**
     * Returns number of post-nights.
     * @returns Number of post-nights.
     */
    getPostNights(): number {
        const selections: Selections = this.getSelections();

        return selections.extra_post_night ? selections.extra_post_night.count : 0;
    }

    /**
     * Returns number of pre-nights.
     * @returns Number of pre-nights.
     */
    getPreNights(): number {
        const selections: Selections = this.getSelections();

        return selections.extra_pre_night ? selections.extra_pre_night.count : 0;
    }

    hasPackagePromotionSelectionApp(): boolean {
        const choices: Choices = this.getChoices()
        return choices?.hasOwnProperty('package_promotion')
    }

    async applyPackagePromotions() {

        const selections: Selections = this.getSelections();
        const choices: Choices = this.getChoices()

        if (!choices['package_promotion']) {
            return;
        }



        this.packagePromotionsFromChoices = choices['package_promotion'][0].selections['package_promotion']['promotions'] || [];
        this.packagePromotionsFromBooking = selections.package_promotion?.promotions || [];

        this.packagePromotionManualCodeWarning = choices['package_promotion'][0].selections['commons']['promotion_code_warning'] || 'a0';

        const c_length = this.packagePromotionsFromChoices.length || 0;
        const b_length = this.packagePromotionsFromBooking.length || 0;

        let total_package_discount_from_choices = 0;
        let total_package_discount_from_booking = 0;
        for (const p of this.packagePromotionsFromChoices) {
            total_package_discount_from_choices = total_package_discount_from_choices + p.price_total;
        }
        for (const p of this.packagePromotionsFromBooking) {
            total_package_discount_from_booking = total_package_discount_from_booking + p.price_total;
        }

        console.log(b_length, c_length, total_package_discount_from_choices, total_package_discount_from_booking);

        if (c_length > 0 &&
            total_package_discount_from_choices !== total_package_discount_from_booking
        ) {
            selections.package_promotion = { promotions: this.packagePromotionsFromChoices };
            console.log('=== Apply Discount Promotoin:', this.packagePromotionsFromChoices);
            await this.updateSelections(selections);
            this.triggerEvent(BookingUpdateEvent.Insurance);
        } else if (c_length === 0 && b_length > 0) {
            delete selections.package_promotion;
            console.log('=== Remove Discount Promotoin');
            await this.updateSelections(selections);
            this.triggerEvent(BookingUpdateEvent.Insurance);
        } else  {
            console.log('=== Discount Promotoin already setup. Do nothing', selections.package_promotion);
        }
        this.initPromotionCodeFromBooking();
    }

    async promotionCodeChanged() {
        console.log('----------Promotion Code-----------')
        const code = this.promotionCode || null;
        const commons: SelectionCommons = this.getSelectionCommons();
        commons.promotion_code = code;
        this.waitingForPromotion = true;
        await this.updateCommons(commons);
        await this.initPrices();
        this.waitingForPromotion = false;
    }

    initPromotionCodeFromBooking() {

        const selections: Selections = this.getSelections();
        this.packagePromotionsFromBooking = selections.package_promotion?.promotions || [];
        this.promotionCode = this.getSelectionCommons().promotion_code;
        this.promotionCodeFromBooking = this.getSelectionCommons().promotion_code;

        let availablePromotionCodesFromBooking = [];
        for (const p of this.packagePromotionsFromChoices) {
            availablePromotionCodesFromBooking.push(p.promotion_code);
        }

        this.isPromoCodeInvalid = this.promotionCodeFromBooking &&
            availablePromotionCodesFromBooking.indexOf(this.promotionCodeFromBooking) < 0;

        console.log('this.isPromoCodeInvalid:', this.isPromoCodeInvalid)
    }

    /**
     * Returns extras pricing.
     * @returns Extras pricing object.
     */
    getPrices(): Prices {
        return this.prices;
    }

    /**
     * Returns URL query parameter value.
     * @param name URL query parameter name.
     * @returns URL query parameter value.
     */
    getQueryParam(name: string) {
        return this.activatedRoute.snapshot.queryParamMap.get(name);
    }

    /**
     * Returns rooms guests count by guest role.
     * @param unit List of room guests.
     * @returns Rooms guests count by guest role.
     */
    getRoomOccupancy(unit: Array<Traveller>): TravellerCount {
        let adults = 0;

        for (const traveller of unit) {
            switch (traveller.role) {
                case 'adult':
                    ++adults;
                    break;
            }
        }

        return {
            adults,
        };
    }

    /**
     * Returns booking commons.
     * @returns Booking commons object.
     */
    getSelectionCommons(): SelectionCommons {
        return this.getSelections().commons || {};
    }

    /**
     * Returns all booking selections.
     * @returns Booking selections object.
     */
    getSelections(): Selections {
        if (this.booking == undefined) {
            return null;
        }

        return JSON.parse(JSON.stringify(this.booking.selections));
    }

    /**
     * Returns booking service code.
     * @returns Booking service code.
     */
    getServiceCode(): string {
        return this.booking.service_code;
    }

    /**
     * Returns booking service level.
     * @returns Booking service level.
     */
    async getServiceLevelName(): Promise<string> {
        const serviceLevelId = this.booking.selections.package.service_level_id;
        if (!(serviceLevelId in this.serviceLevelsById)) {
            this.serviceLevelsById[serviceLevelId] = await this.apiService.get(`${environment.apiUrl}products/service-levels/${serviceLevelId}/`);
        }

        const name = this.serviceLevelsById[serviceLevelId].name;

        return this.package.category === 'Rail Only Package' ? name.replace(/ \+ Hotel$/i, '') : name;
    }

    /**
     * Returns booking status.
     * @returns Booking status.
     */
    getStatus(): number {
        return this.booking.status;
    }

    /**
     * Returns guest count by their roles.
     * @returns Guest count by their roles.
     */
    getTravellerCountByRole(): TravellerCount {
        const travellerCount: TravellerCount = {
            adults: 0,
        };

        for (const unit of this.booking.selections.commons.occupancies) {
            for (const traveller of unit) {
                switch (traveller.role) {
                    case 'adult':
                        ++travellerCount.adults;
                        break;
                }
            }
        }

        return travellerCount;
    }

    /**
     * Checks if package has 'Accommodation' component.
     * @returns If package has 'Accommodation' component.
     */
    hasAccommodation(): boolean {
        return this.hasComponent('Accommodation');
    }

    /**
     * Checks if any of the package components has the item of the type provided.
     * @param type Package component type to check.
     * @returns If any of the package components has the item of the type provided.
     */
    private hasComponent(type: string): boolean {
        for (let component of this.package.components) {
            if (component.item_type === type) {
                return true;
            }
        }

        return false;
    }

    /**
     * Checks if package has 'Cruise' component.
     * @returns If package has 'Cruise' component.
     */
    hasCruise(): boolean {
        if (!this.package) return false;

        return this.hasComponent('Cruise');
    }

    /**
     * Initializes service.
     */
    async init(): Promise<void> {
        await this.initBooking();
        this.initUpdateListener();
    }

    /**
     * Initializes booking data by retrieving existing booking or creating a new one if booking ID is not provided.
     */
    private async initBooking(): Promise<void> {

        // redirect from E-Xact payment Gateway with encripted parameters due to issue with url encoding
        const bb: string = this.getQueryParam('bb');
        if (bb) {
            console.log('BB Parameter found!')
            const qs = atob(bb);
            console.log('QS: ', qs);
            window.location.href = "/confirmation/?" + qs;
            return;
        }

        const bookingId: string = this.getQueryParam('booking');

        if (bookingId) {
            this.booking = await this.retrieveBooking(bookingId).catch((errorResponse: HttpErrorResponse) => {
                return Promise.reject(errorResponse.error);
            });
            this.initPromotionCodeFromBooking();
        } else {
            await this.createBooking();
        }

    }

    /**
     * Initializes payment currency data.
     */
    async initCurrency(): Promise<void> {
        const currencyCode: string = this.getSelectionCommons().currency;
        this.currency = await this.apiService.get(`${environment.apiUrl}bookings/currencies/${currencyCode}/`);
    }

    /**
     * Initializes package data.
     */
    async initPackage(): Promise<void> {
        const packageId: string = this.getPackageId();
        let apiPackage: ApiPackage;

        try {
            apiPackage = await this.apiService.get(`${environment.apiUrl}products/packages/${packageId}/`);
        } catch(e) {
            if (e.status === 404) {
                // If the package cannot be found, redirect to the provider's
                // website.
                return Promise.reject("The provided package does not exist, redirect to homepage...");
            }

            return Promise.reject("Didn't find the referenced package.")
        }

        this.package = packageApiToLocal(apiPackage);
    }

    /**
     * Initializes extras options, prices and price change events.
     */
    initPriceOptions(): Promise<void> {
        if (!this.priceInitPromise) {
            this.priceInitPromise = this.initPrices();

            this.listenEvents([
                BookingUpdateEvent.ExtraPostNights,
                BookingUpdateEvent.ExtraPreNights,
                BookingUpdateEvent.Insurance,
                BookingUpdateEvent.Occupancy,
                BookingUpdateEvent.PostActivity,
                BookingUpdateEvent.PostTransfer,
                BookingUpdateEvent.PreActivity,
                BookingUpdateEvent.PreTransfer,
                BookingUpdateEvent.Promotions,
                BookingUpdateEvent.TravellerDetails,
            ]).subscribe(() => {
                this.initPrices();
            });
        }

        return this.priceInitPromise;
    }

    /**
     * Initializes extras options and prices.
     */
    private async initPrices(): Promise<void> {
        const choicesPromise: Promise<void> = this.retrieveChoices([
            'extra_pre_night',
            'extra_post_night',
            'pre_transfer',
            'post_transfer',
            'insurance',
            'package_promotion'
        ]).then((choices: Choices) => {
            this.choices = choices;
            this.applyPackagePromotions();
        });

        const pricesPromise: Promise<void> = this.apiService.get(
            `${environment.apiUrl}bookings/bookings/${this.booking.id}/prices/`,
        ).then((prices: BookingPrices) => {
            this.prices = new Prices(prices);
        });

        await Promise.all([
            choicesPromise,
            pricesPromise,
            this.checkAvailability(),
        ]);

    }

    /**
     * Initializes external booking update event listener.
     */
    private initUpdateListener(): void {
        const channel: any = this.pusher.subscribe(`booking-${this.booking.id}`);

        channel.bind('update', async (update: BookingStatusUpdate) => {
            if (update.origin === this.authService.getClientId()) {
                return;
            }

            const booking: Booking = await this.retrieveBooking(this.booking.id);
            const events: Array<BookingUpdateEvent> = this.processUpdate(booking);

            if (events.length) {
                this.triggerEvents(events);
            }
        });
    }

    /**
     * Checks if the package has cruise or it has departure and arrival location in different countries.
     * @returns If the package is international.
     */
    isInternational(): boolean {
        const homeCountry: string = this.geoService.getHomeCountryName();

        return (
            this.package.arrivalLocation[3] !== homeCountry ||
            this.package.departureLocation[3] !== homeCountry ||
            this.hasCruise()
        );
    }

    /**
     * Checks if the package is already paid.
     * @returns If the package is already paid.
     */
    isPaid(): boolean {
        return this.booking.status >= 300;
    }

    /**
     * Listens bunch of events and trigges their handler.
     * @param targetEvents Events to listen.
     * @returns Event observable.
     */
    listenEvents(targetEvents: Array<BookingUpdateEvent>): Observable<void> {
        const groupObserver: Subject<void> = new Subject<void>();

        // handle all the triggered events
        this.eventObserver.subscribe((triggeredEvents: Array<BookingUpdateEvent>) => {
            let matches = false;

            // check if any target event is triggered
            for (const triggeredEvent of triggeredEvents) {
                if (targetEvents.includes(triggeredEvent)) {
                    matches = true;
                    break;
                }
            }

            // if any target event is triggered -> then trigger target event handlers
            if (matches) {
                groupObserver.next();
            }
        });

        return groupObserver.asObservable();
    }

    /**
     * 'No seats available' modal event
     */
    onNoSeatsModal(): Observable<void> {
        return this.noSeatsModalObservable;
    }

    /**
     * Opens 'No seats available' modal
     */
    openNoSeatsModal(): void {
        this.noSeatsModalSubject.next();
    }

    /**
     * Parses occupancy query string and converts it to list of guests by rooms.
     * @param urlOccupancy Occupancy query string.
     * @returns List of blank guests by rooms.
     */
    private parseUrlOccupancy(urlOccupancy: string): Array<Array<Traveller>> {
        const pairs: Array<string> = urlOccupancy.split(';');
        const urlRooms: Array<UrlRoom> = [];
        for (const pair of pairs) {
            const [key, value]: Array<string> = pair.split('=');
            const order: number = Number(key);
            const [adults, children]: Array<number> = value.split(',').map((count: string): number => {
                return Number(count);
            });

            const travellers: Array<Traveller> = [];

            for (let i = 0; i < adults; ++i) {
                travellers.push({
                    role: 'adult',
                });
            }

            for (let i = 0; i < children; ++i) {
                travellers.push({
                    role: 'child',
                });
            }

            urlRooms.push({
                order,
                travellers,
            });
        }

        const rooms: Array<Array<Traveller>> = urlRooms.sort((urlRoom1: UrlRoom, urlRoom2: UrlRoom): number => {
            return urlRoom1.order - urlRoom2.order;
        }).map(((urlRoom: UrlRoom): Array<Traveller> => {
            return urlRoom.travellers;
        }));

        return rooms;
    }

    /**
     * Handles external booking update and generates a list of update events to be triggered.
     * @param booking Updated booking object.
     * @returns List of events to be triggered.
     */
    private processUpdate(booking: Booking): Array<BookingUpdateEvent> {
        let occupanciesUpdated = false;
        if (booking.selections.commons.occupancies.length !== this.booking.selections.commons.occupancies.length) {
            occupanciesUpdated = true;
        } else { // same room count
            for (let roomIndex = 0; roomIndex < booking.selections.commons.occupancies.length; roomIndex++) {
                const newRoom: Array<Traveller> = booking.selections.commons.occupancies[roomIndex];
                const oldRoom: Array<Traveller> = this.booking.selections.commons.occupancies[roomIndex];

                if (newRoom.length !== oldRoom.length) {
                    occupanciesUpdated = true;
                    break;
                }
            }
        }

        const selectionsUpdate: Selections = getObjectUpdate(this.booking.selections, booking.selections);

        const events: Array<BookingUpdateEvent> = [];

        if (occupanciesUpdated) {
            events.push(BookingUpdateEvent.Occupancy);
        }

        if (selectionsUpdate.commons) {
            if ('booking_details' in selectionsUpdate.commons) {
                events.push(BookingUpdateEvent.BookingDetails);
            }

            if ('cruise_details_by_room' in selectionsUpdate.commons) {
                events.push(BookingUpdateEvent.CruiseDetails);
            }

            if ('occupancies' in selectionsUpdate.commons && !occupanciesUpdated) {
                events.push(BookingUpdateEvent.TravellerDetails);
            }

            if ('payment_details' in selectionsUpdate.commons) {
                events.push(BookingUpdateEvent.PaymentDetails);
            }

            if ('requires_accessibility_assistance' in selectionsUpdate.commons) {
                events.push(BookingUpdateEvent.Accessibility);
            }
        }

        if ('dinner' in selectionsUpdate || 'promotion' in selectionsUpdate) {
            events.push(BookingUpdateEvent.Promotions);
        }

        if ('extra_pre_night' in selectionsUpdate) {
            events.push(BookingUpdateEvent.ExtraPreNights);
        }

        if ('extra_post_night' in selectionsUpdate) {
            events.push(BookingUpdateEvent.ExtraPostNights);
        }

        if ('pre_activity' in selectionsUpdate) {
            events.push(BookingUpdateEvent.PreActivity);
        }

        if ('post_activity' in selectionsUpdate) {
            events.push(BookingUpdateEvent.PostActivity);
        }

        if ('insurance' in selectionsUpdate) {
            events.push(BookingUpdateEvent.Insurance);
        }

        if ('pre_transfer' in selectionsUpdate) {
            events.push(BookingUpdateEvent.PreTransfer);
        }

        if ('post_transfer' in selectionsUpdate) {
            events.push(BookingUpdateEvent.PostTransfer);
        }

        if (this.booking.status !== booking.status) {
            events.push(BookingUpdateEvent.Status);
        }

        this.booking = booking;

        return events;
    }

    /**
     * Removes post-transfer from extras selections.
     */
    async removePostTransfer(): Promise<void> {
        const selections: Selections = this.getSelections();
        delete selections.post_transfer;

        await this.updateSelections(selections);
        this.triggerEvent(BookingUpdateEvent.PostTransfer);
    }

    /**
     * Removes pre-transfer from extras selections.
     */
    async removePreTransfer(): Promise<void> {
        const selections: Selections = this.getSelections();
        delete selections.pre_transfer;

        await this.updateSelections(selections);
        this.triggerEvent(BookingUpdateEvent.PreTransfer);
    }

    /**
     * Retrieves actual booking data by booking ID.
     * @param id Booking ID.
     * @returns Booking object.
     */
    private async retrieveBooking(id: string): Promise<Booking> {
        return await this.apiService.get(`${environment.apiUrl}bookings/bookings/${id}/`);
    }

    /**
     * Rertieves booking extras options for the specific apps.
     * @param apps List of selections apps (extension types).
     * @param includePrices If prices are added to the response. Prices could be omitted for optimization purposes.
     * @returns Booking extras options for the specific apps.
     */
    async retrieveChoices(apps: Array<string>, includePrices: boolean = true): Promise<Choices> {
        const url: string = `${environment.apiUrl}bookings/bookings/${this.booking.id}/choices/?apps=${apps.join('|')}&include_prices=${String(includePrices)}`;
        const choices: Choices = await this.apiService.get(url);

        return choices;
    }

    /**
     * Sets and saves insurance plan.
     * @param plan Insurance plan.
     */
    async setInsurancePlan(plan: string): Promise<void> {
        const selections: Selections = this.getSelections();

        if (plan) {
            selections.insurance = {
                insurance_type: plan,
            };
        } else {
            delete selections.insurance;
        }

        await this.updateSelections(selections);
        this.triggerEvent(BookingUpdateEvent.Insurance);
    }

    /**
     * Sets and saves extra nights and activities.
     * @param preNights Number of pre-nights.
     * @param postNights Number of post-nights.
     * @param preActivity Pre-activity object.
     * @param postActivity Post-activity object.
     */
    async setExtensions(preNights: number, postNights: number, preActivity: ActivityItem = null, postActivity: ActivityItem = null): Promise<void> {
        const selections: Selections = this.getSelections();

        if (preNights) {
            selections.extra_pre_night = {
                count: preNights,
            };
        } else {
            delete selections.extra_pre_night;
        }

        if (postNights) {
            selections.extra_post_night = {
                count: postNights,
            };
        } else {
            delete selections.extra_post_night;
        }

        if (preActivity) {
            if (preActivity.id) {
                selections.pre_activity = {
                    items: [
                        preActivity,
                    ],
                };
            } else {
                delete selections.pre_activity;
            }
        }

        if (postActivity) {
            if (postActivity.id) {
                selections.post_activity = {
                    items: [
                        postActivity,
                    ],
                };
            } else {
                delete selections.post_activity;
            }
        }

        await this.updateSelections(selections);
        this.triggerEvents([
            BookingUpdateEvent.ExtraPostNights,
            BookingUpdateEvent.ExtraPreNights,
            BookingUpdateEvent.PostActivity,
            BookingUpdateEvent.PreActivity,
        ]);
    }

    /**
     * Sets and saves transfers.
     * @param preTransfer Pre-transfer object.
     * @param postTransfer Post-transfer object.
     */
    async setTransfers(preTransfer: Transfer, postTransfer: Transfer): Promise<void> {
        preTransfer = JSON.parse(JSON.stringify(preTransfer));
        postTransfer = JSON.parse(JSON.stringify(postTransfer));
        const selections: Selections = this.getSelections();

        if (preTransfer.id) {
            removeEmptyFields(preTransfer);
            selections.pre_transfer = preTransfer;
        } else {
            delete selections.pre_transfer;
        }

        if (postTransfer.id) {
            removeEmptyFields(postTransfer);
            selections.post_transfer = postTransfer;
        } else {
            delete selections.post_transfer;
        }

        await this.updateSelections(selections);
        this.triggerEvents([
            BookingUpdateEvent.PostTransfer,
            BookingUpdateEvent.PreTransfer,
        ]);
    }

    /**
     * Trigges the event provided.
     * @param event Event to trigger.
     */
    triggerEvent(event: BookingUpdateEvent): void {
        this.triggerEvents([event]);
    }

    /**
     * Triggers list of events provided.
     * @param events List of events to trigger.
     */
    triggerEvents(events: Array<BookingUpdateEvent>): void {
        this.eventObserver.next(events);
    }

    /**
     * Sets and saves accessibility flag value.
     * @param value Accessibility flag value.
     */
    async updateAccessibility(value: boolean): Promise<void> {
        const commons: SelectionCommons = this.getSelectionCommons();
        commons.requires_accessibility_assistance = value;

        return this.updateCommons(commons).then(() => {
            this.triggerEvent(BookingUpdateEvent.Accessibility);
        }).catch((error: SelectionCommonsError) => {
            return Promise.reject(error.booking_details);
        });
    }

    /**
     * Saves booking update.
     * @param bookingUpdate Booking fields to update.
     */
    private async updateBooking(bookingUpdate: BookingUpdate): Promise<void> {
        return this.apiService.patch(`${environment.apiUrl}bookings/bookings/${this.booking.id}/`, bookingUpdate).then((booking: Booking) => {
            this.booking = booking;
        }).catch((errorResponse: HttpErrorResponse) => {
            return Promise.reject(errorResponse.error);
        });
    }

    /**
     * Saves billing details.
     * @param bookingDetails Billing details.
     */
    async updateBookingDetails(bookingDetails: BookingDetails): Promise<void> {
        const bookingDetailsToSave: BookingDetails = JSON.parse(JSON.stringify(bookingDetails));
        removeEmptyFields(bookingDetailsToSave);

        const commons: SelectionCommons = this.getSelectionCommons();
        commons.booking_details = bookingDetailsToSave;

        return this.updateCommons(commons).then(() => {
            this.triggerEvent(BookingUpdateEvent.BookingDetails);
        }).catch((error: SelectionCommonsError) => {
            return Promise.reject(error.booking_details);
        });
    }

    /**
     * Saves booking commons.
     * @param commons Booking commons object.
     */
    private async updateCommons(commons: SelectionCommons): Promise<void> {
        const selections: Selections = this.getSelections();
        selections.commons = commons;

        return this.updateBooking({
            selections,
        }).then(() => {
            this.triggerEvent(BookingUpdateEvent.BookingDetails);
        }).catch((error: BookingError) => {
            return Promise.reject(error.selections.commons);
        });
    }

    /**
     * Saves cruise details.
     * @param cruiseDetails Cruise details object.
     */
    async updateCruiseDetails(cruiseDetails: CruiseDetails, idx: number): Promise<void> {
        const cruiseDetailsToSave: CruiseDetails = JSON.parse(JSON.stringify(cruiseDetails));
        removeEmptyFields(cruiseDetailsToSave);

        const commons: SelectionCommons = this.getSelectionCommons();

        if (!commons.cruise_details_by_room) {
            commons.cruise_details_by_room = [];
        }
        commons.cruise_details_by_room[idx] = Object.keys(cruiseDetailsToSave).length > 0 ? cruiseDetailsToSave : null;

        // fix for SF serialisator
        for (var i=0; i<commons.cruise_details_by_room.length; i++) {
            if (!commons.cruise_details_by_room[i] || commons.cruise_details_by_room[i] == null) {
                commons.cruise_details_by_room[i] = {};
            }
        }

        return this.updateCommons(commons).then(() => {
            this.triggerEvent(BookingUpdateEvent.CruiseDetails);
        }).catch((error: SelectionCommonsError) => {
            return Promise.reject(error.cruise_details_by_room);
        });
    }

    /**
     * Saves payment details.
     * @param paymentDetails Payment details object.
     */
    async updatePaymentDetails(paymentDetails: PaymentDetails): Promise<void> {
        const commons: SelectionCommons = this.getSelectionCommons();
        commons.payment_details = paymentDetails;

        return this.updateCommons(commons).then(() => {
            this.triggerEvent(BookingUpdateEvent.PaymentDetails);
        }).catch((error: SelectionCommonsError) => {
            return Promise.reject(error.payment_details);
        });
    }

    /**
     * Saves booking selections.
     * @param selections Booking selections object.
     */
    async updateSelections(selections: Selections): Promise<void> {
        return this.updateBooking({
            selections,
        }).then(() => {
            this.triggerEvent(BookingUpdateEvent.BookingDetails);
        }).catch((error: BookingError) => {
            return Promise.reject(error.selections);
        });
    }

    /**
     * Saves guest details.
     * @param roomIndex Room index value.
     * @param guestIndex Guest index value.
     * @param traveller Guest details.
     */
    async updateTraveller(roomIndex: number, guestIndex: number, traveller: Traveller): Promise<void> {
        const commons: SelectionCommons = this.getSelectionCommons();
        commons.occupancies[roomIndex][guestIndex] = traveller;

        return this.updateCommons(commons).then(() => {
            this.triggerEvent(BookingUpdateEvent.TravellerDetails);
        }).catch((error: SelectionCommonsError) => {
            return Promise.reject(error.occupancies[roomIndex][guestIndex]);
        });
    }

    async reserveBooking(): Promise<void> {

        console.log('RESERVE MY BOOKING');
        await this.apiService.post(`${environment.apiUrl}bookings/bookings/${this.booking.id}/reserve-my-booking/`, this.booking);

    }
}
