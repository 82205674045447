<div class="time" [ngClass]="{
    'disabled': disabled
}">
    <div>
        <input
            class="time-input rmc-input"
            [disabled]="disabled"
            type="text"
            [value]="hours"
            (input)="onInput($event, 'hours')"
            (change)="onHoursChange($event)"
        />
    </div>

    <div class="delimiter">
        :
    </div>

    <div>
        <input
            class="time-input rmc-input"
            [disabled]="disabled"
            type="text"
            [value]="minutes"
            (input)="onInput($event, 'minutes')"
            (change)="onMinutesChange($event)"
        />
    </div>

    <div class="period">
        <rmc-switch
            [disabled]="disabled"
            [options]="periodOptions"
            [(value)]="period"
            (valueChange)="onChange()"
        ></rmc-switch>
    </div>
</div>
