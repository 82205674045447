import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { Choice, Choices } from '../helpers/choices';
import { Dinner, DinnerItem, DinnerOption, Extensions, PrePost, Transfer } from '../helpers/extensions';
import { ProductItem } from '../helpers/products';
import {
    Addon,
    AddonResponse,
    Promotion,
    PromotionEffect,
    PromotionResponse,
    PromotionRule,
} from '../helpers/promotions';
import { Selections } from '../helpers/selections';

import { ApiService } from './api.service';
import { BookingService } from './booking.service';
import { ConfigurationService } from './configuration.service';
import { NavigationService } from './navigation.service';

@Injectable({
    providedIn: 'root'
})
export class PromotionsService {

    private dinnerOptions: Array<DinnerOption>;
    private initPromise: Promise<void>;
    private promotion: Promotion;
    private selectionOptions: Array<Selections>;

    private promotionalNightsWarningSource: Subject<void> = new Subject<void>();
    private promotionalNightsWarning: Observable<void> = this.promotionalNightsWarningSource.asObservable();

    constructor(
        private apiService: ApiService,
        private bookingService: BookingService,
        private configurationService: ConfigurationService,
        private navigationService: NavigationService,
    ) { }

    /**
     * Returns promotional dinner options.
     * @returns List of dinner options.
     */
    getDinnerOptions(): Array<DinnerOption> {
        return this.dinnerOptions;
    }

    /**
     * Returns dinner promotions available.
     * @returns List of dinner promotions available.
     */
    private getDinnerPromotions(): Array<PromotionEffect> {
        const promotions: Array<PromotionEffect> = [];

        for (const selectionOption of this.selectionOptions) {
            const promotion: PromotionEffect = this.getPromotionSelectionEffect('free_dinner', selectionOption);

            if (promotion) {
                promotions.push(promotion);
            }
        }

        return promotions;
    }

    /**
     * Returns dinner selection.
     * @returns Dinner selection.
     */
    private getDinnerSelection(): DinnerItem {
        const selections: Selections = this.bookingService.getSelections();
        const promotion: PromotionEffect = this.getPromotionSelectionEffect('free_dinner', selections);

        if (promotion) {
            const dinner: Dinner = promotion.configuration.dinner;
            return dinner.items[0];
        }
    }

    /**
     * Returns dinner selection ID.
     * @returns Dinner selection ID.
     */
    getDinnerSelectionId(): string {
        const dinnerItem: DinnerItem = this.getDinnerSelection();
        return dinnerItem ? dinnerItem.id : null;
    }

    /**
     * Returns number of free extra nights available for the type specified.
     * @param type 'pre'|'post'.
     * @returns Number of free extra nights available.
     */
    getExtraNightsLimit(type: string): number {
        const key = `extra_${type}_night`;
        let result = 0;

        for (const selection of this.selectionOptions) {
            if (selection.promotion) {
                for (const effect of selection.promotion.promotion_effects) {
                    if (effect.configuration[key]) {
                        result = Math.max(result, effect.configuration[key].count);
                    }
                }
            }
        }

        return result;
    }

    /**
     * Returns extra nights selection.
     * @returns Extra nights selection.
     */
    getExtraNightsSelection(): PrePost<number> {
        const selections: Selections = this.bookingService.getSelections();
        const promotion: PromotionEffect = (
            this.getPromotionSelectionEffect('2_free_nights', selections) ||
            this.getPromotionSelectionEffect('1_free_night', selections)
        );

        const extraNights: PrePost<number> = {
            post: 0,
            pre: 0,
        };

        if (promotion) {
            if (promotion.configuration.extra_pre_night) {
                extraNights.pre = promotion.configuration.extra_pre_night.count;
            }

            if (promotion.configuration.extra_post_night) {
                extraNights.post = promotion.configuration.extra_post_night.count;
            }
        }

        return extraNights;
    }

    /**
     * Returns total (pre+post) free nights limit.
     * @returns Total (pre+post) free nights limit.
     */
    getExtraNightsTotalLimit(): number {
        if (this.isPromotionAvailable('2_free_nights')) {
            return 2;
        } else if (this.isPromotionAvailable('1_free_night')) {
            return 1;
        } else {
            return 0;
        }
    }

    /**
     * Checks if there are a promotion effect of type and returns it.
     * @param type Promotion effect type.
     * @returns Promotion effect object.
     */
    private getPromotionEffect(type: string): PromotionEffect {
        const result: PromotionEffect = this.promotion.promotion_effects.find((promotionEffect: PromotionEffect): boolean => {
            return promotionEffect.properties === type;
        });

        return result ? JSON.parse(JSON.stringify(result)) : null;
    }

    /**
     * Returns promotion expiration date string.
     * @returns Promotion expiration date string.
     */
    getPromotionExpiration(): string {
        if (!this.promotion) {
            return null;
        }

        const dateRule: PromotionRule = this.promotion.promotion_rules.find((rule: PromotionRule) => {
            return (
                rule.field === 'booking_date' &&
                rule.operator === '<='
            );
        });

        return dateRule?.promotion_rule_operands[0].operand;
    }

    /**
     * Returns promotion name.
     * @returns Promotion name.
     */
    getPromotionLabel(): string {
        return this.promotion ? this.promotion.external_name : null;
    }

    /**
     * Gets promotional effect from the selections object.
     * @param type Promotion effect type.
     * @param selection Selections object.
     * @returns Promotion effect found.
     */
    private getPromotionSelectionEffect(type: string, selection: Selections): PromotionEffect {
        if (selection.promotion) {
            for (const effect of selection.promotion.promotion_effects) {
                if (effect.properties === type) {
                    return JSON.parse(JSON.stringify(effect));
                }
            }
        }
    }

    /**
     * Returns free transfer options.
     * @returns Free transfer options.
     */
    getTransferOptions(): PrePost<Transfer> {
        const transferOptions: PrePost<Transfer> = {};

        for (const selectionOption of this.selectionOptions) {
            if (!selectionOption.promotion) {
                continue;
            }

            for (const effect of selectionOption.promotion.promotion_effects) {
                if (effect.properties === 'free_transfer') {
                    if (effect.configuration.pre_transfer) {
                        transferOptions.pre = effect.configuration.pre_transfer;
                    }

                    if (effect.configuration.post_transfer) {
                        transferOptions.post = effect.configuration.post_transfer;
                    }
                }
            }
        }

        return JSON.parse(JSON.stringify(transferOptions));
    }

    /**
     * Returns free transfer selection.
     * @returns Free transfer selection.
     */
    getTransferSelection(): string {
        const selections: Selections = this.bookingService.getSelections();
        const promotion: PromotionEffect = this.getPromotionSelectionEffect('free_transfer', selections);

        if (!promotion) {
            return null;
        }

        return promotion.configuration.pre_transfer ? 'pre' : 'post';
    }

    /**
     * Checks if there are any free dinner options.
     * @returns If there are free dinner options.
     */
    hasDinnerOptions(): boolean {
        return this.getDinnerOptions().length > 0;
    }

    /**
     * Checks if there are any free transfer options.
     * @returns If there are free transfer options.
     */
    hasTransferOptions(): boolean {
        return Object.keys(this.getTransferOptions()).length > 0;
    }

    /**
     * Checks if there are any promotion selection.
     * @returns If there are promotion selection.
     */
    hasSelection(): boolean {
        const selection: Selections = this.bookingService.getSelections();
        return Boolean(selection.promotion);
    }

    /**
     * Checks if service data is already initiazed and initializes it if needed.
     */
    async init(): Promise<void> {
        this.initPromise = this.initPromise || this.initData();
        await this.initPromise;
    }

    /**
     * Initializes service data.
     */
    private async initData(): Promise<void> {
        await this.initOptions();

        await Promise.all([
            this.initPromotion(),
            this.initDinnerOptions(),
        ]);

        if (this.navigationService.isRoute('/configure')) {
            if (
                this.isPromotionsAvailable() &&
                !this.hasSelection()
            ) {
                this.configurationService.openPromotions();
            }
        }
    }

    /**
     * Initializes free dinner options.
     */
    private async initDinnerOptions(): Promise<void> {
        const dinnerPromotions: Array<PromotionEffect> = this.getDinnerPromotions();

        const dinnerMap: {[optionId: string]: Dinner} = {};
        for (const dinnerPromotion of dinnerPromotions) {
            const dinner: Dinner = dinnerPromotion.configuration.dinner;
            dinnerMap[dinner.items[0].id] = dinner;
        }

        const ids: string = Object.values(dinnerMap).map((dinner: Dinner): string => {
            return dinner.items[0].id;
        }).join('|');

        const url = `${environment.apiUrl}products/add-ons/?ids=${ids}`;
        const addonResponse: AddonResponse = await this.apiService.get(url);

        this.dinnerOptions = addonResponse.results.map((addon: Addon): DinnerOption => {
            const product: ProductItem = addon.item;

            return {
                date: dinnerMap[addon.id].items[0].date,
                id: dinnerMap[addon.id].items[0].id,
                name: product.address.split(', ').reverse()[2],
            };
        });
    }

    /**
     * Initializes promotion options.
     */
    private async initOptions(): Promise<void> {
        const choices: Choices = await this.bookingService.retrieveChoices(['promotion'], false);

        this.selectionOptions = choices.promotion.map(
            (promotionChoice: Choice): Selections => {
                return promotionChoice.selections;
            }
        );
    }

    /**
     * Initializes promotion info.
     */
    private async initPromotion(): Promise<void> {
        const promotionEffectIdSet: Set<string> = new Set<string>();

        for (const selection of this.selectionOptions) {
            if (selection.promotion) {
                for (const effect of selection.promotion.promotion_effects) {
                    promotionEffectIdSet.add(effect.id);
                }
            }
        }

        if (!promotionEffectIdSet.size) {
            return;
        }

        const ids: string = Array.from(promotionEffectIdSet).join('|');
        const url = `${environment.apiUrl}products/promotions/?promotion_effect_ids=${ids}`;

        const response: PromotionResponse = await this.apiService.get(url);
        this.promotion = response.results[0];
    }

    /**
     * Checks if there is any promotion of type.
     * @param type Promotion effect type.
     * @returns If there is any promotion of type.
     */
    private isPromotionAvailable(type: string): boolean {
        for (const selection of this.selectionOptions) {
            if (selection.promotion) {
                for (const effect of selection.promotion.promotion_effects) {
                    if (effect.properties === type) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    /**
     * Checks if there are any promotion options available.
     * @returns If there are promotion options available.
     */
    isPromotionsAvailable(): boolean {
        for (const selection of this.selectionOptions) {
            if (selection.promotion) {
                return true;
            }
        }

        return false;
    }

    /**
     * 'Extra nights cannot be removed' modal open event.
     */
    onPromotionalNightsWarning(): Observable<void> {
        return this.promotionalNightsWarning;
    }

    /**
     * Removes free dinner selection.
     */
    async removeDinner(): Promise<void> {
        await this.removeSelections(null, true, false);
    }

    /**
     * Removes free extra nights selection.
     * @param type 'pre'|'post'.
     */
    async removeNights(type: string): Promise<void> {
        await this.removeSelections({
            post: (type === 'post'),
            pre: (type === 'pre'),
        }, false, false);
    }

    /**
     * Removes promotion selections specified.
     * @param removeNights If free extra nights should be removed.
     * @param removeDinner If free dinner should be removed.
     * @param removeTransfer If free transfer should be removed.
     */
    private async removeSelections(removeNights: PrePost<boolean>, removeDinner: boolean, removeTransfer: boolean): Promise<void> {
        const nights: PrePost<number> = this.getExtraNightsSelection();

        for (const type of ['pre', 'post']) {
            if (removeNights && removeNights[type]) {
                nights[type] = 0;
            }
        }

        const dinnerLocation: string = this.getDinnerSelectionId();

        const transferType: string = this.getTransferSelection();
        let transfer: Transfer;

        if (transferType) {
            const selections: Selections = this.bookingService.getSelections();
            const key = `${transferType}_transfer`;

            transfer = selections[key] || null;
        } else {
            transfer = null;
        }

        await this.setPromotion(
            {
                post: removeNights && removeNights.post ? 0 : nights.post,
                pre: removeNights && removeNights.pre ? 0 : nights.pre,
            },
            removeDinner ? null : dinnerLocation,
            removeTransfer ? null : transfer,
        );
    }

    /**
     * Removes free transfer selection.
     */
    async removeTransfer(): Promise<void> {
        await this.removeSelections(null, false, true);
    }

    /**
     * Saves promotion selection.
     * @param nights Promotion extra nights count.
     * @param dinnerLocation Promotion dinner location.
     * @param transfer Promotion transfer object.
     */
    async setPromotion(nights: PrePost<number>, dinnerLocation: string, transfer: Transfer): Promise<void> {
        const selections: Selections = this.bookingService.getSelections();
        selections.promotion = {
            promotion_effects: [],
        };

        this.setPromotionNights(selections, nights);
        this.setPromotionDinner(selections, dinnerLocation);
        this.setPromotionTransfer(selections, transfer);

        if (!selections.promotion.promotion_effects.length) {
            delete selections.promotion;
        }

        await this.bookingService.updateSelections(selections);
    }

    /**
     * Adds promotion dinner config to the booking selections.
     * @param newSelections Booking selections object.
     * @param dinnerLocation Promotion dinner location.
     */
    private setPromotionDinner(newSelections: Selections, dinnerLocation: string): void {
        if (dinnerLocation) {
            const dinnerOption: DinnerOption = this.dinnerOptions.find((option: DinnerOption): boolean => {
                return option.id === dinnerLocation;
            });

            const dinnerEffect: PromotionEffect = this.getPromotionEffect('free_dinner');
            const dinner: Dinner = {
                items: [
                    {
                        date: dinnerOption.date,
                        id: dinnerOption.id,
                    },
                ],
            };

            dinnerEffect.configuration = {
                dinner,
            };

            newSelections.promotion.promotion_effects.push(dinnerEffect);
            newSelections.dinner = dinner;
        } else {
            // if there was promotion dinner previously -> then remove the dinner from selections
            delete newSelections.dinner;
        }
    }

    /**
     * Adds promotion extra nights config to the booking selections.
     * @param newSelections Booking selections object.
     * @param promotionNights Promotion extra nights count.
     */
    private setPromotionNights(newSelections: Selections, promotionNights: PrePost<number>): void {
        if (promotionNights.pre || promotionNights.post) {
            const config: Extensions = {};

            if (promotionNights.pre) {
                config.extra_pre_night = {
                    count: promotionNights.pre,
                };
            }

            if (promotionNights.post) {
                config.extra_post_night = {
                    count: promotionNights.post,
                };
            }

            const nightsEffect: PromotionEffect = (
                this.getPromotionEffect('2_free_nights') ||
                this.getPromotionEffect('1_free_night')
            );

            nightsEffect.configuration = config;

            newSelections.promotion.promotion_effects.push(nightsEffect);
        }

        const oldSelections: Selections = this.bookingService.getSelections();
        const oldNights: PrePost<number> = {
            post: oldSelections.extra_post_night ? oldSelections.extra_post_night.count : 0,
            pre: oldSelections.extra_pre_night ? oldSelections.extra_pre_night.count : 0,
        };

        const oldNightsEffect: PromotionEffect = (
            this.getPromotionSelectionEffect('2_free_nights', oldSelections) ||
            this.getPromotionSelectionEffect('1_free_night', oldSelections)
        );
        const oldPromotionNights: PrePost<number> = {
            post: 0,
            pre: 0,
        };

        if (oldNightsEffect) {
            if (oldNightsEffect.configuration.extra_pre_night) {
                oldPromotionNights.pre = oldNightsEffect.configuration.extra_pre_night.count;
            }

            if (oldNightsEffect.configuration.extra_post_night) {
                oldPromotionNights.post = oldNightsEffect.configuration.extra_post_night.count;
            }
        }

        for (const type of ['pre', 'post']) {
            const key = `extra_${type}_night`;

            if (promotionNights[type] > oldPromotionNights[type]) {
                newSelections[key] = {
                    count: Math.max(oldNights[type], promotionNights[type]),
                };
            } else {
                const decrement: number = oldPromotionNights[type] - promotionNights[type];
                const count: number = oldNights[type] - decrement;

                if (count > 0) {
                    newSelections[key] = {
                        count,
                    };
                } else {
                    delete newSelections[key];
                }
            }
        }
    }

    /**
     * Adds promotion transfer config to the booking selections.
     * @param newSelections Booking selections object.
     * @param transfer Promotion transfer object.
     */
    private setPromotionTransfer(newSelections: Selections, transfer: Transfer): void {
        const oldSelections: Selections = this.bookingService.getSelections();
        const oldTransferEffect: PromotionEffect = this.getPromotionSelectionEffect('free_transfer', oldSelections);

        // if there was promotion transfer previously -> then remove the transfer from selections
        if (oldTransferEffect) {
            const oldTransferType: string = oldTransferEffect.configuration.pre_transfer ? 'pre_transfer' : 'post_transfer';
            delete newSelections[oldTransferType];
        }

        if (transfer) {
            const transferEffect: PromotionEffect = this.getPromotionEffect('free_transfer');
            const transferType: string = transfer.direction === 'from_airport_to_hotel' ? 'pre_transfer' : 'post_transfer';

            transferEffect.configuration = {
                [transferType]: {
                    direction: transfer.direction,
                    id: transfer.id,
                },
            };

            newSelections.promotion.promotion_effects.push(transferEffect);

            if (!newSelections[transferType]) {
                newSelections[transferType] = transfer;
            }
        }
    }

    /**
     * Opens 'Extra nights cannot be removed' modal.
     */
    showPromotionalNightsWarning(): void {
        this.promotionalNightsWarningSource.next();
    }
}
