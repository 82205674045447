<div *ngIf="isReady" class="rmc-card rmc-card--primary slds-m-bottom--medium">
    <div *ngIf="!requiresAccessibilityAssistance()" class="slds-p-bottom--medium">
        <div class="help-text slds-m-bottom--medium">
            All fields are required, unless indicated as optional.
        </div>
    </div>

    <rmc-guests-details-accessibility></rmc-guests-details-accessibility>

    <div *ngIf="!requiresAccessibilityAssistance()">
        <div class="rmc-form">
            <div *ngFor="let room of rooms; let roomIndex = index; trackBy: trackByIndex">

                <rmc-guests-details-room *ngIf="!requiresAccessibilityAssistance()" [submitted]="isSubmitted()" [roomIdx]="roomIndex"></rmc-guests-details-room>

                <div *ngFor="let guest of room; let guestIndex = index; trackBy: trackByIndex">
                    <rmc-guest
                            [autosave]="true"
                            [guestIndex]="guestIndex"
                            [roomIndex]="roomIndex"
                            [(requestCounter)]="guestDetailsRequestCounter"
                            (guestUpdated)="guestUpdated($event, roomIndex, guestIndex)"
                            [submitted]="isSubmitted()"
                            [traveller]="rooms[roomIndex][guestIndex]"
                    ></rmc-guest>
                </div>
            </div>
        </div>

        <rmc-guests-details-billing
            [submitted]="isSubmitted()"
            (billingDetailsUpdated)="billingDetailsUpdated($event)"
            ></rmc-guests-details-billing>

        <div class="slds-p-bottom--medium">
            <button class="rmc-button rmc-button--brand" (click)="submit()" [disabled]="isLoading() || bookingService.saveInProgress || !guestDetailsService.isFormValid">
                <rmc-button-bouncer *ngIf="isLoading() || bookingService.saveInProgress"></rmc-button-bouncer>
                <rmc-phrase *ngIf="!isLoading() && !bookingService.saveInProgress" label="CTA_REVIEW"></rmc-phrase>
            </button>
        </div>
    </div>
</div>
