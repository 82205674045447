export interface PicklistOption {
    label: string;
    value: any;
}

export interface PicklistOptionLabelPart {
    match: boolean;
    text: string;
}

export interface PicklistOptionModel {
    label: string;
    labelParts: Array<PicklistOptionLabelPart>;
    value: any;
}

export const emptyOptionLabel = '- None -';

export const emptyOption: PicklistOption = {
    label: emptyOptionLabel,
    value: undefined,
};

export function escapeRegExp(regex: string) {
    return regex.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function sortOptions(options: Array<PicklistOption>): Array<PicklistOption> {
    return options.sort((option1: PicklistOption, option2: PicklistOption): number => {
        return option1.label.localeCompare(option2.label);
    });
}
