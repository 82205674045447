import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { isEmpty } from '../../helpers/validation';

@Component({
    selector: 'rmc-form-element',
    templateUrl: './form-element.component.html',
    styleUrls: ['./form-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormElementComponent implements OnChanges {

    @Input()
    disabled = false;

    @Input()
    errors: string;

    @Input()
    label: string;

    @Input()
    loader = false;

    @Input()
    required = false;

    @Input()
    requiredMessage: string;

    @Input()
    submitted: boolean;

    @Input()
    value: any;

    constructor() { }

    ngOnChanges() {
    }

    isError(): boolean {
        return !this.loader && ((this.isRequiredError() && this.submitted) || Boolean(this.errors));
    }

    isErrorMessage(): boolean {
        return !this.loader && !this.isRequiredError() && Boolean(this.errors);
    }

    private isRequiredError(): boolean {
        return this.required && !this.isValueSet();
    }

    isRequiredMessage(): boolean {
        return this.isRequiredError() && this.submitted;
    }

    isSuccess(): boolean {
        return !this.loader && this.isValueSet() && !this.errors;
    }

    private isValueSet(): boolean {
        return !(isEmpty(this.value) || this.value === false);
    }

    trackByIndex(index: number): number {
        return index;
    }
}
