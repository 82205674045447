<header>
    <div class="container rmc-container">
        <div class="main">
            <a [href]="fallbackRedirectLocation" title="Rocky Mountaineer Home">
                <img class="logo" src="/images/logo-rm.svg" alt="Rocky Mountaineer Logo">
            </a>

            <div class="links">
                <a class="link" href="https://www.rockymountaineer.com/faq" target="_blank">FAQ</a>
                <a class="link" href="https://www.rockymountaineer.com/contact-us" target="_blank">Contact Us</a>
            </div>
        </div>

        <div *ngIf="isReady && false" class="actions">
            <div *ngIf="account" class="auth">
                <button class="auth-button" type="button" (click)="openUserMenu()">
                    <svg *ngIf="account.isEditor" class="rmc-icon rmc-icon--s rmc-icon--button" rmcSldsIcon="favorite"></svg>
                    <span class="auth-button__text">{{account.email}}</span>
                </button>

                <div
                    *ngIf="isUserMenuOpen"
                    class="rmc-dropdown"
                    (clickOutside)="closeUserMenu()"
                    exclude=".auth-button"
                >
                    <button class="dropdown-item" type="button" (click)="logout()">Logout</button>
                </div>
            </div>

            <div *ngIf="!account" class="auth">
                <button class="auth-button" type="button" (click)="login()">Login</button>
            </div>
        </div>

        <button *ngIf="isReady" class="menu-button" type="button" (click)="openMobileMenu()">
            <svg class="rmc-icon rmc-icon--l rmc-icon--white" rmcSldsIcon="rows"></svg>
        </button>

        <div *ngIf="isReady" class="menu-wrapper">
            <div *ngIf="isMobileMenuOpen" class="menu-backdrop" (click)="closeMobileMenu()"></div>

            <div class="menu" [ngClass]="{
                'menu--open': isMobileMenuOpen
            }">
                <div *ngIf="account" class="menu-group">
                    <div class="menu-title">
                        <svg *ngIf="account.isEditor" class="rmc-icon rmc-icon--s rmc-icon--white slds-m-right--x-small" rmcSldsIcon="favorite"></svg>
                        <span>{{account.email}}</span>
                    </div>
                </div>

                <div class="menu-group">
                    <div class="menu-item">
                        <a class="link" href="https://www.rockymountaineer.com/faq" target="_blank">FAQ</a>
                    </div>
                    <div class="menu-item">
                        <a class="link" href="https://www.rockymountaineer.com/contact-us" target="_blank">Contact Us</a>
                    </div>
                </div>

                <div class="menu-group" *ngIf="false">
                    <div class="menu-item">
                        <a href="javascript:void(0)" *ngIf="account" class="link" (click)="logout()">Logout</a>
                        <a href="javascript:void(0)" *ngIf="!account" class="link" (click)="login()">Login</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
