<div class="footer">
    <div class="top">
        <div class="rmc-container">
            <div class="group">
                <div class="item">
                    <a class="link" target="_blank" href="https://www.rockymountaineer.com/plan-your-trip/smoothing-way-mobility-and-accessibility">Mobility & Accessibility</a>
                </div>
                <div class="item">
                    <a class="link" target="_blank" href="https://www.rockymountaineer.com/corporate/privacy-policy">Privacy Policy</a>
                </div>
            </div>
            <div class="group">
                <div class="item">
                    <a class="link" target="_blank" href="https://www.rockymountaineer.com/terms-conditions">Terms & Conditions</a>
                </div>
                <div class="item">
                    <a class="link" target="_blank" href="https://www.rockymountaineer.com/contact-us">Contact Us</a>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom"></div>
    <a href="?booking={{getBookingId()}}">
        <img class="logo" src="/images/logo-rm-sign.svg">
    </a>
</div>
