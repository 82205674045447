import { Component, OnInit } from '@angular/core';
import {BookingService} from "../../services/booking.service";

@Component({
    selector: 'rmc-app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {

    constructor(
        private bookingService: BookingService
    ) { }

    ngOnInit() {
    }

    getBookingId() {
        return this.bookingService.getBookingId();
    }
}
