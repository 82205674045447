import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { SnapengageService} from '../../services/snapengage.service';

@Component({
    selector: 'rmc-configure-help',
    templateUrl: './configure-help.component.html',
    styleUrls: ['./configure-help.component.scss']
})
export class ConfigureHelpComponent implements OnInit {

    isReady = false;
    serviceCode: string;
    servicePhone: string = environment.servicePhone;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private snapengageService: SnapengageService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;
        this.serviceCode = this.bookingService.getServiceCode();
    }

    openChat(): void {
        this.snapengageService.openChat();
    }
}
