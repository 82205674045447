interface ApiAccount {
    email: string;
    id: string;
    is_content_editor: boolean;
    url: string;
}

export interface ApiAccountResponse {
    results: Array<ApiAccount>
}

export interface Account {
    email: string;
    id: string;
    isEditor: boolean;
    url: string;
}

export function accountApiToLocal(apiAccountResponse: ApiAccountResponse): Account {
    const apiAccount: ApiAccount = apiAccountResponse.results[0];

    return {
        email: apiAccount.email,
        id: apiAccount.id,
        isEditor: apiAccount.is_content_editor,
        url: apiAccount.url
    };
}

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
function dec2hex(dec: number): string {
    return dec.toString(16).padStart(2, '0');
}

export function generateId(length: number): string {
    const uintArray: Uint8Array = new Uint8Array((length) / 2);
    window.crypto.getRandomValues(uintArray);

    return Array.from(uintArray, dec2hex).join('');
}
