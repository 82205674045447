import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rmc-review-page',
    templateUrl: './review-page.component.html',
    styleUrls: ['./review-page.component.scss']
})
export class ReviewPageComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}
