<div class="prices">
    <div class="total" [ngClass]="{
        'total--open': isOptionsOpened
    }">
        <button class="options-button" type="button" [disabled]="!isPricesReady || requiresAccessibilityAssistance()" (click)="toggleOptions()">
            <img class="dropdown-icon" src="/images/dropdown-caret.svg" width="21" height="12">
        </button>

        <div class="total-col rmc-grow">
            <div *ngIf="!isPricesReady">
                <div class="price-table-placeholder price-table-placeholder--total rmc-placeholder">&nbsp;</div>
                <div class="price-table-placeholder price-table-placeholder--deposit rmc-placeholder">&nbsp;</div>
            </div>

            <div *ngIf="isPricesReady" class="price-table">
                <div class="price-row price-row--total">
                    <div class="price-label"><rmc-phrase label="CTA_TOTAL_PRICE"></rmc-phrase>:</div>
                    <div class="price">
                        <span class="price__value">{{(getPrices() ? getPrices().getTotal() : 0) | envCurrency}}</span>
                        <span class="price__currency">{{currency.code}}</span>
                    </div>
                </div>

                <div *ngIf="getPrices() && getPrices().getDeposit() !== getPrices().getTotal()" class="price-row price-row--deposit">
                    <div class="price-label"><rmc-phrase label="CTA_AMOUNT_DUE_TODAY"></rmc-phrase>:</div>
                    <div class="price">
                        <span class="price__value">{{(getPrices() ? getPrices().getDeposit() : 0) | envCurrency}}</span>
                        <span class="price__currency">{{currency.code}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isPricesReady" class="slds-text-align--center total-col">
            <div class="button-placeholder rmc-placeholder rmc-placeholder--button">&nbsp;</div>
        </div>
        <div *ngIf="isPricesReady" [ngSwitch]="path" class="slds-text-align--center total-col">
            <a href="javascript:void(0)" *ngSwitchCase="'configure'" class="rmc-button rmc-button--brand" (click)="submitExtensions()">
                <div class="bouncer-container">
                    <rmc-button-bouncer *ngIf="isLoading()"></rmc-button-bouncer>
                </div>
                <rmc-phrase [ngClass]="{transparent: isLoading()}" label="CTA_CHECKOUT"></rmc-phrase>
            </a>
            <button *ngSwitchCase="'guest-details'" class="rmc-button rmc-button--brand" [disabled]="requiresAccessibilityAssistance() || bookingService.saveInProgress" (click)="submitDetails()">
                <div class="bouncer-container">
                    <rmc-button-bouncer *ngIf="isLoading()"></rmc-button-bouncer>
                </div>
                <rmc-phrase [ngClass]="{transparent: isLoading() || bookingService.saveInProgress}" label="CTA_REVIEW"></rmc-phrase>
            </button>

            <ng-container *ngSwitchCase="'review'">

                <button *ngIf="aeroplanStatus != 'elite' && aeroplanStatus != 'premium'" class="rmc-button rmc-button--brand" (click)="submitReview()">
                    <div class="bouncer-container" [ngClass]="{
                        'center': !isCheckingAvailability()
                    }">
                        <rmc-button-bouncer *ngIf="isLoading()" [inline]="isCheckingAvailability()"></rmc-button-bouncer>
                        <span *ngIf="isCheckingAvailability()">Checking Availabilities</span>
                    </div>
                    <rmc-phrase [ngClass]="{transparent: isLoading()}" label="CTA_PAYMENT"></rmc-phrase>
                </button>

                <button *ngIf="aeroplanStatus == 'elite' || aeroplanStatus == 'premium'" class="rmc-button rmc-button--brand" (click)="reserveBooking()">
                    <div class="bouncer-container" [ngClass]="{
                        'center': !isCheckingAvailability()
                    }">
                        <rmc-button-bouncer *ngIf="isLoading()" [inline]="isCheckingAvailability()"></rmc-button-bouncer>
                        <span *ngIf="isCheckingAvailability()">Checking Availabilities</span>
                    </div>
                    <rmc-phrase [ngClass]="{transparent: isLoading()}" label="RESERVE MY BOOKING"></rmc-phrase>
                </button>

            </ng-container>

        </div>
    </div>

    <div
        *ngIf="isOptionsOpened"
        class="pricing rmc-dropdown"
        (clickOutside)="closeOptions()"
        exclude=".options-button"
    >
        <rmc-price-breakdown [dropdown]="true"></rmc-price-breakdown>
    </div>
</div>

<rmc-modal *ngIf="isPromotionsModal" [closeButton]="false">
    <div class="rmc-modal__header">
        {{getPromotionsModalHeading()}}
    </div>
    <div class="rmc-modal__body rmc-modal__body--message">
        <div class="rmc-modal__message-text">You will not able to add your free items to your itinerary after booking.</div>

        <div>
            <button class="rmc-modal__message-action rmc-button rmc-button--brand" (click)="closePromotionsModal(true)">Add Promotion</button>
            <button class="rmc-modal__message-action rmc-button rmc-button--neutral" (click)="closePromotionsModal(false)">Checkout Without Promotion</button>
        </div>
    </div>
</rmc-modal>
