import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from '@angular/common';

import { environment } from '../../environments/environment';
import { Currency } from '../helpers/prices';

import { BookingService } from '../services/booking.service';

const fractionPattern = '1.2-2';
const noFractionPattern = '1.0-0';

@Pipe({
    name: 'envCurrency'
})
export class EnvCurrencyPipe implements PipeTransform {

    constructor(
        private bookingService: BookingService,
    ) {}

    transform(value: number, hideFraction: boolean = false): string {
        const currency: Currency = this.bookingService.getCurrency();
        const digitsInfo: string = hideFraction ? noFractionPattern : fractionPattern;
        const formatted: string = formatCurrency(value, environment.locale, currency.symbol, currency.code, digitsInfo);

        return formatted;
    }
}
