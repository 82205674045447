import { Component, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';

@Component({
    selector: 'rmc-price-breakdown-card',
    templateUrl: './price-breakdown-card.component.html',
    styleUrls: ['./price-breakdown-card.component.scss']
})
export class PriceBreakdownCardComponent implements OnInit {

    isReady = false;

    constructor(
        private appService: AppService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;
    }
}
