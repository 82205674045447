import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { BookingUpdateEvent } from '../../helpers/booking';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { ConfigurationService } from '../../services/configuration.service';
import { PromotionsService } from '../../services/promotions.service';

@Component({
    selector: 'rmc-configure-promotions',
    templateUrl: './configure-promotions.component.html',
    styleUrls: ['./configure-promotions.component.scss']
})
export class ConfigurePromotionsComponent implements OnInit {

    date: string;
    dateFormat: string = environment.dateFormat;
    isAvailable: boolean;
    isSelected: boolean;
    label: string;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private configurationService: ConfigurationService,
        private promotionsService: PromotionsService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        await this.promotionsService.init();

        this.isAvailable = this.promotionsService.isPromotionsAvailable();
        this.label = this.promotionsService.getPromotionLabel();
        this.date = this.promotionsService.getPromotionExpiration();

        this.initSelection();
        this.bookingService.listenEvents([
            BookingUpdateEvent.Promotions,
        ]).subscribe(() => {
            this.initSelection();
        });
    }

    private initSelection(): void {
        this.isSelected = this.promotionsService.hasSelection();
    }

    open(): void {
        this.configurationService.openPromotions();
    }
}
