import { CaptchaError, CaptchaResult } from './captcha';
import { RequiredSchema } from './validation';

export interface EmailError {
    captcha?: CaptchaError;
    custom_message?: Array<string>;
    recipient_email?: Array<string>;
    recipient_name?: Array<string>;
    sender?: Array<string>;
    sender_email?: Array<string>;
}

export interface EmailForm {
    custom_message?: string;
    recipient_email?: string;
    recipient_name?: string;
    sender?: string;
    sender_email?: string;
}

export interface EmailRequest {
    captcha: CaptchaResult;
    custom_data: {
        booking_id: string;
        custom_message: string;
        sender: string;
        sender_email: string;
    };
    message_type: string;
    recipient_email: string;
    recipient_name: string;
}

export const emailRequiredSchema: RequiredSchema = {
    custom_message: false,
    recipient_email: true,
    recipient_name: true,
    sender: true,
    sender_email: true,
};
