import { Component, OnInit } from '@angular/core';

import { BookingUpdateEvent } from '../../helpers/booking';
import { Traveller, TravellerError } from '../../helpers/traveller';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { GuestDetailsService } from '../../services/guest-details.service';

@Component({
    selector: 'rmc-edit-guest-modal',
    templateUrl: './edit-guest-modal.component.html',
    styleUrls: ['./edit-guest-modal.component.scss']
})
export class EditGuestModalComponent implements OnInit {

    guestIndex: number;
    isOpen: boolean;
    roomIndex: number;
    traveller: Traveller;
    errors: TravellerError = {};


    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private guestDetailsService: GuestDetailsService,
    ) { }

    ngOnInit() {
        this.guestDetailsService.onEditGuest().subscribe((guestPath: Array<number>) => {
            this.isOpen = true;
            [this.roomIndex, this.guestIndex] = guestPath;

            const traveller: Traveller = this.guestDetailsService.getGuest(this.roomIndex, this.guestIndex);
            this.traveller = JSON.parse(JSON.stringify(traveller));

            this.appService.incrementModal();
        });

        // this.bookingService.listenEvents([
        //     BookingUpdateEvent.Occupancy,
        //     BookingUpdateEvent.TravellerDetails,
        // ]).subscribe(() => {
        //     if (this.isOpen) {
        //         this.close();
        //     }
        // });
    }

    close(): void {
        this.isOpen = false;
        this.appService.decrementModal();
    }

    isLoading(): boolean {
        return this.guestDetailsService.isLoading();
    }

    submit(): void {
        if (!this.isLoading()) {
            this.guestDetailsService.submitGuest(this.roomIndex, this.guestIndex, this.traveller).then(() => {
                this.close();
            }).catch((err) => {
                console.log(err);
                this.errors = err;
                // form is invalid, scroll to the first error
                this.guestDetailsService.scrollToError();
            });
        }
    }
}
