import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private http: HttpClient,
    ) { }

    /**
     * Promise wrapper for `delete()` method of `HttpClient`.
     * @param url The endpoint URL.
     */
    async delete(url: string): Promise<any> {
        return this.invoke('delete', url);
    }

    /**
     * Promise wrapper for `get()` method of `HttpClient`.
     * @param url The endpoint URL.
     */
    async get(url: string): Promise<any> {
        return this.invoke('get', url);
    }

    /**
     * Sends HTTP request using `HttpClient`.
     * @param method `HttpClient` method name.
     * @param url The endpoint URL.
     * @param data The data to be sent as JSON body.
     */
    private async invoke(method: string, url: string, data: Object = null): Promise<any> {
        return data
            ? this.http[method](url, data).toPromise()
            : this.http[method](url).toPromise();
    }

    /**
     * Promise wrapper for `patch()` method of `HttpClient`.
     * @param url The endpoint URL.
     * @param data The data to be sent as JSON body.
     */
    async patch(url: string, data: Object = null): Promise<any> {
        return this.invoke('patch', url, data);
    }

    /**
     * Promise wrapper for `post()` method of `HttpClient`.
     * @param url The endpoint URL.
     * @param data The data to be sent as JSON body.
     */
    async post(url: string, data: Object = null): Promise<any> {
        return this.invoke('post', url, data);
    }
}
