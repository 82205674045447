import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { BookingService } from './booking.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private bookingService: BookingService,
    ) { }

    /**
     * Adds authorization header to HTTP request.
     * @param request The outgoing request object to handle.
     * @param next The next interceptor in the chain, or the backend.
     */
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (request.url.indexOf(environment.apiUrl) === 0) {
            let apiHeaders: HttpHeaders = request.headers.set('Channel', environment.channelId);
            apiHeaders = apiHeaders.set('Client-Id', this.authService.getClientId());

            if (environment.mockPackages && request.url.indexOf(`${environment.apiUrl}products/packages/`) === 0) {
                apiHeaders = apiHeaders.set('Mock-Request', 'True');
            }

            const token: string = this.authService.getToken();

            if (token) {
                apiHeaders = apiHeaders.set('Authorization', `Token ${token}`);
            }

            return next.handle(request.clone({
                headers: apiHeaders
            })).pipe(catchError((error: HttpErrorResponse): Observable<never> => {
                if (error.status === environment.noSeatsError) {
                    this.bookingService.openNoSeatsModal();
                }

                return throwError(error);
             }));
        } else {
            return next.handle(request);
        }
    }
}
