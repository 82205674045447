import { RequiredSchema } from './validation';

export const maxAge = 120;
export const maxDay = 31;
export const maxMonth = 12;

export interface Traveller {
    country?: string;
    date_of_birth?: string;
    dietary_requests?: string;
    first_name?: string;
    last_name?: string;
    middle_name?: string;
    nationality?: string;
    passport?: string;
    passport_expiry?: string;
    province?: string;
    role: string;
    aeroplan_membership_number?: string;
    email?: string;
    phone_number?: string;
}

export interface TravellerError {
    country?: Array<string>;
    date_of_birth?: Array<string>;
    dietary_requests?: Array<string>;
    first_name?: Array<string>;
    last_name?: Array<string>;
    middle_name?: Array<string>;
    nationality?: Array<string>;
    passport?: Array<string>;
    passport_expiry?: Array<string>;
    province?: Array<string>;
    aeroplan_membership_number?: Array<string>;
    email?: string;
    phone_number?: string;
}

export interface TravellerCount {
    adults: number;
}

export const basicTravellerFields: RequiredSchema = {
    date_of_birth: true,
    dietary_requests: false,
    first_name: true,
    last_name: true,
    middle_name: false,
    phone_number: true,
    email: true
};

// changed to optional due to https://kaptio.atlassian.net/browse/API-655
export const internationalTravellerFields: RequiredSchema = {
    nationality: false,
    passport: false,
    passport_expiry: false,
};
