<div class="rmc-help rmc-card slds-p-around--large slds-m-bottom--medium" *ngIf="isReady">
    <div class="rmc-help__agent rmc-help__agent--s">
        <div class="rmc-help__agent-photo rmc-help__agent-photo--s"></div>
    </div>

    <div class="rmc-heading rmc-heading--m slds-m-bottom--medium">
        <rmc-phrase label="CONTACT_EXPERT_HEADLINE"></rmc-phrase>
    </div>

    <div class="rmc-help__info">
        <rmc-phrase label="CONTACT_EXPERT_DESCRIPTION"></rmc-phrase>
    </div>

    <div class="reference">
        <rmc-phrase label="SERVICE_CODE"></rmc-phrase>: {{ serviceCode }}
    </div>

    <a href="javascript:void(0)" class="rmc-button rmc-button--neutral" (click)="openChat()">
        <rmc-phrase label="CONTACT_EXPERT_CTA"></rmc-phrase>
    </a>
</div>
