import { Component, OnInit } from '@angular/core';

import { Choice } from '../../helpers/choices';
import { Currency } from '../../helpers/prices';
import { Selections } from '../../helpers/selections';

import { AppService } from '../../services/app.service';
import { BookingService } from "../../services/booking.service";
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'rmc-configure-insurance',
    templateUrl: './configure-insurance.component.html',
    styleUrls: ['./configure-insurance.component.scss']
})
export class ConfigureInsuranceComponent implements OnInit {

    currency: Currency;
    isReady = false;
    isPricesReady = false;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private configurationService: ConfigurationService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;

        this.currency = this.bookingService.getCurrency();

        await this.bookingService.initPriceOptions();
        this.isPricesReady = true;
    }

    openInsurance(): void {
        this.configurationService.openInsurance();
    }

    hasChoices(): boolean {
        const choices: Array<Choice> = this.bookingService.getChoices().insurance;
        return choices.length > 0;
    }

    hasSelection() {
        let selections: Selections = this.bookingService.getSelections();
        return "insurance" in selections;
    }

    getTotalRate(): number {
        let result = 0;
        let pricesByTypes = this.bookingService.getPrices().raw.prices_by_types;

        if (pricesByTypes.insurance) {
            let price = pricesByTypes.insurance;
            result += price.service + price.fee + price.promotion;
        }

        return result;
    }

    getMinimumRate(): number {
        let choices = this.bookingService.getChoices();
        let insuranceChoices = choices.insurance;

        let rates = [];

        for (let choice of insuranceChoices) {
            if (!choice.selections.insurance) continue;
            let price = choice.price.prices_by_types.insurance;
            let extraRate = price.service + price.fee + price.promotion;
            rates.push(extraRate);
        }

        if (rates.length > 0) {
            return Math.min(...rates);
        }
        return null;
    }
}
