<div class="slds-p-bottom--medium">
    <div class="rmc-heading rmc-heading--m slds-m-bottom--medium">
        {{title}}: Details
    </div>

    <div class="help-text slds-m-bottom--large" *ngIf="isInternational">
        Please fill out all details as they appear on your passport.
    </div>

    <div class="slds-m-bottom--large">
        <rmc-form-element
            [errors]="errors?.first_name"
            label="First Name"
            [loader]="loaders.first_name"
            [required]="requiredSchema.first_name"
            [submitted]="submitted"
            [value]="traveller.first_name"
        >
            <input
                class="rmc-input"
                name="first_name"
                type="text"
                [(ngModel)]="traveller.first_name"
                (blur)="onChange('first_name')"
            />
        </rmc-form-element>
    </div>

    <div class="slds-m-bottom--large">
        <rmc-form-element
            [errors]="errors?.last_name"
            label="Last Name"
            [loader]="loaders.last_name"
            [required]="requiredSchema.last_name"
            [submitted]="submitted"
            [value]="traveller.last_name"
        >
            <input
                class="rmc-input"
                name="last_name"
                type="text"
                [(ngModel)]="traveller.last_name"
                (blur)="onChange('last_name')"
            />
        </rmc-form-element>
    </div>

    <div class="slds-m-bottom--large">
        <rmc-form-element
            [errors]="errors?.middle_name"
            label="Middle Name"
            [loader]="loaders.middle_name"
            [required]="requiredSchema.middle_name"
            [submitted]="submitted"
            [value]="traveller.middle_name"
        >
            <input
                class="rmc-input"
                name="middle_name"
                type="text"
                [(ngModel)]="traveller.middle_name"
                (blur)="onChange('middle_name')"
            />
        </rmc-form-element>
    </div>

    <div class="slds-m-bottom--large">
        <rmc-form-element
            [errors]="errors?.date_of_birth"
            label="Date of Birth"
            [loader]="loaders.date_of_birth"
            [required]="requiredSchema.date_of_birth"
            [submitted]="submitted"
            [value]="traveller.date_of_birth"
        >
            <rmc-input-date
                [maxYear]="thisYear"
                [(value)]="traveller.date_of_birth"
                (valueChange)="onChange('date_of_birth')"
            ></rmc-input-date>
        </rmc-form-element>
    </div>

    <div class="slds-m-bottom--large">
        <rmc-form-element
            [errors]="errors?.country"
            label="Country of Residence"
            [loader]="loaders.country"
            [required]="isInsuranceAdded()"
            [submitted]="submitted"
            [value]="traveller.country"
        >
            <rmc-picklist
                [options]="countryOptions"
                [(value)]="traveller.country"
                (valueChange)="onCountryChange()"
            ></rmc-picklist>
        </rmc-form-element>
    </div>

    <div *ngIf="provinceOptions && provinceOptions.length" class="slds-m-bottom--large">
        <rmc-form-element
            [errors]="errors?.province"
            label="Province or State of Residence"
            [loader]="loaders.province"
            [required]="isHomeCountry()"
            [submitted]="submitted"
            [value]="traveller.province"
        >
            <rmc-picklist
                [options]="provinceOptions"
                [(value)]="traveller.province"
                (valueChange)="onProvinceChange()"
            ></rmc-picklist>
        </rmc-form-element>
    </div>

    <div class="slds-m-bottom--large" *ngIf="isInternational">
        <rmc-form-element
            [errors]="errors?.nationality"
            label="Nationality"
            [loader]="loaders.nationality"
            [required]="requiredSchema.nationality"
            [submitted]="submitted"
            [value]="traveller.nationality"
        >
            <rmc-picklist
                [options]="countryOptions"
                [(value)]="traveller.nationality"
                (valueChange)="onChange('nationality')"
            ></rmc-picklist>
        </rmc-form-element>
    </div>

    <!-- <div class="slds-m-bottom--large" *ngIf="isInternational">
        <rmc-form-element
            [errors]="errors?.passport"
            label="Passport Number"
            [loader]="loaders.passport"
            [required]="requiredSchema.passport"
            [submitted]="submitted"
            [value]="traveller.passport"
        >
            <input
                class="rmc-input"
                name="passport"
                type="text"
                [(ngModel)]="traveller.passport"
                (blur)="onChange('passport')"
            />
        </rmc-form-element>
    </div>

    <div class="slds-m-bottom--large" *ngIf="isInternational">
        <rmc-form-element
            [errors]="errors?.passport_expiry"
            label="Passport Expiry Date"
            [loader]="loaders.passport_expiry"
            [required]="requiredSchema.passport_expiry"
            [submitted]="submitted"
            [value]="traveller.passport_expiry"
        >
            <rmc-input-date
                [(value)]="traveller.passport_expiry"
                (valueChange)="onChange('passport_expiry')"
            ></rmc-input-date>
        </rmc-form-element>
    </div> -->

    <div class="slds-m-bottom--large">
        <rmc-form-element
            [errors]="errors?.dietary_requests"
            label="Dietary Requests & Needs"
            [loader]="loaders.dietary_requests"
            [required]="requiredSchema.dietary_requests"
            [submitted]="submitted"
            [value]="traveller.dietary_requests"
        >
            <rmc-picklist
                [options]="dietaryOptions"
                [(value)]="traveller.dietary_requests"
                (valueChange)="onChange('dietary_requests')"
            ></rmc-picklist>
        </rmc-form-element>
    </div>

    <div class="slds-m-bottom--large" *ngIf="isAeroplanAllowed()">
        <rmc-form-element
            [errors]="errors?.aeroplan_membership_number"
            label="Aeroplan Membership Number"
            [loader]="loaders.aeroplan_membership_number"
            [required]="requiredSchema.aeroplan_membership_number"
            [submitted]="submitted"
            [value]="traveller.aeroplan_membership_number"
        >
            <input
                class="rmc-input"
                name="middle_name"
                type="text"
                [(ngModel)]="traveller.aeroplan_membership_number"
                (blur)="onChange('aeroplan_membership_number')"
            />
        </rmc-form-element>
    </div>

    <div class="slds-m-bottom--large">
      <rmc-form-element
          [errors]="errors?.email"
          label="Email"
          [loader]="loaders.email"
          [required]="requiredSchema.email"
          [submitted]="submitted"
          [value]="traveller.email"
      >
          <input
              class="rmc-input"
              name="middle_name"
              type="text"
              [(ngModel)]="traveller.email"
              (blur)="onChange('email')"
          />
      </rmc-form-element>
    </div>

    <div class="slds-m-bottom--large">
      <rmc-form-element
          [errors]="errors?.phone_number"
          label="Phone"
          [loader]="loaders.phone_number"
          [required]="requiredSchema.phone_number"
          [submitted]="submitted"
          [value]="traveller.phone_number"
      >
          <input
              class="rmc-input"
              name="middle_name"
              type="text"
              placeholder="+1 000-000-0000"
              [(ngModel)]="traveller.phone_number"
              (blur)="onChange('phone')"
          />
      </rmc-form-element>
    </div>

</div>
