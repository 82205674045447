import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Country, Province } from '../helpers/geo';
import { PicklistOption, sortOptions } from '../helpers/picklist';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class GeoService {

    private countries: Array<Country>;
    private homeCountry: Country;
    private provinceMap: {[countryId: string]: Array<Province>} = Object.create(null);

    constructor(
        private apiService: ApiService,
    ) { }

    /**
     * Returns country options.
     * @returns List of country options.
     */
    getCountryOptions(): Array<PicklistOption> {
        const options: Array<PicklistOption> = this.countries.map((country: Country): PicklistOption => {
            return {
                label: country.label,
                value: country.id,
            };
        });

        return sortOptions(options);
    }

    /**
     * Returns 'home' country ('Canada').
     * @returns 'Home' country ('Canada').
     */
    getHomeCountryName(): string {
        return this.homeCountry.label;
    }

    /**
     * Returns province options for the country provided.
     * @param countryId Country ID.
     * @returns List of province options.
     */
    async getProvinceOptions(countryId: string): Promise<Array<PicklistOption>> {
        if (!(countryId in this.provinceMap)) {
            this.provinceMap[countryId] = (await this.apiService.get(`${environment.apiUrl}countries/states/?countries=${countryId}`)).results;
        }

        const options: Array<PicklistOption> = this.provinceMap[countryId].map((province: Province): PicklistOption => {
            return {
                label: province.label,
                value: province.id,
            };
        });

        return sortOptions(options);
    }

    /**
     * Initializes service.
     */
    async init(): Promise<void> {
        this.countries = (await this.apiService.get(`${environment.apiUrl}countries/countries/?limit=500`)).results;
        this.homeCountry = this.countries.find((country: Country): boolean => {
            return country.iso_code === environment.homeCountry;
        });

        await this.getProvinceOptions(this.homeCountry.id); // pre-load Canadian provinces, because province is required for insurance in Canada
    }

    /**
     * Checks if billing province field is required for the country provided.
     * @param countryId Country ID.
     * @returns If billing province field is required.
     */
    isBillingProvinceRequired(countryId: string): boolean {
        const targetCountry: Country = this.countries.find((country: Country): boolean => {
            return country.id === countryId;
        });

        return environment.billingProvinceRequiredIn.includes(targetCountry.iso_code);
    }

    /**
     * Checks if the country provided is 'home' country.
     * @param id Country ID.
     * @returns If the country provided is 'home' country.
     */
    isHomeCountry(id: string): boolean {
        return id === this.homeCountry.id;
    }

    /**
     * Checks if the province provided supports insurance.
     * @param id Province ID.
     * @returns If the province provided supports insurance.
     */
    isInsuranceHomeProvince(id: string): boolean {
        const homeProvinces: Array<Province> = this.provinceMap[this.homeCountry.id];
        const province: Province = homeProvinces.find((homeProvince: Province): boolean => {
            return homeProvince.id === id;
        });

        if (!province) {
            return false;
        }

        return environment.homeInsuranceProvinces.includes(province.iso_code);
    }
}
