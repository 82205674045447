import { Component, OnInit } from '@angular/core';

import { Currency } from '../../helpers/prices';
import { Selections } from '../../helpers/selections';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { ConfigurationService } from '../../services/configuration.service';
import { PromotionsService } from '../../services/promotions.service';

@Component({
    selector: 'rmc-configure-transfer',
    templateUrl: './configure-transfer.component.html',
    styleUrls: ['./configure-transfer.component.scss']
})
export class ConfigureTransferComponent implements OnInit {

    currency: Currency;
    isPricesReady = false;
    isReady = false;
    showPrices = false;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private configurationService: ConfigurationService,
        private promotionsService: PromotionsService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;

        this.currency = this.bookingService.getCurrency();

        await this.bookingService.initPriceOptions();
        this.isPricesReady = true;
    }

    getMinimumRate(): number {
        const choices: any = this.bookingService.getChoices();
        const rates: Array<number> = [];

        for (const type of ['pre_transfer', 'post_transfer']) {
            for (const choice of choices[type]) {
                if (!choice.selections[type]) {
                    continue;
                }

                const price: any = choice.price.prices_by_types[type];
                const rate: number = price.service + price.fee + price.promotion;

                rates.push(rate);
            }
        }

        return Math.min(...rates);
    }

    getTotalRate(): number {
        let result: number = 0;
        const pricesByTypes: any = this.bookingService.getPrices().raw.prices_by_types;

        for (const type of ['pre_transfer', 'post_transfer']) {
            if (pricesByTypes[type]) {
                const price: any = pricesByTypes[type];
                result += price.service + price.fee + price.promotion;
            }
        }

        return result;
    }

    hasChoices(): boolean {
        const choices: any = this.bookingService.getChoices();
        const promotionalTransfer: string = this.promotionsService.getTransferSelection();

        return (
            (choices.pre_transfer.length > 1 || promotionalTransfer === 'pre') ||
            (choices.post_transfer.length > 1 || promotionalTransfer === 'post')
        );
    }

    hasSelection() {
        const selections: Selections = this.bookingService.getSelections();
        return (
            "pre_transfer" in selections ||
            "post_transfer" in selections
        );
    }

    openTransfers(): void {
        this.configurationService.openTransfers();
    }
}
