import { Component, OnInit } from '@angular/core';

import { Currency } from '../../helpers/prices';
import { Selections } from '../../helpers/selections';

import { AppService } from '../../services/app.service';
import { BookingService } from "../../services/booking.service";
import { ConfigurationService } from "../../services/configuration.service";

@Component({
    selector: 'rmc-configure-extra-nights',
    templateUrl: './configure-extra-nights.component.html',
    styleUrls: ['./configure-extra-nights.component.scss']
})
export class ConfigureExtraNightsComponent implements OnInit {

    currency: Currency;
    isReady = false;
    isPricesReady = false;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private configurationService: ConfigurationService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;

        this.currency = this.bookingService.getCurrency();

        await this.bookingService.initPriceOptions();
        this.isPricesReady = true;
    }

    openExtendJourneySidebar() {
        this.configurationService.openExtendJourney();
    }

    getTotalRate(): number {
        let result = 0;
        let pricesByTypes = this.bookingService.getPrices().raw.prices_by_types;

        for (const type of ['extra_pre_night', 'extra_post_night', 'pre_activity', 'post_activity']) {
            const price: any = pricesByTypes[type];
            result += price.service + price.fee + price.promotion;
        }

        return result;
    }

    getMinimumRate(): number {
        let choices = this.bookingService.getChoices();
        let extraPostChoices = choices.extra_post_night;
        let extraPreChoices = choices.extra_pre_night;

        let rates = [];

        for (let choice of extraPostChoices) {
            if (!choice.selections.extra_post_night) continue;
            let price = choice.price.prices_by_types.extra_post_night;
            let extraRate = price.service + price.fee + price.promotion;
            rates.push(extraRate);
        }
        for (let choice of extraPreChoices) {
            if (!choice.selections.extra_pre_night) continue;
            let price = choice.price.prices_by_types.extra_pre_night;
            let extraRate = price.service + price.fee + price.promotion;
            rates.push(extraRate);
        }

        if (rates.length > 0) {
            return Math.min(...rates);
        }
        return null;
    }

    hasSelection(): boolean {
        const selections: Selections = this.bookingService.getSelections();

        if (selections == null) {
            return false;
        }

        return Boolean(selections.extra_pre_night || selections.extra_post_night);
    }

    hasChoices(): boolean {
        let choices = this.bookingService.getChoices();
        return (
            choices && (
                // It always contains the "0" object
                Object.keys(choices.extra_post_night).length > 1 ||
                Object.keys(choices.extra_pre_night).length > 1
            )
        )
    }
}
