import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { BookingUpdateEvent } from '../../helpers/booking';
import { formatEnumeration } from '../../helpers/occupancy';
import { Package } from '../../helpers/package';
import {Traveller, TravellerCount} from '../../helpers/traveller';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { GuestDetailsService } from '../../services/guest-details.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
    selector: 'rmc-tour-header',
    templateUrl: './tour-header.component.html',
    styleUrls: ['./tour-header.component.scss']
})
export class TourHeaderComponent implements OnInit {

    arrivalDate: Date;
    dateFormat: string = environment.dateFormat;
    departureDate: Date;
    isReady = false;
    private occupancy: Array<Array<Traveller>>;
    package: Package;
    path: string;
    serviceLevelLabel: string;
    private travellerCount: TravellerCount;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private guestDetailsService: GuestDetailsService,
        private navigationService: NavigationService,
    ) { }

    async ngOnInit() {
        this.path = this.navigationService.getTopLevelRoute();

        await this.appService.onInit();
        await this.getServiceLevelLabel();
        this.init();
    }

    async getServiceLevelLabel(): Promise<void> {
        this.serviceLevelLabel = await this.bookingService.getServiceLevelName();
    }

    getOccupancyLabel(): string {
        const labelParts: Array<string> = [];

        if (this.travellerCount.adults) {
            labelParts.push(`${this.travellerCount.adults} ${this.travellerCount.adults === 1 ? 'Adult' : 'Adults'}`);
        }

        labelParts.push(`${this.occupancy.length} ${this.occupancy.length === 1 ? 'Room' : 'Rooms'}`);

        return formatEnumeration(labelParts);
    }

    init(): void {
        this.isReady = true;
        this.package = this.bookingService.getPackage();
        this.occupancy = this.bookingService.getOccupancy();

        this.initDates();
        this.bookingService.listenEvents([
            BookingUpdateEvent.ExtraPostNights,
            BookingUpdateEvent.ExtraPreNights,
            BookingUpdateEvent.Promotions,
        ]).subscribe(() => {
            this.initDates();
        });

        this.bookingService.listenEvents([
            BookingUpdateEvent.Occupancy,
        ]).subscribe(() => {
            this.occupancy = this.guestDetailsService.getGuestsByRooms();
            this.travellerCount = this.bookingService.getTravellerCountByRole();
        });

        this.travellerCount = this.bookingService.getTravellerCountByRole();
    }

    private initDates(): void {
        this.departureDate = this.bookingService.getDepartureDate(true);
        this.arrivalDate = this.bookingService.getArrivalDate(true);
    }
}
