import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { SnapengageService } from "../../services/snapengage.service";
import { BookingService } from '../../services/booking.service';

@Component({
    selector: 'rmc-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

    isReady = false;
    serviceCode: string;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private snapengageService: SnapengageService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;
        this.serviceCode = this.bookingService.getServiceCode();
    }

    openChat(): void {
        this.snapengageService.openChat();
    }
}
