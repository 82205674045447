<div class="rmc-pricing-option rmc-pricing-option--subitem">
    <div *ngIf="loading" class="rmc-pricing-option__name rmc-placeholder">&nbsp;</div>
    <div *ngIf="!loading" class="rmc-pricing-option__name">
        {{label}}
    </div>

    <div *ngIf="!loading" class="rmc-pricing-option__value">
        {{ price | envCurrency }}
    </div>

    <div *ngIf="!loading" class="rmc-pricing-option__currency">{{currency.code}}</div>

    <div *ngIf="dropdown" class="rmc-pricing-option__actions">
        <button class="delete-button" [disabled]="disabled || loading" type="button" (click)="removeAddon()" matTooltip="Remove Add On">
            <img class="delete-icon" src="/images/remove-addon.png">
        </button>
    </div>
</div>
