export interface ApiChannel {
    channel_details: ApiChannelDetails;
    client: string;
    environment: string;
    identity_provider: string;
    note: string;
    phrases: PhraseMap;
    token: string;
    url: string;
}

interface ApiChannelDetails {
    available_currencies: string;
    code: string;
    currency_iso_code: string;
    id: string;
    name: string;
    url: string;
}

export interface ApiEditorialSet {
    channel: string;
    id: string;
    name: string;
    order: number;
    phrases: Array<ApiPhrase>;
    url: string;
}

export interface ApiEditorialSets {
    results: Array<ApiEditorialSet>;
};

export interface ApiPhrase {
    editorial_set: string;
    id: string;
    label: string;
    text: string;
    url: string;
}

export interface ApiPhrases {
    results: Array<ApiPhrase>;
}

export interface Channel {
    phrases: PhraseMap;
}

export interface EditorialSet {
    channel: string;
    id: string;
    name: string;
    order: number;
    phrases: Array<Phrase>;
    url: string;
}

export interface LabelInfo {
    label: string;
    phrases: Array<Phrase>;
}

export interface NewPhrase {
    editorialSet: string;
    label: string;
    text: string;
}

export interface Phrase {
    editorialSet: string;
    id: string;
    label: string;
    text: string;
    url: string;
}

interface PhraseMap {
    [label: string]: string;
}

export function channelApiToLocal(apiChannel: ApiChannel): Channel {
    return {
        phrases: apiChannel.phrases
    };
}

export function editorialSetApiToLocal(apiEditorialSet: ApiEditorialSet): EditorialSet {
    const phrases: Array<Phrase> = apiEditorialSet.phrases.map(phraseApiToLocal);

    return {
        channel: apiEditorialSet.channel,
        id: apiEditorialSet.id,
        name: apiEditorialSet.name,
        order: apiEditorialSet.order,
        phrases,
        url: apiEditorialSet.url
    };
}

export function editorialSetsApiToLocal(apiEditorialSets: ApiEditorialSets): Array<EditorialSet> {
    return apiEditorialSets.results.map(editorialSetApiToLocal);
}

export function phraseApiToLocal(apiPhrase: ApiPhrase): Phrase {
    return {
        editorialSet: apiPhrase.editorial_set,
        id: apiPhrase.id,
        label: apiPhrase.label,
        text: apiPhrase.text,
        url: apiPhrase.url
    };
}

export function phrasesApiToLocal(apiPhrases: ApiPhrases): Array<Phrase> {
    return apiPhrases.results.map(phraseApiToLocal);
}
