import { Component, Input, OnInit } from '@angular/core';
import { debounce } from 'lodash';

import { environment } from '../../../environments/environment';
import { BookingUpdateEvent } from '../../helpers/booking';
import { formatEnumeration } from '../../helpers/occupancy';
import { emptyOption, PicklistOption } from '../../helpers/picklist';
import { CruiseDetails, CruiseDetailsError } from '../../helpers/selections';

import { BookingService } from '../../services/booking.service';
import { StaticDataService } from '../../services/static-data.service';

@Component({
    selector: 'rmc-guests-details-room',
    templateUrl: './guests-details-room.component.html',
    styleUrls: ['./guests-details-room.component.scss']
})
export class GuestsDetailsRoomComponent implements OnInit {

    @Input()
    submitted: boolean;
    @Input()
    roomIdx: number;
    bedding: string;
    beddingOptions: Array<PicklistOption>;
    cabinOptions: Array<PicklistOption>;
    cruiseDetails: CruiseDetails;
    cruiseDetailsRequestCounter = 0;
    private debouncedSave: () => void;
    errors: CruiseDetailsError = {};
    hasAccommodation: boolean;
    hasCruise: boolean;
    loaders: {[field: string]: boolean} = {};
    preferenceTypes: string;
    seatingOptions: Array<PicklistOption>;

    constructor(
        private bookingService: BookingService,
        private staticDataService: StaticDataService,
    ) { }

    ngOnInit() {
        this.hasAccommodation = this.bookingService.hasAccommodation();
        this.hasCruise = this.bookingService.hasCruise();

        if (this.hasAccommodation || this.hasCruise) {
            const preferenceTypes: Array<string> = [];
            if (this.hasAccommodation) {
                preferenceTypes.push('Room');
            }
            if (this.hasCruise) {
                preferenceTypes.push('Cruise');
            }
            this.preferenceTypes = formatEnumeration(preferenceTypes);

            this.beddingOptions = JSON.parse(JSON.stringify(environment.beddingOptions));
            this.beddingOptions.unshift(emptyOption);

            this.cabinOptions = this.staticDataService.getValue('cruise_cabin_preference');
            this.cabinOptions.unshift(emptyOption);

            this.seatingOptions = this.staticDataService.getValue('cruise_meal_seating_preference');
            this.seatingOptions.unshift(emptyOption);

            this.initDetails();
            this.bookingService.listenEvents([
                BookingUpdateEvent.CruiseDetails,
            ]).subscribe(() => {
                if (!this.cruiseDetailsRequestCounter) { // check if this change is external
                    this.initDetails();
                }
            });

            this.debouncedSave = debounce(this.save, environment.autosaveInterval);
        }
    }

    private initDetails(): void {
        this.cruiseDetails = this.bookingService.getCruiseDetails()[this.roomIdx] || {};
    }

    onChange(field: string): void {
        this.loaders[field] = true;
        this.debouncedSave();
    }

    private async save(): Promise<void> {
        this.cruiseDetailsRequestCounter++;

        await this.bookingService.updateCruiseDetails(this.cruiseDetails, this.roomIdx).then(() => {
            this.errors = {};
        }).catch((errors: CruiseDetailsError) => {
            this.errors = errors;
        });

        this.loaders = {};
        this.cruiseDetailsRequestCounter--;
    }
}
