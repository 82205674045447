import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PicklistOption } from '../../helpers/picklist';

@Component({
    selector: 'rmc-input-time',
    templateUrl: './input-time.component.html',
    styleUrls: ['./input-time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTimeComponent implements OnInit {

    @Input()
    disabled = false;

    @Input()
    value: string;
    @Output()
    valueChange: EventEmitter<string> = new EventEmitter<string>();

    hours: string;
    minutes: string;
    period: number;

    periodOptions: Array<PicklistOption> = [
        {
            label: 'AM',
            value: 0,
        },
        {
            label: 'PM',
            value: 12,
        },
    ];

    constructor() { }

    ngOnInit() {
        if (this.value) {
            let hoursString: string;
            [hoursString, this.minutes] = this.value.split(':');
            const hours: number = Number(hoursString);

            this.hours = String((hours % 12) || 12);
            this.period = hours < 12 ? 0 : 12;
        } else {
            this.value = null;
            this.hours = null;
            this.minutes = null;
            this.period = 0;
        }
    }

    onChange(): void {
        if (this.hours && this.minutes) {
            const hours12h: number = Number(this.hours);
            const hours24h = (hours12h === 12 ? 0 : hours12h) + this.period;

            this.setValue(`${String(hours24h).padStart(2, '0')}:${this.minutes.padStart(2, '0')}`);
        } else {
            this.setValue(null);
        }
    }

    onHoursChange(event: UIEvent): void {
        const input: HTMLInputElement = <HTMLInputElement>event.target;

        if (!input.value) {
            this.onChange();
            return;
        }

        const hours: number = Number(input.value);

        if (hours >= 1 && hours <= 12) {
            this.hours = String(hours).padStart(2, '0');
        } else {
            this.hours = '12';
        }

        this.onChange();
    }

    onInput(event: UIEvent, field: string): void {
        const input: HTMLInputElement = <HTMLInputElement>event.target;

        if (/^[0-9]{0,2}$/.test(input.value)) {
            this[field] = input.value;
        } else {
            input.value = this[field];
        }
    }

    onMinutesChange(event: UIEvent): void {
        const input: HTMLInputElement = <HTMLInputElement>event.target;

        if (!input.value) {
            this.onChange();
            return;
        }

        const minutes: number = Number(input.value);

        if (minutes >= 0 && minutes <= 59) {
            this.minutes = String(minutes).padStart(2, '0');
        } else {
            this.minutes = '59';
        }

        this.onChange();
    }

    private setValue(value: string): void {
        if (value !== this.value) {
            this.value = value;
            this.valueChange.emit(this.value);
        }
    }
}
