import { Injectable } from '@angular/core';

import { escapeRegExp, PicklistOptionLabelPart } from '../helpers/picklist';

@Injectable({
    providedIn: 'root'
})
export class PicklistService {

    constructor() { }

    /**
     * Returns label parts for empty search.
     * @param label Picklist option label.
     * @returns Default label parts for empty search.
     */
    getDefaultLabelParts(label: string): Array<PicklistOptionLabelPart> {
        return [
            {
                match: false,
                text: label,
            },
        ];
    }

    /**
     * Returns search matches for the label provided.
     * @param label Picklist option label.
     * @param query Search query.
     * @returns List of matching label parts.
     */
    private getMatches(label: string, query: string): Array<string> {
        return label.match(new RegExp(`^${escapeRegExp(query)}`, 'gi'));
    }

    /**
     * Splits label into list of matching and non-matching substrings.
     * @param label Picklist option label.
     * @param query Search query.
     * @returns List of label parts (matching and non-matching substrings).
     */
    splitLabel(label: string, query: string): Array<PicklistOptionLabelPart> {
        const matches: Array<string> = this.getMatches(label, query);
    
        if (!matches) {
            return this.getDefaultLabelParts(label);
        }
    
        const parts: Array<PicklistOptionLabelPart> = [];
        let currentIndex = 0;
    
        for (const match of matches) {
            const matchStart: number = label.indexOf(match, currentIndex);
            const matchEnd: number = matchStart + match.length;
    
            parts.push(
                {
                    match: false,
                    text: label.slice(currentIndex, matchStart),
                },
                {
                    match: true,
                    text: match,
                }
            );
    
            currentIndex = matchEnd;
        }
    
        parts.push({
            match: false,
            text: label.slice(currentIndex),
        });
    
        return parts.filter((part): boolean => {
            return part.text.length > 0;
        });
    }

    /**
     * Checks if label matches search query.
     * @param label Picklist option label.
     * @param query Search query.
     * @returns If label matches search query.
     */
    testLabel(label: string, query: string): boolean {
        return Boolean(this.getMatches(label, query));
    }
}
