import { BookingPrices } from './booking';
import { dateStringToDate } from './date';

export interface Currency {
    code: string;
    name: string;
    symbol: string;
}

export interface Price {
    fee: number;
    promotion: number;
    service: number;
    tax: number;
    total: number;
}

export class Prices {
    public raw: BookingPrices;

    constructor(pricesDefinition: BookingPrices) {
        this.raw = pricesDefinition;
    }

    public getPackageSubtotal(): number {
        let packagePrice = this.raw.prices_by_types.package;
        return packagePrice.fee + packagePrice.service + packagePrice.promotion
    }

    public getSubtotal(): number {
        return this.raw.summary.total - this.raw.summary.tax;
    }

    public getTax(): number {
        return this.raw.summary.tax;
    }

    public getTotal(): number {
        return Math.round((parseFloat(String((this.raw.summary.total))) + Number.EPSILON) * 100) / 100;
    }

    public getFee(): number {
        return this.raw.summary.fee;
    }

    public getPackagePromotion(): number {
        return this.raw.prices_by_types?.package_promotion?.total;
    }

    public getFinalDate(): Date {
        let finalPayment: any;

        for (const payment of this.raw.payment_schedule) {
            if (finalPayment) {
                if (payment.day_offset > finalPayment.day_offset) {
                    finalPayment = payment;
                }
            } else {
                finalPayment = payment;
            }
        }

        return dateStringToDate(finalPayment.due_date);
    }

    public getDeposit(): number {
        for (let payment of this.raw.payment_schedule) {
            if (payment.day_offset === 0) {
                return Number(payment.value);
            }
        }

        return 0;
    }
}
