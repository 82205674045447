import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from 'lodash';

import { environment } from '../../../environments/environment';
import { emptyOption, PicklistOption } from '../../helpers/picklist';
import { Selections } from '../../helpers/selections';
import { Traveller, TravellerError } from '../../helpers/traveller';
import { RequiredSchema } from '../../helpers/validation';

import { BookingService } from '../../services/booking.service';
import { GeoService } from '../../services/geo.service';
import { GuestDetailsService } from '../../services/guest-details.service';
import { StaticDataService } from '../../services/static-data.service';

@Component({
    selector: 'rmc-guest',
    templateUrl: './guest.component.html',
    styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {

    @Input()
    autosave = false;

    @Input()
    guestIndex: number;

    @Input()
    requestCounter: number;
    @Output()
    requestCounterChange: EventEmitter<number> = new EventEmitter<number>();

    @Input()
    roomIndex: number;

    @Input()
    submitted: boolean;

    @Input()
    traveller: Traveller;

    @Input()
    errors: TravellerError = {};

    @Output()
    guestUpdated = new EventEmitter<Traveller>();

    countryOptions: Array<PicklistOption>;
    private debouncedSave: () => void;
    dietaryOptions: Array<PicklistOption>;
    isInternational: boolean;
    loaders: {[field: string]: boolean} = {};
    provinceOptions: Array<PicklistOption>;
    requiredSchema: RequiredSchema;
    thisYear: number = new Date().getFullYear();
    title: string;
    aeroplanExpires: Date = new Date(2024, 4, 1);

    constructor(
        private bookingService: BookingService,
        private geoService: GeoService,
        private guestDetailsService: GuestDetailsService,
        private staticDataService: StaticDataService,
    ) { }

    ngOnInit() {
        this.dietaryOptions = this.staticDataService.getValue('dietary_meal_requests');
        this.dietaryOptions.unshift(emptyOption);

        this.isInternational = this.bookingService.isInternational();
        this.requiredSchema = this.guestDetailsService.getGuestSchema();
        this.title = this.guestDetailsService.getTitle(this.roomIndex, this.guestIndex);

        this.countryOptions = this.geoService.getCountryOptions();
        this.initProvinceOptions();

        this.debouncedSave = debounce(this.save, environment.autosaveInterval);
    }

    async initProvinceOptions(): Promise<void> {
        this.provinceOptions = null;

        if (this.traveller.country) {
            this.loaders.province = true;
            this.provinceOptions = await this.geoService.getProvinceOptions(this.traveller.country);
            this.loaders.province = false;
        }
    }

    isHomeCountry(): boolean {
        return this.geoService.isHomeCountry(this.traveller.country);
    }

    isInsuranceAdded(): boolean {
        return this.guestDetailsService.isInsuranceAdded();
    }

    isAeroplanAllowed(): boolean {
	return this.bookingService.getDepartureDate() < this.aeroplanExpires;    
    }

    onChange(field: string): void {
        delete this.errors[field];
        if (this.autosave) {
	    this.guestDetailsService.guestUpdateGuard()
            this.loaders[field] = true;
            this.debouncedSave();
        }
    }

    onCountryChange(): void {
        this.onChange('country');
        this.initProvinceOptions();

        if (this.traveller.province) {
            this.traveller.province = null;
            this.onChange('province');
        }
    }

    async onProvinceChange(): Promise<void> {
        if (
            this.isInsuranceAdded() &&
            this.traveller.province &&
            this.geoService.isHomeCountry(this.traveller.country) &&
            !this.geoService.isInsuranceHomeProvince(this.traveller.province)
        ) {
            const provinceName: string = this.provinceOptions.find((province: PicklistOption): boolean => {
                return province.value === this.traveller.province;
            }).label;
            this.guestDetailsService.openInsuranceModal(provinceName);

            await this.bookingService.setInsurancePlan(null);
        }

        this.onChange('province');
    }

    private async save(): Promise<void> {
        this.requestCounter++;
        this.requestCounterChange.emit(this.requestCounter);

        this.guestUpdated.emit(this.traveller);

        this.errors = await this.guestDetailsService.updateGuest(this.roomIndex, this.guestIndex, this.traveller);
        this.loaders = {};

        this.requestCounter--;
        this.requestCounterChange.emit(this.requestCounter);
    }
}
