import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Product } from '../helpers/products';

import { ApiService } from '../services/api.service';

@Injectable({
    providedIn: 'root'
})
export class ProductsService {

    constructor(
        private apiService: ApiService,
    ) { }

    /**
     * Retrieves list of products.
     * @param productIds List of product IDs.
     * @param includePrices If prices are added to the response. Prices could be omitted for optimization purposes.
     * @returns List of products.
     */
    async getProducts(productIds: Array<string>, includePrices: boolean = true): Promise<Array<Product>> {
        const url: string = `${environment.apiUrl}products/item-options/?ids=${productIds.join('|')}`;
        const products: Array<Product> = (await this.apiService.get(url)).results;

        return products;
    }
}
