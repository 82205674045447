<div *ngIf="isAvailable">
    <div *ngIf="isSelected" class="promotions">
        <svg class="icon checkmark">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/checkmark.svg#check"></use>
        </svg>

        <strong>ADDED: {{ label }}</strong> Expires {{date | date: dateFormat}}
        <strong><a href="javascript:void(0)" class="link" (click)="open()">Change Options</a></strong>
    </div>

    <div *ngIf="!isSelected" class="promotions">
        <img class="icon exclamation" src="/images/exclamation.svg">
        <strong>Your booking is eligible for our {{ label }} offer:</strong>
        <strong><a href="javascript:void(0)" class="link" (click)="open()">Add Promotion</a></strong>
    </div>
</div>
