import { Component, OnInit } from '@angular/core';

import { Currency, Prices } from '../../helpers/prices';
import { BookingService } from '../../services/booking.service';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'rmc-cancel-policy',
    templateUrl: './cancel-policy.component.html',
    styleUrls: ['./cancel-policy.component.scss']
})
export class CancelPolicyComponent implements OnInit {

    currency: Currency;
    isReady = false;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        await this.bookingService.initPriceOptions();
        this.isReady = true;

        this.currency = this.bookingService.getCurrency();
    }

    getPrices(): Prices {
        return this.bookingService.getPrices();
    }

    trackByIndex(index: number): number {
        return index;
    }
}
