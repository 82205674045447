<div class="row">
    <div class="col col--year">
        <label class="rmc-form-element__label">
            Year
        </label>

        <div class="rmc-form-element__control">
            <input
                class="rmc-input"
                [disabled]="disabled"
                [attr.max]="maxYear"
                min="1900"
                placeholder="YYYY"
                type="number"
                [(ngModel)]="year"
                (ngModelChange)="onChange()"
            />
        </div>
    </div>

    <div class="col col--month">
        <label class="rmc-form-element__label">
            Month
        </label>

        <div class="rmc-form-element__control">
            <input
                class="rmc-input"
                [disabled]="disabled"
                max="12"
                min="1"
                placeholder="MM"
                type="number"
                [(ngModel)]="month"
                (ngModelChange)="onChange()"
            />
        </div>
    </div>

    <div class="col col--day">
        <label class="rmc-form-element__label">
            Day
        </label>

        <div class="rmc-form-element__control">
            <input
                class="rmc-input"
                [disabled]="disabled"
                max="31"
                min="1"
                placeholder="DD"
                type="number"
                [(ngModel)]="day"
                (ngModelChange)="onChange()"
            />
        </div>
    </div>
</div>
