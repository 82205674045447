export interface RequiredSchema {
    [field: string]: boolean;
}

export function isEmpty(value: any): boolean {
    return (
        value === undefined ||
        value === null ||
        value === ''
    );
}

export function isObjectFilled(data: Object, schema: RequiredSchema): boolean {
    for (const [field, required] of Object.entries(schema)) {
        if (required && isEmpty(data[field])) {
            return false;
        }
    }

    return true;
}

export function removeEmptyFields(data: Object): void {
    // clean up data (null values could cause API error)
    for (const field in data) {
        if (isEmpty(data[field])) {
            delete data[field];
        }
    }
}
