<div class="switch" [ngClass]="{
    'switch--single': type === 'single',
    'switch--separate': type === 'separate'
}">
    <button
        *ngFor="let option of options; trackBy: trackOption"
        class="option"
        [disabled]="disabled"
        [ngClass]="{
            'selected': isSelected(option)
        }"
        (click)="select(option)"
    >
        {{option.label}}
    </button>
</div>
