import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'rmc-button-bouncer',
    templateUrl: './button-bouncer.component.html',
    styleUrls: ['./button-bouncer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonBouncerComponent implements OnInit {

    @Input()
    inline = false;

    constructor() { }

    ngOnInit() {
    }
}
