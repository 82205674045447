import { Departure } from './departure';

interface ApiComponent {
    id: string;
    item_type: string;
    name: string;
    mandatory: Boolean;
}

export interface ApiPackage {
    arrival_location: Array<string>;
    category: string;
    components: Array<ApiComponent>;
    departure_location: Array<string>;
    departures: string;
    description: string;
    name: string;
    external_name: string;
    id: string;
    length: number;
    url: string;
}

export interface PackageComponent {
    id: string;
    item_type: string;
    name: string;
    mandatory: Boolean;
}

export interface Package {
    arrivalLocation: Array<string>;
    category: string;
    components: Array<PackageComponent>;
    departureList: Array<Departure>;
    departureLocation: Array<string>;
    name: string;
    externalName: string;
    id: string;
    length: number;
}

export function packageApiToLocal(apiPack: ApiPackage): Package {
    const pack: Package = {
        arrivalLocation: apiPack.arrival_location,
        category: apiPack.category,
        components: apiPack.components,
        departureList: [],
        departureLocation: apiPack.departure_location,
	name: apiPack.name,
        externalName: apiPack.external_name,
        id: apiPack.id,
        length: apiPack.length
    };

    return pack;
}
