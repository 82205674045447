<div *ngIf="hasAccommodation || hasCruise" class="slds-p-bottom--medium">
    <div class="rmc-heading rmc-heading--m slds-m-bottom--medium">
        {{preferenceTypes}} #{{roomIdx + 1}} Preferences
    </div>

    <div class="rmc-form">
        <div *ngIf="hasAccommodation" class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.bedding"
                label="Bedding"
                [loader]="loaders.bedding"
                [submitted]="submitted"
                [value]="cruiseDetails.bedding"
            >
                <rmc-picklist
                    [options]="beddingOptions"
                    [(value)]="cruiseDetails.bedding"
                    (valueChange)="onChange('bedding')"
                ></rmc-picklist>
            </rmc-form-element>
        </div>

        <div *ngIf="hasCruise" class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.cabin"
                label="Cruise Cabin"
                [loader]="loaders.cabin"
                [submitted]="submitted"
                [value]="cruiseDetails.cabin"
            >
                <rmc-picklist
                    [options]="cabinOptions"
                    [(value)]="cruiseDetails.cabin"
                    (valueChange)="onChange('cabin')"
                ></rmc-picklist>
            </rmc-form-element>
        </div>

        <div *ngIf="hasCruise" class="slds-m-bottom--large">
            <rmc-form-element
                [errors]="errors?.meal_seating"
                label="Cruise Meal Seating"
                [loader]="loaders.meal_seating"
                [submitted]="submitted"
                [value]="cruiseDetails.meal_seating"
            >
                <rmc-picklist
                    [options]="seatingOptions"
                    [(value)]="cruiseDetails.meal_seating"
                    (valueChange)="onChange('meal_seating')"
                ></rmc-picklist>
            </rmc-form-element>
        </div>
    </div>
</div>

