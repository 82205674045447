import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'rmc-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit {

    @Input()
    closeButton: boolean = true;

    @Output()
    close: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    ngOnInit() {
    }

    closeModal(): void {
        this.close.next();
    }
}
