import { Extensions } from './extensions';
import { Price } from './prices';
import { PromotionEffect } from './promotions';
import { Traveller, TravellerError } from './traveller';
import { RequiredSchema } from './validation';

export interface BookingDetails {
    city?: string;
    country?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    postal_code?: string;
    province?: string;
    street?: string;
}

export interface BookingDetailsError {
    address?: Array<string>;
    city?: Array<string>;
    country?: Array<string>;
    email?: Array<string>;
    first_name?: Array<string>;
    last_name?: Array<string>;
    phone_number?: Array<string>;
    postal_code?: Array<string>;
    province?: Array<string>;
    street?: Array<string>;
}

export interface CruiseDetails {
    bedding?: string;
    cabin?: string;
    meal_seating?: string;
}

export interface CruiseDetailsError {
    bedding?: Array<string>;
    cabin?: Array<string>;
    meal_seating?: Array<string>;
}

export interface PackageSelection {
    departure_date: string;
    package_id: string;
    service_level_id: string;
}

export interface PaymentDetails {
    deposit_type: string;
}

export interface PaymentDetailsError {
    deposit_type: Array<string>;
}

interface PromotionSelection {
    promotion_effects: Array<PromotionEffect>;
}

export interface SelectionCommons {
    booking_details?: BookingDetails;
    cruise_details?: CruiseDetails;
    cruise_details_by_room?: Array<CruiseDetails>;
    payment_details?: PaymentDetails;
    currency?: string;
    occupancies?: Array<Array<Traveller>>;
    tax_profile_id?: string;
    requires_accessibility_assistance?: boolean;
    aeroplan_summary_status?: string;
    promotion_code?: string;
    country_code?: string;
}

export interface SelectionCommonsError {
    booking_details?: BookingDetailsError;
    cruise_details?: CruiseDetailsError;
    cruise_details_by_room?: Array<CruiseDetailsError>;
    payment_details?: PaymentDetailsError;
    occupancies?: Array<Array<TravellerError>>;
}

export interface Selections extends Extensions {
    commons?: SelectionCommons;
    package?: PackageSelection;
    promotion?: PromotionSelection;
}

export interface SelectionsError {
    commons?: SelectionCommonsError;
}

export interface SelectionsPrice {
    commons?: Price;
    dinner?: Price;
    extra_post_night?: Price;
    extra_pre_night?: Price;
    insurance?: Price;
    package?: Price;
    post_activity?: Price;
    post_transfer?: Price;
    pre_activity?: Price;
    pre_transfer?: Price;
    promotion?: Price;
    package_promotion?: Price;
}

export const bookingDetailsRequiredSchema: RequiredSchema = {
    city: true,
    country: true,
    email: true,
    first_name: true,
    last_name: true,
    //phone_number: true,
    postal_code: true,
    province: false,
    street: true,
};

/**
 * Returns an UPDATE to originalObject that turns it to updatedObject.
 * The following expression is correct in general: updatedObject = Object.assign({}, originalObject, UPDATE).
 * Deleted fields are marked as undefined.
 * @param originalObject original object
 * @param updatedObject updated object
 */
export function getObjectUpdate(originalObject: object, updatedObject: object): object {
    const update: object = Object.create(null);

    // collect all the fields (from both originalObject and updatedObject)
    const fieldSet: Set<string | number> = new Set<string | number>();
    for (const obj of [originalObject, updatedObject]) {
        for (const field of Object.keys(obj)) {
            fieldSet.add(field);
        }
    }

    // check if the field values are changed or not
    const fields: Array<string | number> = Array.from(fieldSet);
    for (const field of fields) {
        if (
            originalObject[field] && typeof originalObject[field] === 'object' &&
            updatedObject[field] && typeof updatedObject[field] === 'object'
        ) {
            // if original value and updated value are both objects -> then call the check recursively
            const objectFieldUpdate: object = getObjectUpdate(originalObject[field], updatedObject[field]);

            // if field update contains any data -> then include field update to the object update
            if (Object.keys(objectFieldUpdate).length) {
                update[field] = objectFieldUpdate;
            }
        } else if (updatedObject[field] && typeof updatedObject[field] === 'object') {
            // if original value is not an object and after value is an object -> then include updated value to the object update
            update[field] = JSON.parse(JSON.stringify(updatedObject[field]));
        } else if (originalObject[field] !== updatedObject[field]) {
            // if original value and updated value are not objects -> then compare
            // if the value is changed -> then include updated value to the object update
            update[field] = updatedObject[field];
        }
    }

    return update;
}
