import { Component, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, Router, RouterEvent } from '@angular/router';

import { environment } from '../../../environments/environment';

import { AppService } from '../../services/app.service';
import { ConfigurationService } from '../../services/configuration.service';
import { NavigationService } from '../../services/navigation.service';
import { PromotionsService } from '../../services/promotions.service';

@Component({
    selector: 'rmc-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    hasCheckout = false;
    initialized = false;
    isSidebarOpen = false;
    modalCount = 0;
    needInit = true;
    promotionalNightsWarning = false;
    savedTripsUrl = `${environment.communitiesUrl}${environment.savedTripsPath}my-saved-trips`;
    title = 'app';
    tripSavedModal = false;

    constructor(
        private appService: AppService,
        private configurationService: ConfigurationService,
        private location: Location,
        private navigationService: NavigationService,
        private promotionsService: PromotionsService,
        private renderer: Renderer2,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.checkNoScroll();

        this.router.events.subscribe((event: RouterEvent) => {
            this.routerEventHandler(event);
        });

        this.appService.getModalCountChange().subscribe((modalCount: number) => {
            this.modalCount = modalCount;
            this.checkNoScroll();
        });

        this.promotionsService.onPromotionalNightsWarning().subscribe(() => {
            this.promotionalNightsWarning = true;
            this.appService.incrementModal();
        });
    }

    private checkNoScroll(): void {
        if (this.configurationService.isOpen() || this.modalCount) {
            this.renderer.addClass(document.body, environment.bodyNoScrollClass);
        } else {
            this.renderer.removeClass(document.body, environment.bodyNoScrollClass);
        }
    }

    private checkScrollTop(): void {
        const isSidebarAction = this.isSidebarOpen || this.configurationService.isOpen();

        if (!this.needInit && !isSidebarAction) {
            setTimeout(() => {
                document.documentElement.scrollTop = 0;
            });
        }

        this.isSidebarOpen = this.configurationService.isOpen();
    }

    closePromotionalNightsWarning(): void {
        this.promotionalNightsWarning = false;
        this.appService.decrementModal();
    }

    closeSavedModal(): void {
        this.tripSavedModal = false;
        this.appService.decrementModal();
    }

    private initApp(): void {
        this.initChannelAndBackUrlFromGetParams();
        this.appService.init().then(() => {
            this.initialized = true;
            this.appService.checkRouteValidity(true);

            const path: string = this.location.path();
            if (/[\?&]tripSaved=true/i.test(path)) {
                this.tripSavedModal = true;
                this.appService.incrementModal();
            }
        }).catch((error: any) => {
            console.warn(error);

            // if (!(error.status && error.status === 409)) {
            //     window.location.href = environment.fallbackRedirectLocation;
            // }
        });
    }

    getQueryParam(name: string) {
        return this.activatedRoute.snapshot.queryParamMap.get(name);
    }

    initChannelAndBackUrlFromGetParams() {
        // https://kaptio.atlassian.net/browse/RMPBD-7
        const businessUnit: string = this.getQueryParam('business_unit');
        if (businessUnit) {
            this.navigationService.businessUnit = businessUnit;
            if (environment.businessUnitToChannelMap[businessUnit]) {
                environment.channelId = environment.businessUnitToChannelMap[businessUnit];
                console.log(`=== BusinessUnit "${businessUnit}" FOUND! ChannelID was updated to ${environment.channelId}`);
            }
        }
        // https://kaptio.atlassian.net/browse/KT-6088
        const backUrl: string = this.getQueryParam('backUrl');
        if (backUrl) {
            this.navigationService.backUrl = backUrl;
            console.log('===', 'backUrl FOUND!:', this.navigationService.backUrl);
        }
    }

    private routerEventHandler(event: RouterEvent) {
        if (
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        ) {
            if (this.needInit) {
                this.initApp();
            }

            if (event instanceof NavigationEnd) {
                this.hasCheckout = this.navigationService.isCheckoutRoute();
                this.checkScrollTop();
                this.checkNoScroll();

                if (this.initialized) {
                    this.appService.checkRouteValidity();
                }
            }

            this.needInit = false;
        }
    }

    trackByIndex(index: number): number {
        return index;
    }
}
