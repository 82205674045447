<div *ngIf="isReady" class="promotions-border rmc-sidebar__content">
    <div class="rmc-sidebar__body">
        <div class="rmc-sidebar__scrollable">
            <div class="promotions-border rmc-sidebar__header">
                {{label}}: Add Promotion
                <img class="rmc-sidebar__close" src="/images/times.svg" (click)="close()" />
            </div>

            <div class="container">
                <div class="content">
                    <div *ngIf="nightsAvailable" class="section">
                        <div class="section-heading rmc-heading rmc-heading--m">
                            Hotels
                        </div>

                        <div *ngIf="preNightsAvailable" class="slds-p-bottom--medium">
                            <div class="selector rmc-count-input">
                                <div class="rmc-count-input__label">Add free hotel night(s) to beginning of your trip</div>
                                <a href="javascript:void(0)" class="rmc-count-input__decrease" (click)="decreasePreNights()">—</a>
                                <div class="rmc-count-input__count">{{ preNights }}</div>
                                <a href="javascript:void(0)" class="rmc-count-input__increase" (click)="increasePreNights()">+</a>
                            </div>
                        </div>

                        <div *ngIf="postNightsAvailable" class="slds-p-bottom--medium">
                            <div class="selector rmc-count-input">
                                <div class="rmc-count-input__label">Add free hotel night(s) to the end of your trip</div>
                                <a href="javascript:void(0)" class="rmc-count-input__decrease" (click)="decreasePostNights()">—</a>
                                <div class="rmc-count-input__count">{{ postNights }}</div>
                                <a href="javascript:void(0)" class="rmc-count-input__increase" (click)="increasePostNights()">+</a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isDinnerAvailable" class="section">
                        <div class="section-heading rmc-heading rmc-heading--m">
                            Dinner
                        </div>

                        <div class="slds-p-bottom--medium">
                            Dinner will be a set menu at one of the hotels within your itinerary. Please choose the destination where you’d like to receive your free dinner:
                        </div>

                        <div class="slds-p-bottom--large">
                            <rmc-switch
                                [options]="dinnerPicklistOptions"
                                type="separate"
                                [(value)]="dinnerLocationId"
                                (valueChange)="onDinnerChange()"
                            ></rmc-switch>
                        </div>

                        <div class="slds-p-bottom--medium">
                            <rmc-form-element [required]="true">
                                <rmc-checkbox [(value)]="hideDinner" (valueChange)="onHideDinner()">
                                    I do not require a dinner
                                </rmc-checkbox>
                            </rmc-form-element>
                        </div>
                    </div>

                    <div *ngIf="isTransfersAvailable" class="section">
                        <div class="section-heading rmc-heading rmc-heading--m">
                            Private Airport Transfer - Sedan
                        </div>

                        <div class="slds-p-bottom--medium">
                            You can select a transfer at the beginning or end of your journey. Please select one of the following:
                        </div>

                        <div class="slds-p-bottom--large">
                            <rmc-switch
                                [options]="transferPicklistOptions"
                                type="separate"
                                [(value)]="transfer"
                                (valueChange)="onTransferChange()"
                            ></rmc-switch>
                        </div>

                        <div class="slds-p-bottom--medium">
                            <rmc-form-element [required]="true">
                                <rmc-checkbox [(value)]="hideTransfer" (valueChange)="onHideTransfer()">
                                    I do not require a transfer
                                </rmc-checkbox>
                            </rmc-form-element>
                        </div>
                    </div>

                    <div *ngIf="transfer">
                        <div class="flight-heading slds-p-bottom--medium">
                            Flight Details
                        </div>

                        <div class="slds-p-bottom--medium">
                            <strong>Please provide your flight details so that we can arrange your airport pick-up or drop-off.</strong>
                        </div>

                        <rmc-transfer-details [details]="transferDetails"></rmc-transfer-details>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="promotions-border rmc-sidebar__footer">
        <div class="rmc-sidebar__footer-actions">
            <a href="javascript:void(0)" (click)="close()">Cancel</a>
        </div>

        <div class="rmc-sidebar__footer-total">
            Total: <strong>FREE</strong>
        </div>

        <div class="rmc-sidebar__footer-submit">
            <button class="rmc-button rmc-button--brand" type="button" (click)="confirm()">
                <rmc-button-bouncer *ngIf="isSaving"></rmc-button-bouncer>
                <span *ngIf="!isSaving">Confirm</span>
            </button>
        </div>
    </div>
</div>
