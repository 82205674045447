import { Component, OnInit } from '@angular/core';

import { BookingUpdateEvent } from '../../helpers/booking';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';

@Component({
    selector: 'rmc-managed-modal',
    templateUrl: './managed-modal.component.html',
    styleUrls: ['./managed-modal.component.scss']
})
export class ManagedModalComponent implements OnInit {

    isModalOpen = false;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();

        this.checkStatus();
        this.bookingService.listenEvents([
            BookingUpdateEvent.Status,
        ]).subscribe(() => {
            this.checkStatus();
        });
    }

    private checkStatus(): void {
        const status: number = this.bookingService.getStatus();
        const managed: boolean = (status === 110 || status === 210);

        if (managed !== this.isModalOpen) {
            this.isModalOpen = managed;

            if (this.isModalOpen) {
                this.appService.incrementModal();
            } else {
                this.appService.decrementModal();
            }
        }
    }
}
