import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';
import { PhrasesService } from '../../services/phrases.service';

@Component({
    selector: 'rmc-phrase',
    templateUrl: './phrase.component.html',
    styleUrls: ['./phrase.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhraseComponent implements OnInit {

    @Input()
    label: string;

    isEditor: boolean;

    constructor(
        private authService: AuthService,
        private phrasesService: PhrasesService,
    ) { }

    ngOnInit() {
        let account = this.authService.getAccount();
        if (account === null) {
            this.isEditor = false;
        } else {
            this.isEditor = account.isEditor;
        }
    }

    edit(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        this.phrasesService.openPhraseEdit(this.label);
    }
}
