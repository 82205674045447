import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Traveller } from 'src/app/helpers/traveller';

import { AppService } from '../../services/app.service';
import { GeoService } from '../../services/geo.service';
import { GuestDetailsService } from '../../services/guest-details.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { BookingService } from 'src/app/services/booking.service';

@Component({
    selector: 'rmc-configure-page',
    templateUrl: './configure-page.component.html',
    styleUrls: ['./configure-page.component.scss']
})
export class ConfigurePageComponent implements OnInit {

    showInsurance: boolean = true;
    showExtendYourJourney: boolean = true;
    isShowTransfer: boolean = false;
    CATEGORY_RAIL_ONLY_PACKAGE: string = 'Rail Only Package';


    constructor(
        private appService: AppService,
        private geoService: GeoService,
        private guestDetailsService: GuestDetailsService,
        private navigationService: NavigationService,
        private bookingService: BookingService
    ) { }

    async ngOnInit() {
        await this.appService.onInit();

        const rooms: Array<Array<Traveller>> = this.guestDetailsService.getGuestsByRooms();

        let tavelPackage = this.bookingService.getPackage()
        if (tavelPackage && tavelPackage.length === 1) {
            this.showExtendYourJourney = false;
            console.log('=== Hide Extend Your Journey section due to Tavel Package lenght = 2 days');
        }

        if (tavelPackage.category !== this.CATEGORY_RAIL_ONLY_PACKAGE) {
          this.isShowTransfer = true;
        }

        if (this.navigationService.businessUnit === 'rtr') {
            this.showInsurance = false;
            this.showExtendYourJourney = false;
            console.log('=== Hide Extend Your Journey AND Insurance sections due to RTR Package');
        }

        for (const room of rooms) {
            for (const guest of room) {
                if (
                    guest.country &&
                    guest.province &&
                    this.geoService.isHomeCountry(guest.country) &&
                    !this.geoService.isInsuranceHomeProvince(guest.province)
                ) {
                    this.showInsurance = false;
                }
            }
        }
    }
}
