import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { CaptchaResult } from '../helpers/captcha';
import { EmailForm, EmailRequest, emailRequiredSchema } from '../helpers/email';
import { isObjectFilled } from '../helpers/validation';

import { ApiService } from './api.service';
import { BookingService } from './booking.service';

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    private tripModalSubject: Subject<void> = new Subject<void>();
    private tripModalObservable: Observable<void> = this.tripModalSubject.asObservable();

    constructor(
        private apiService: ApiService,
        private bookingService: BookingService,
    ) { }

    /**
     * Checks if 'Email Plan' form is completely filled.
     * @param data 'Email Plan' form data.
     * @returns If 'Email Plan' form is completely filled.
     */
    isFormFilled(data: EmailForm): boolean {
        return isObjectFilled(data, emailRequiredSchema);
    }

    /**
     * Returns 'Email Plan' modal open event.
     * @returns 'Email Trip' modal open event.
     */
    onTripModal(): Observable<void> {
        return this.tripModalObservable;
    }

    /**
     * Opens 'Email Trip' modal.
     */
    openTripModal(): void {
        this.tripModalSubject.next();
    }

    /**
     * Sends 'Email Plan' request to the API.
     * @param data 'Email Plan' form data.
     * @param captcha Google reCAPTCHA result. 
     */
    async sendEmail(data: EmailForm, captcha: CaptchaResult): Promise<void> {
        const request: EmailRequest = {
            captcha,
            custom_data: {
                booking_id: this.bookingService.getBookingId(),
                custom_message: data.custom_message,
                sender: data.sender,
                sender_email: data.sender_email,
            },
            message_type: 'trip-configuration',
            recipient_email: data.recipient_email,
            recipient_name: data.recipient_name,
        };

        await this.apiService.post(`${environment.apiUrl}emails/emails/send/`, request);
    }
}
