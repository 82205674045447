import { Component, OnInit } from '@angular/core';

import { BookingUpdateEvent } from '../../helpers/booking';
import { SelectionCommons } from '../../helpers/selections';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { GuestDetailsService } from '../../services/guest-details.service';
import { SnapengageService } from '../../services/snapengage.service';

@Component({
    selector: 'rmc-guests-details-accessibility',
    templateUrl: './guests-details-accessibility.component.html',
    styleUrls: ['./guests-details-accessibility.component.scss']
})
export class GuestsDetailsAccessibilityComponent implements OnInit {

    accessibilityCheckbox: boolean;
    isReady = false;
    saveRequests = 0;
    selectionCommons: SelectionCommons;
    serviceCode: string;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private guestDetailsService: GuestDetailsService,
        private snapengageService: SnapengageService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;

        this.serviceCode = this.bookingService.getServiceCode();

        this.init();
        this.bookingService.listenEvents([
            BookingUpdateEvent.Accessibility,
        ]).subscribe(() => {
            if (!this.saveRequests) { // check if this change is external
                this.init();
            }
        });
    }

    private init(): void {
        this.accessibilityCheckbox = this.requiresAccessibilityAssistance();
    }

    onAccessibilityChange(): void {
        this.saveRequests++;
        this.guestDetailsService.updateAccessibility(this.accessibilityCheckbox).then(() => {
            this.saveRequests--;
        }, () => {
            this.saveRequests--;
        });
    }

    openChat(): void {
        this.snapengageService.openChat();
    }

    requiresAccessibilityAssistance(): boolean {
        return this.guestDetailsService.requiresAccessibilityAssistance();
    }
}
