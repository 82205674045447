import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { ApiChannel, ApiEditorialSet, ApiPhrase, ApiPhrases, Channel, channelApiToLocal, EditorialSet, editorialSetApiToLocal, editorialSetsApiToLocal, NewPhrase, Phrase, phraseApiToLocal, phrasesApiToLocal } from '../helpers/phrases';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class PhrasesService {

    private channel: Channel;
    private editorialNames: Map<string, string> = new Map<string, string>();

    private phraseEditSource: Subject<string> = new Subject<string>();
    private phraseEdit: Observable<string> = this.phraseEditSource.asObservable();

    constructor(
        private apiService: ApiService
    ) { }

    async createEditorialSet(name: string): Promise<void> {
        return this.apiService.post(`${environment.apiUrl}editorial-sets/editorial-sets/`, {
            name,
            order: 1
        }).then((apiEditorialSet: ApiEditorialSet) => {
            const editorialSet: EditorialSet = editorialSetApiToLocal(apiEditorialSet);
            this.editorialNames.set(editorialSet.url, editorialSet.name);
        });
    }

    async createPhrase(newPhrase: NewPhrase): Promise<Phrase> {
        return this.apiService.post(`${environment.apiUrl}editorial-sets/phrases/`, {
            editorial_set: newPhrase.editorialSet,
            label: newPhrase.label,
            text: newPhrase.text
        }).then((apiPhrase: ApiPhrase) => {
            return phraseApiToLocal(apiPhrase);
        });
    }

    async deletePhrase(id: string): Promise<void> {
        return this.apiService.delete(`${environment.apiUrl}editorial-sets/phrases/${id}/`);
    }

    getEditorialNames(): Map<string, string> {
        return this.editorialNames;
    }

    async getLabelPhrases(label: string): Promise<Array<Phrase>> {
        return this.apiService.get(`${environment.apiUrl}editorial-sets/phrases/?labels=${label}`).then((apiPhrases: ApiPhrases) => {
            const phrases: Array<Phrase> = phrasesApiToLocal(apiPhrases);

            return phrases;
        });
    }

    getPhrase(label: string): string {
        return this.channel.phrases[label] || label;
    }

    getPhraseEdit(): Observable<string> {
        return this.phraseEdit;
    }

    async init(): Promise<void> {
        await Promise.all([
            this.apiService.get(`${environment.apiUrl}accounts/channels/${environment.channelId}/`),
            this.apiService.get(`${environment.apiUrl}editorial-sets/editorial-sets/`)
        ]).then((results) => {
            const apiChannel: ApiChannel = results[0];
            this.channel = channelApiToLocal(apiChannel);

            const editorialSets: Array<EditorialSet> = editorialSetsApiToLocal(results[1]);
            editorialSets.forEach((editorialSet: EditorialSet) => {
                this.editorialNames.set(editorialSet.url, editorialSet.name);
            });
        });
    }

    openPhraseEdit(label: string): void {
        this.phraseEditSource.next(label);
    }

    async updatePhrase(phrase: Phrase): Promise<void> {
        await this.apiService.patch(phrase.url, {
            text: phrase.text
        });
    }
}
