import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rmc-guests-page',
    templateUrl: './guests-page.component.html',
    styleUrls: ['./guests-page.component.scss']
})
export class GuestsPageComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}
