<div class="rmc-page-header">
    <div class="rmc-page-header__content">
        <div class="rmc-page-header__title rmc-grow">
            <div *ngIf="!isReady" class="tour-dates rmc-placeholder rmc-placeholder--max-20">&nbsp;</div>
            <div *ngIf="isReady && departureDate" class="tour-dates">
                {{departureDate | date: dateFormat}} – {{arrivalDate | date: dateFormat}}
            </div>

            <div *ngIf="!isReady" class="tour-name rmc-placeholder rmc-placeholder--max-40">&nbsp;</div>
            <div *ngIf="isReady" class="tour-name">
                {{package.externalName}}
            </div>

            <div *ngIf="!isReady" class="tour-details rmc-placeholder rmc-placeholder--max-20">&nbsp;</div>
            <div *ngIf="isReady" class="tour-details">
                {{getOccupancyLabel()}}.
                {{package.departureLocation[0]}} - {{package.arrivalLocation[0]}}.
                {{serviceLevelLabel}}.
            </div>
        </div>

        <rmc-pricing></rmc-pricing>
    </div>
</div>
