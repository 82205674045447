import { Component, OnInit } from '@angular/core';

import { BookingUpdateEvent } from '../../helpers/booking';
import { Traveller } from '../../helpers/traveller';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { GuestDetailsService } from '../../services/guest-details.service';

@Component({
    selector: 'rmc-guests-details',
    templateUrl: './guests-details.component.html',
    styleUrls: ['./guests-details.component.scss']
})
export class GuestsDetailsComponent implements OnInit {

    guestDetailsRequestCounter = 0;
    hasCruise: boolean;
    isReady = false;
    rooms: Array<Array<Traveller>>;

    constructor(
        private appService: AppService,
        public bookingService: BookingService,
        public guestDetailsService: GuestDetailsService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;

        this.init();
        this.bookingService.listenEvents([
            BookingUpdateEvent.Occupancy,
            BookingUpdateEvent.TravellerDetails,
        ]).subscribe(() => {
            if (!this.guestDetailsRequestCounter) { // check if this change is external
                this.init();
            }
        });
    }

    private init(): void {
        this.rooms = this.guestDetailsService.getGuestsByRooms();
    }

    async guestUpdated(guest: Traveller, roomIdx: number, guestIdx: number) {
        // console.log('===========');
        // console.log(roomIdx, guestIdx);
        // console.log(guest);
    }

    async billingDetailsUpdated(billingDetails) {
        // console.log('===========');
        // console.log(billingDetails);
    }

    isLoading(): boolean {
        return this.guestDetailsService.isLoading();
    }

    isSubmitted(): boolean {
        return this.guestDetailsService.isSubmitted();
    }

    async submit() {
        if (!this.isLoading()) {
            this.guestDetailsService.submitDetails(this.rooms);
        }
    }

    requiresAccessibilityAssistance(): boolean {
        return this.guestDetailsService.requiresAccessibilityAssistance();
    }

    trackByIndex(index: number): number {
        return index;
    }
}
