<rmc-modal *ngIf="isModalOpen" (close)="close()">
    <div class="rmc-modal__header">
        <rmc-phrase label="LABEL_ITINERARY"></rmc-phrase>
    </div>
    <div class="rmc-modal__body">
        <div *ngIf="groupedItinerary">
            <a href="javascript:void(0)" *ngIf="isAllExpanded()" class="expand" (click)="closeSections()">- Close All -</a>
            <a href="javascript:void(0)" *ngIf="!isAllExpanded()" class="expand" (click)="expandSections()">- Expand All -</a>

            <div *ngFor="let date of dates; let dateIndex = index; trackBy: trackByIndex" class="section" [ngClass]="{
                'open': sectionOpen[dateIndex]
            }">
                <div class="section-header" (click)="toggleSection(dateIndex)">
                    <div class="section-icon-container">
                        <img class="section-icon" src="/images/brochure-{{getIcon(date)}}.svg">
                    </div>
                    <div class="section-name">Day {{dateIndex + 1}}: {{date | date: dateFormat}}</div>
                    <div class="section-arrow">
                        <img class="dropdown-icon" src="/images/dropdown-caret.svg" width="21" height="12">
                    </div>
                </div>

                <div class="section-body">
                    <div *ngIf="groupedItinerary[date].otherActivities.length" class="description-item">
                        <div class="rmc-heading rmc-heading--m">
                            Activities & Tours
                        </div>
                        <div *ngFor="let service of groupedItinerary[date].otherActivities; trackBy: trackServiceItem">
                            {{service.item.external_name}}
                            <span *ngIf="service.item_option">({{service.item_option.external_name}})</span>
                        </div>
                    </div>

                    <div *ngIf="groupedItinerary[date].transfers.length" class="description-item">
                        <div class="rmc-heading rmc-heading--m">
                            Transfers
                        </div>
                        <div *ngFor="let service of groupedItinerary[date].transfers; trackBy: trackServiceItem">
                            {{service.item.external_name}}
                            <span *ngIf="service.item_option">({{service.item_option.external_name}})</span>
                        </div>
                    </div>

                    <div *ngIf="groupedItinerary[date].accommodations.length" class="description-item">
                        <div class="rmc-heading rmc-heading--m">
                            Accommodation
                        </div>
                        <div *ngFor="let service of groupedItinerary[date].accommodations; trackBy: trackServiceItem">
                            {{service.item.external_name}}
                            <span *ngIf="service.item_option">({{service.item_option.external_name}})</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-actions">
            <button *ngIf="groupedItinerary" class="rmc-button rmc-button--brand" (click)="close()">Close</button>
            <button *ngIf="!groupedItinerary" class="rmc-button rmc-button--brand">
                <rmc-button-bouncer></rmc-button-bouncer>
            </button>
        </div>
    </div>
</rmc-modal>
