import { Component, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';
import { GuestDetailsService } from '../../services/guest-details.service';

@Component({
    selector: 'rmc-insurance-modal',
    templateUrl: './insurance-modal.component.html',
    styleUrls: ['./insurance-modal.component.scss']
})
export class InsuranceModalComponent implements OnInit {

    isModalOpen = false;
    province: string;

    constructor(
        private appService: AppService,
        private guestDetailsService: GuestDetailsService,
    ) { }

    ngOnInit() {
        this.guestDetailsService.onInsuranceModal().subscribe((province: string) => {
            this.isModalOpen = true;
            this.appService.incrementModal();

            this.province = province;
        });
    }

    close(): void {
        this.isModalOpen = false;
        this.appService.decrementModal();
    }
}
