import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SnapengageService {

    constructor() { }

    /**
     * Opens SnapEngage chat widget.
     */
    openChat() {
        // @ts-ignore: This is taken from global js snippet
        SnapEngage.startChat("n/a");
    }
}
