<div class="rmc-card slds-p-around--large slds-m-bottom--medium" *ngIf="isReady">
    <div class="rmc-heading rmc-heading--m slds-m-bottom--medium">
        <rmc-phrase label="CANCELLATION_POLICY_HEADLINE"></rmc-phrase>
    </div>

    <div class="body">
        <p><rmc-phrase label="CANCELLATION_POLICY_DESCRIPTION"></rmc-phrase>
        </p>

        <table>
            <tr>
                <th>
                    <rmc-phrase label="CANCELLATION_POLICY_CANCEL_AFTER"></rmc-phrase>
                </th>
                <th class="price-container">
                    <rmc-phrase label="CANCELLATION_POLICY_NON_REFUNDABLE_AMOUNT"></rmc-phrase>
                </th>
            </tr>
            <tr *ngFor="let payment of (getPrices() ? getPrices().raw.payment_schedule : []); trackBy: trackByIndex">
                <td>
                    <span *ngIf="payment.day_offset > 0">{{ payment.due_date | date : 'mediumDate' }}</span>
                    <span *ngIf="payment.day_offset == 0"><rmc-phrase label="TODAY"></rmc-phrase></span>
                </td>
                <td class="price-container">
                    <span class="price__value">{{ payment.value | envCurrency }}</span>
                    <span class="price__currency">{{currency.code}}</span>
                </td>
            </tr>
        </table>
    </div>
</div>
