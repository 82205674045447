<div *ngIf="isReady" class="slds-p-bottom--medium">
    <div class="rmc-heading rmc-heading--m slds-m-bottom--medium">
        Mobility Requirements
    </div>

    <div class="rmc-form">
        <div class="slds-m-bottom--large">
            <rmc-form-element [required]="true">
                <rmc-checkbox [(value)]="accessibilityCheckbox" (valueChange)="onAccessibilityChange()">
                    <rmc-phrase label="REQUIRE_ASSISTANCE_CHECKMARK"></rmc-phrase>
                </rmc-checkbox>
            </rmc-form-element>
        </div>
    </div>

    <div *ngIf="requiresAccessibilityAssistance()" class="slds-m-bottom--large">
        <div class="slds-m-bottom--large">
            <rmc-phrase label="DESCRIPTION_ACCESSIBILITY_NEEDS"></rmc-phrase>
        </div>

        <div class="slds-m-bottom--large">
            <strong><rmc-phrase label="SERVICE_CODE"></rmc-phrase>:</strong> {{serviceCode}}
        </div>

        <div>
            <button class="button rmc-button rmc-button--brand" type="button" (click)="openChat()">
                <rmc-phrase label="CONTACT_EXPERT_CTA"></rmc-phrase>
            </button>

            <button class="button rmc-button rmc-button--neutral" type="button" *ngIf="false">
                Save your trip
            </button>
        </div>
    </div>
</div>
