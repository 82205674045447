<div class="slds-p-bottom--medium">
    <div class="rmc-heading rmc-heading--m slds-m-bottom--medium">
        Room {{roomIndex + 1}}
    </div>

    <div *ngIf="guests.length" class="slds-grid slds-wrap">
        <div *ngFor="let traveller of guests; let guestIndex = index; trackBy: trackByIndex" class="guest slds-col slds-size--1-of-1 slds-medium-size--1-of-2 slds-p-bottom--medium">
            <div class="name slds-m-bottom--small">
                {{getName(traveller)}}
            </div>

            <div class="details">
                <div *ngIf="traveller.date_of_birth">Date of Birth: {{traveller.date_of_birth | date: dateFormat}}</div>
                <div *ngIf="isInternational && traveller.nationality">Country of Residence: {{getNationality(traveller)}}</div>
                <div *ngIf="isInternational && traveller.passport">Passport Number: {{traveller.passport}}</div>
                <div *ngIf="isInternational && traveller.passport_expiry">Passport Expiry Date: {{traveller.passport_expiry | date: dateFormat}}</div>
                <div *ngIf="traveller.dietary_requests">Dietary Requests: {{getDiet(traveller)}}</div>
            </div>

            <div class="actions slds-m-top--x-small">
                <a href="javascript:void(0)" (click)="editGuest(guestIndex)">Edit {{getName(traveller)}} Details</a>
            </div>
        </div>
    </div>
</div>
