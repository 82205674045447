import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConfigurePageComponent } from './components/configure-page/configure-page.component';
import { ConfirmationPageComponent } from './components/confirmation-page/confirmation-page.component';
import { GuestsPageComponent } from './components/guests-page/guests-page.component';
import { ReviewPageComponent } from './components/review-page/review-page.component';
import { SidebarExtendJourneyComponent } from './components/sidebar-extend-journey/sidebar-extend-journey.component';
import { SidebarInsuranceComponent } from './components/sidebar-insurance/sidebar-insurance.component';
import { SidebarPromotionsComponent } from './components/sidebar-promotions/sidebar-promotions.component';
import { SidebarTransfersComponent } from './components/sidebar-transfers/sidebar-transfers.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'configure',
        pathMatch: 'full',
    },
    {
        path: 'configure',
        component: ConfigurePageComponent,
        children: [
            {
                path: 'extend-journey',
                component: SidebarExtendJourneyComponent,
            },
            {
                path: 'insurance',
                component: SidebarInsuranceComponent,
            },
            {
                path: 'promotions',
                component: SidebarPromotionsComponent,
            },
            {
                path: 'transfers',
                component: SidebarTransfersComponent,
            },
        ],
    },
    {
        path: 'guest-details',
        component: GuestsPageComponent,
    },
    {
        path: 'review',
        component: ReviewPageComponent,
    },
    {
        path: 'confirmation',
        component: ConfirmationPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
