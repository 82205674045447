export const weekdayLabels: Array<string> = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];
export const weekLength: number = weekdayLabels.length;

const invalidDate = 'Invalid Date';

const millisecondsInSecond = 1000;
const secondsInMinute = 60;
const millisecondsInMinute = millisecondsInSecond * secondsInMinute;

export function dateStringToDate(dateString: string): Date {
    if (!dateString) {
        return null;
    }

    const timezoneDate: Date = new Date(dateString);
    if (!isDateValid(timezoneDate)) {
        return null;
    }

    const timezoneOffset: number = timezoneDate.getTimezoneOffset() * millisecondsInMinute;
    const date: Date = new Date(timezoneDate.getTime() + timezoneOffset);

    return date;
}

export function dateToDateString(date: Date): string {
    const timezoneOffset: number = date.getTimezoneOffset() * millisecondsInMinute;
    const timezoneDate: Date = new Date(date.getTime() - timezoneOffset);
    const dateString: string = timezoneDate.toISOString().split('T')[0];

    return dateString;
}

export function getWeekday(date: Date): number {
    return (date.getDay() || weekLength) - 1;
}

function isDateValid(date: Date) {
    return String(date) !== invalidDate;
}
