import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BookingService } from './booking.service';
import { environment } from '../../environments/environment';
import { NavigationService } from './navigation.service';

@Injectable({
    providedIn: 'root'
})
export class ReviewService {

    private checkingAvailability = false;
    private conditionsAccepted = false;
    private itineraryModalSubject: Subject<void> = new Subject<void>();
    private itineraryModalObservable: Observable<void> = this.itineraryModalSubject.asObservable();
    private loading = false;
    private submitted = false;

    constructor(
        private bookingService: BookingService,
        private navigationService: NavigationService
    ) {}

    /**
     * Checks if seats are available for this booking.
     * @returns if seats are available.
     */
    private async checkAvailability(): Promise<boolean> {
        this.checkingAvailability = true;

        return await this.bookingService.checkAvailability().then(() => {
            this.checkingAvailability = false;
            return true;
        }).catch(() => {
            this.checkingAvailability = false;
            return false;
        });
    }

    /**
     * Returns 'Accept Terms & Conditions' checkbox value.
     * @returns 'Accept Terms & Conditions' checkbox value.
     */
    get conditionsCheckbox(): boolean {
        return this.conditionsAccepted;
    }

    /**
     * Sets 'Accept Terms & Conditions' checkbox value.
     * @param value Checkbox value.
     */
    set conditionsCheckbox(value: boolean) {
        this.conditionsAccepted = value;
    }

    /**
     * Returns payment page URL.
     * @returns Payment page URL.
     */
    private getPaymentUrl() {
        const bookingId: string = this.bookingService.getBookingId();
        let next: string = document.location.protocol + '//' + document.location.hostname;

        const requiresPort: boolean = (
            (document.location.protocol == 'http:' && document.location.port != '80') ||
            (document.location.protocol == 'https:' && document.location.port != '443')
        );
        if (requiresPort) {
            next += ':' + document.location.port;
        }

        // confirmationRedirectParams HACK is required as Exact Payment Gateway break next url
        const confirmationRedirectParams = btoa(`booking=${bookingId}&backUrl=${encodeURIComponent(this.navigationService.backUrl)}&business_unit=${this.navigationService.businessUnit}`);
        next += `/confirmation?bb=${confirmationRedirectParams}`;

        return (
            this.bookingService.getPaymentUrl() +
            '?next=' + encodeURIComponent(next) +
            '&cid=' + `${environment.channelId}`
        );
    }

    /**
     * Checks if 'Check seats availability' request is running.
     * @returns If 'Check seats availability' request is running.
     */
    isCheckingAvailability(): boolean {
        return this.checkingAvailability;
    }

    /**
     * Checks if any service requests are running.
     * @returns If any service requests are running.
     */
    isLoading(): boolean {
        return this.loading || this.checkingAvailability;
    }

    /**
     * Checks if review form has already been submitted.
     * @returns If review form has already been submitted.
     */
    isSubmitted(): boolean {
        return this.submitted;
    }

    /**
     * Itinerary modal open event.
     */
    onItineraryModal(): Observable<void> {
        return this.itineraryModalObservable;
    }

    /**
     * Opens itinerary modal.
     */
    openItineraryModal(): void {
        this.itineraryModalSubject.next();
    }

    /**
     * Submits review form.
     */
    async submitReview(): Promise<void> {
        this.submitted = true;

        if (this.conditionsAccepted) {
            const isAvailable: boolean = await this.checkAvailability();

            if (isAvailable) {
                this.loading = true;
                document.location.href = this.getPaymentUrl();
            }
        } else {
            // form is invalid
            setTimeout(() => {
                // scroll to the first error
                document.querySelector('.rmc-field-error').scrollIntoView();
            });
        }
    }

    async reserveBooking(): Promise<void> {
        this.submitted = true;

        if (this.conditionsAccepted) {
            const isAvailable: boolean = await this.checkAvailability();

            if (isAvailable) {
                this.loading = true;
                try {
                    await this.bookingService.reserveBooking();
                    top.location.reload();
                } catch (e) {
                    console.log("*** reserveBooking ERROR");
                    console.log(e)
                    alert('Internal Server Error. Please contact administrator.')
                }
            }
        } else {
            // form is invalid
            setTimeout(() => {
                // scroll to the first error
                document.querySelector('.rmc-field-error').scrollIntoView();
            });
        }
    }

}
