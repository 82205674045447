<div class="rmc-card rmc-card--thumbnail" *ngIf="!isPricesReady || hasChoices()">
    <div class="rmc-card__thumbnail rmc-placeholder">
        <img *ngIf="isPricesReady" class="rmc-card__thumbnail-image" src="/images/extend-journey.jpg">
    </div>

    <div class="rmc-card__price" *ngIf="isPricesReady && !hasSelection()">
        <div class="price-label">FROM</div>
        <div class="rmc-price">
            <span class="rmc-price__value">{{ getMinimumRate() | envCurrency: true }}</span>
            <span class="rmc-price__currency">{{currency.code}}</span>
        </div>
    </div>

    <div class="rmc-card__price--selected" *ngIf="hasSelection() && isPricesReady">
        {{ getTotalRate() | envCurrency }} {{currency.code}}
    </div>

    <div class="rmc-card__info">
        <div *ngIf="!isPricesReady" class="rmc-card__title rmc-placeholder rmc-placeholder--max-20">&nbsp;</div>
        <div *ngIf="isPricesReady" class="rmc-card__title">
            <rmc-phrase label="HEADLINE_CONFIGURE_EXTEND_JOURNEY"></rmc-phrase>
        </div>

        <div *ngIf="!isPricesReady" class="rmc-card__description rmc-placeholder">&nbsp;</div>
        <div *ngIf="isPricesReady" class="rmc-card__description">
            <rmc-phrase label="BODY_CONFIGURE_EXTEND_JOURNEY"></rmc-phrase>
        </div>

        <div *ngIf="!isPricesReady" class="rmc-placeholder rmc-placeholder--button">&nbsp;</div>
        <a href="javascript:void(0)" *ngIf="isPricesReady" class="rmc-button rmc-button--neutral" (click)="openExtendJourneySidebar()">
            <rmc-phrase *ngIf="!hasSelection()" label="ACTION_CONFIGURE_EXTEND_JOURNEY"></rmc-phrase>
            <rmc-phrase *ngIf="hasSelection()" label="ACTION_EDIT_CONFIGURE_EXTEND_JOURNEY"></rmc-phrase>
        </a>
    </div>
</div>
