<div *ngIf="isReady" [ngClass]="{
    'dropdown': dropdown
}">
    <div *ngIf="dropdown" class="option-container">
        <div class="rmc-pricing-option rmc-pricing-option--headline">
            <div class="rmc-pricing-option__name">
                <rmc-phrase label="PRICE_SUMMARY"></rmc-phrase>
            </div>
        </div>
    </div>

    <div class="option-container">
        <div class="rmc-pricing-option">
            <div class="rmc-pricing-option__name">
                <rmc-phrase label="PACKAGE_PRICE"></rmc-phrase>
            </div>
            <div class="rmc-pricing-option__value">
                {{ getPrices()?.raw.prices_by_types.package.service | envCurrency }}
            </div>
            <div class="rmc-pricing-option__currency">{{currency.code}}</div>
        </div>
    </div>

    <div *ngIf="bookingService.hasPackagePromotionSelectionApp() && bookingService.packagePromotionsFromBooking && bookingService.packagePromotionsFromBooking.length > 0" class="option-container">
        <div *ngFor="let promotion of bookingService.packagePromotionsFromBooking">
            <div class="rmc-pricing-option">
                <div class="rmc-pricing-option__name">
                    {{ promotion.promotion_name }}
                    <span *ngIf="promotion.promotion_code && promotion.promotion_code == bookingService.promotionCodeFromBooking"><button class="delete-button" type="button" (click)="removePromotionCode()" matTooltip="Remove Promotion Code">
                        <img class="delete-icon" src="/images/remove-addon.png">
                    </button></span>
                </div>
                <div class="rmc-pricing-option__value">
			{{ promotion.price_total || 0 | envCurrency }}
                </div>
                <div class="rmc-pricing-option__currency">{{currency.code}}</div>
            </div>
        </div>
    </div>

    <div *ngIf="isPromotionsSelected" class="option-container">
        <div class="rmc-pricing-option">
            <div class="rmc-pricing-option__name">
                {{promotionName}}
            </div>
        </div>

        <rmc-pricing-addon
            *ngIf="promotionNights.pre"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            [label]="getPreNightsLabel(true)"
            [price]="0"
            (remove)="removeFreePreNights()"
        ></rmc-pricing-addon>

        <rmc-pricing-addon
            *ngIf="promotionNights.post"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            [label]="getPostNightsLabel(true)"
            [price]="0"
            (remove)="removeFreePostNights()"
        ></rmc-pricing-addon>

        <rmc-pricing-addon
            *ngIf="hasFreeDinner"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            label="Dinner"
            [price]="0"
            (remove)="removeFreeDinner()"
        ></rmc-pricing-addon>

        <rmc-pricing-addon
            *ngIf="freeTransferType"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            [label]="freeTransferType === 'pre' ? preTransferTitle : postTransferTitle"
            [price]="0"
            (remove)="removeFreeTransfer()"
        ></rmc-pricing-addon>
    </div>

    <div *ngIf="isAddonsAdded()" class="option-container">
        <div class="rmc-pricing-option">
            <div class="rmc-pricing-option__name">
                <rmc-phrase label="ADD_ONS"></rmc-phrase>
            </div>
        </div>

        <rmc-pricing-addon
            *ngIf="paidPreNights"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            [label]="getPreNightsLabel(false)"
            [price]="getSelectionPrice('extra_pre_night')"
            (remove)="removePaidPreNights()"
        ></rmc-pricing-addon>

        <rmc-pricing-addon
            *ngIf="isActivitiesEnabled && getSelectionPrice('pre_activity')"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            [label]="getActivityTitle('pre_activity')"
            [loading]="!isActivitiesReady"
            [price]="getSelectionPrice('pre_activity')"
            (remove)="removePreActivity()"
        ></rmc-pricing-addon>

        <rmc-pricing-addon
            *ngIf="paidPostNights"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            [label]="getPostNightsLabel(false)"
            [price]="getSelectionPrice('extra_post_night')"
            (remove)="removePaidPostNights()"
        ></rmc-pricing-addon>

        <rmc-pricing-addon
            *ngIf="isActivitiesEnabled && getSelectionPrice('post_activity')"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            [label]="getActivityTitle('post_activity')"
            [loading]="!isActivitiesReady"
            [price]="getSelectionPrice('post_activity')"
            (remove)="removePostActivity()"
        ></rmc-pricing-addon>

        <rmc-pricing-addon
            *ngIf="getSelectionPrice('pre_transfer')"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            [label]="preTransferTitle"
            [loading]="!isTransfersReady"
            [price]="getSelectionPrice('pre_transfer')"
            (remove)="removePreTransfer()"
        ></rmc-pricing-addon>

        <rmc-pricing-addon
            *ngIf="getSelectionPrice('post_transfer')"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            [label]="postTransferTitle"
            [loading]="!isTransfersReady"
            [price]="getSelectionPrice('post_transfer')"
            (remove)="removePostTransfer()"
        ></rmc-pricing-addon>

        <rmc-pricing-addon
            *ngIf="getSelectionPrice('insurance')"
            [disabled]="isLoading()"
            [dropdown]="dropdown"
            label="Insurance"
            [price]="getSelectionPrice('insurance')"
            (remove)="removeInsurance()"
        ></rmc-pricing-addon>
    </div>

    <div *ngIf="!dropdown" class="option-container">
        <div class="rmc-pricing-option">
            <div class="rmc-pricing-option__name">
                <rmc-phrase label="SUBTOTAL"></rmc-phrase>
            </div>
            <div class="rmc-pricing-option__value">
                {{ getPrices()?.getSubtotal() - getPrices()?.getFee() | envCurrency }}
            </div>
            <div class="rmc-pricing-option__currency">{{currency.code}}</div>
        </div>
    </div>

    <div class="option-container">
        <div class="rmc-pricing-option">
            <div class="rmc-pricing-option__name">
                <rmc-phrase [label]="dropdown ? 'TAX' : 'TAX_GST'"></rmc-phrase>
            </div>
            <div class="rmc-pricing-option__value">
                {{ getPrices()?.getTax() | envCurrency }}
            </div>
            <div class="rmc-pricing-option__currency">{{currency.code}}</div>
        </div>
    </div>

    <div *ngIf="getPrices()?.getFee()" class="option-container">
        <div class="rmc-pricing-option">
            <div class="rmc-pricing-option__name">
                <rmc-phrase label="FEE"></rmc-phrase>
            </div>
            <div class="rmc-pricing-option__value">
                {{ getPrices()?.getFee() | envCurrency }}
            </div>
            <div class="rmc-pricing-option__currency">{{currency.code}}</div>
        </div>
    </div>


    <div class="option-container">
        <div class="rmc-pricing-option">
            <div class="rmc-pricing-option__name" [ngClass]="{
                'rmc-pricing-option__name--strong': !dropdown
            }">
                <rmc-phrase label="TOTAL"></rmc-phrase>
            </div>
            <div class="rmc-pricing-option__value">
                {{ getPrices()?.getTotal() | envCurrency }}
            </div>
            <div class="rmc-pricing-option__currency">{{currency.code}}</div>
        </div>
    </div>

    <div *ngIf="!dropdown && getPrices() && (getPrices()?.getDeposit() != getPrices()?.getTotal())" class="rmc-pricing-option rmc-pricing-option--footer">
        <div class="rmc-pricing-option__name">
            <rmc-phrase label="DEPOSIT"></rmc-phrase>
        </div>
        <div class="rmc-pricing-option__value">
            {{ getPrices()?.getDeposit() | envCurrency }}
        </div>
        <div class="rmc-pricing-option__currency">{{currency.code}}</div>
    </div>

    <div *ngIf="!dropdown && getPrices() && (getPrices()?.getDeposit() != getPrices()?.getTotal())" class="rmc-pricing-option rmc-pricing-option--footer">
        <div class="rmc-pricing-option__name">
            <rmc-phrase label="FINAL_PAYMENT_DATE"></rmc-phrase>: {{getPrices()?.getFinalDate() | date: dateFormat}}
        </div>
    </div>

    <div *ngIf="!dropdown && bookingService.hasPackagePromotionSelectionApp()" style="margin-top: 20px;">
        <div>
            <label class="rmc-form-element__label">Promo Code</label>
            <div style="display: inline-block; width: 56%; vertical-align: middle;" [class]="{'rmc-field-error': bookingService.isPromoCodeInvalid, 'rmc-field-success': bookingService.promotionCodeFromBooking && !bookingService.isPromoCodeInvalid}">
                <input
                    class="rmc-input"
                    name="postal_code"
                    type="text"
                    placeholder="ENTER CODE"
                    [(ngModel)]="bookingService.promotionCode"
                    style="height: 45px;"
                />
            </div>
            &nbsp;&nbsp;
            <button class="rmc-button rmc-button--brand" (click)="applyPromotionCodeChange()">
                <rmc-button-bouncer *ngIf="bookingService.waitingForPromotion"></rmc-button-bouncer>
                <span *ngIf="!bookingService.waitingForPromotion">Apply</span>
            </button>
        </div>
        <div *ngIf="bookingService.isPromoCodeInvalid && bookingService.packagePromotionManualCodeWarning == 'a99'" class="rmc-form-element__help">
            <span style="color: #b71523;">The promo code you entered is invalid.</span>
        </div>
        <div *ngIf="bookingService.isPromoCodeInvalid && bookingService.packagePromotionManualCodeWarning == 'a1'" class="rmc-form-element__help">
            <span style="color: #b71523;">This promo code cannot be used in conjunction with another promotion</span>
        </div>
        <div *ngIf="bookingService.promotionCodeFromBooking && !bookingService.isPromoCodeInvalid" class="rmc-form-element__help">
            <span style="color: #028048;">Your promo code was applied successfully.</span>
        </div>
    </div>


</div>
