<div *ngIf="isReady" class="help rmc-help">
    <div class="content">
        <div class="title">
            <rmc-phrase label="HEADLINE_QUESTIONS"></rmc-phrase>
        </div>

        <div class="rmc-help__agent rmc-help__agent--l">
            <div class="rmc-help__agent-photo rmc-help__agent-photo--l"></div>
        </div>

        <div class="rmc-help__info">
            <rmc-phrase label="BODY_QUESTIONS"></rmc-phrase>
        </div>

        <a href="javascript:void(0)" class="rmc-help__chat-button rmc-button rmc-button--neutral" type="button" (click)="openChat()">
            <rmc-phrase label="ACTION_QUESTIONS_CHAT"></rmc-phrase>
        </a>

        <a class="rmc-help__call-us" href="tel:{{servicePhone}}">
            <rmc-phrase label="ACTION_QUESTIONS_CALL"></rmc-phrase>
            (Service Code: {{serviceCode}})
        </a>
    </div>
</div>
