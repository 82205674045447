import { ChoicePrices } from './choices';
import { Extensions } from './extensions';
import { Occupancy } from './occupancy';
import { Selections, SelectionsError } from './selections';

export interface Booking {
    departure_date: string;
    extras_configuration: Extensions;
    id: string;
    confirmation_number: string;
    modified_at?: string;
    occupancy: Occupancy;
    occupancy_option_url: string;
    package_id: string;
    payment_url: string;
    promotions: Array<string>;
    selections?: Selections;
    service_code: string;
    service_level_id: string;
    tax_profile_id: string;
    url: string;
    status: number;
    user_meta: Object;
    payments: Array<Payment>;
}

export interface BookingError {
    departure_date?: Array<string>;
    extras_configuration?: Array<string>;
    promotional_services?: Array<string>;
    promotions?: Array<string>;
    selections?: SelectionsError;
}

export interface BookingPrices extends ChoicePrices {
    payment_schedule?: any;
}

export interface BookingStatusUpdate {
    origin: string;
    status: number;
}

export interface BookingUpdate {
    departure_date?: string;
    extras_configuration?: Extensions;
    occupancy?: Occupancy;
    promotions?: Array<string>;
    selections?: Selections;
    service_level_id?: string;
    tax_profile_id?: string;
}

export enum BookingUpdateEvent {
    Accessibility = 'Accessibility',
    BookingDetails = 'BookingDetails',
    BookingDetailsInit = 'BookingDetailsInit',
    CruiseDetails = 'CruiseDetails',
    ExtraPostNights = 'ExtraPostNights',
    ExtraPreNights = 'ExtraPreNights',
    Insurance = 'Insurance',
    Occupancy = 'Occupancy',
    PaymentDetails = 'PaymentDetails',
    PostActivity = 'PostActivity',
    PostTransfer = 'PostTransfer',
    PreActivity = 'PreActivity',
    PreTransfer = 'PreTransfer',
    Promotions = 'Promotions',
    Status = 'Status',
    TravellerDetails = 'TravellerDetails',
}

export interface Payment {
    id: string;
    status: number;
    amount: number;
    currency: string;
    payment_response: object;
}
