import { Traveller, TravellerCount } from './traveller';

export interface Accommodation {
    occupancy: Occupancy;
    priceDelta: number;
}

export interface ApiAccommodation {
    occupancy: Occupancy;
    price_delta: string;
}

export type Occupancy = Array<OccupancyItem>;

export interface OccupancyItem {
    travellers: Array<String>;
    unit: {
        description: string;
        id: string;
        name: string;
        max_people: number;
    };
}

export interface UrlRoom {
    order: number;
    travellers: Array<Traveller>;
}

export function accommodationApiToLocal(apiAccommodation: ApiAccommodation): Accommodation {
    const accommodation: Accommodation = {
        occupancy: apiAccommodation.occupancy,
        priceDelta: Number(apiAccommodation.price_delta)
    };

    return accommodation;
}

export function formatEnumeration(parts: Array<string>, andAsWord: boolean = false): string {
    const and: string = andAsWord ? 'and' : '&';

    if (parts.length > 2) {
        return `${parts.slice(0, -1).join(', ')} ${and} ${parts.slice(-1)}`;
    } else {
        return parts.join(` ${and} `);
    }
}
