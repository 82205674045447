import { Component, OnInit } from '@angular/core';

import { BookingUpdateEvent } from '../../helpers/booking';
import { Transfer, PrePost } from '../../helpers/extensions';
import { Currency } from '../../helpers/prices';
import { ProductOption } from '../../helpers/products';
import { Selections } from 'src/app/helpers/selections';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { ConfigurationService } from '../../services/configuration.service';
import { PromotionsService } from '../../services/promotions.service';
import { TransferService } from '../../services/transfer.service';

@Component({
    selector: 'rmc-sidebar-transfers',
    templateUrl: './sidebar-transfers.component.html',
    styleUrls: ['./sidebar-transfers.component.scss']
})
export class SidebarTransfersComponent implements OnInit {

    currency: Currency;
    isReady = false;
    isSaving = false;
    postTransfer: Transfer;
    postTransferTitle: string;
    preTransfer: Transfer;
    preTransferTitle: string;
    promotionalTransfer: string;
    rerender = false;
    showRates = false;
    transferOptions: PrePost<Array<ProductOption>>;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private configurationService: ConfigurationService,
        private promotionsService: PromotionsService,
        private transferService: TransferService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        await this.bookingService.initPriceOptions();

        await this.init();
        this.bookingService.listenEvents([
            BookingUpdateEvent.PostTransfer,
            BookingUpdateEvent.PreTransfer,
            BookingUpdateEvent.Promotions,
        ]).subscribe(async () => {
            if (!this.isSaving) { // check if this change is external
                this.isReady = false;
                await this.init();
                this.isReady = true;
            }
        });

        this.currency = this.bookingService.getCurrency();
        this.isReady = true;
        this.showRates = true;
    }

    close(): void {
        this.configurationService.close();
    }

    getTotal(): number {
        let total: number = 0;

        for (const option of this.transferOptions.pre) {
            if (
                option.product && option.product.id === this.preTransfer.id ||
                !option.product && !this.preTransfer.id
            ) {
                total += option.price;
                break;
            }
        }

        for (const option of this.transferOptions.post) {
            if (
                option.product && option.product.id === this.postTransfer.id ||
                !option.product && !this.postTransfer.id
            ) {
                total += option.price;
                break;
            }
        }

        return total;
    }

    private async init(): Promise<void> {
        const initPromise: Promise<void> = this.transferService.initAccommodations();
        const optionsPromise: Promise<void> = this.transferService.getOptions().then((transferOptions: PrePost<Array<ProductOption>>) => {
            this.transferOptions = transferOptions;
        });

        await Promise.all([
            initPromise,
            optionsPromise,
        ]);

        this.preTransferTitle = this.transferService.getPreTransferTitle();
        this.postTransferTitle = this.transferService.getPostTransferTitle();

        const selections: Selections = this.bookingService.getSelections();
        this.preTransfer = selections.pre_transfer || {
            direction: 'from_airport_to_hotel',
            id: null,
        };
        this.postTransfer = selections.post_transfer || {
            direction: 'from_hotel_to_airport',
            id: null,
        };
        this.promotionalTransfer = this.promotionsService.getTransferSelection();
    }

    async updateTransfers(): Promise<void> {
        if (this.isSaving) {
            return;
        }

        this.isSaving = true;
        await this.bookingService.setTransfers(this.preTransfer, this.postTransfer).catch(() => {});
        this.isSaving = false;

        this.close();
    }
}
