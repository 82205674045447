<rmc-breadcrumbs></rmc-breadcrumbs>
<rmc-tour-header></rmc-tour-header>
<rmc-configure-promotions></rmc-configure-promotions>

<div class="rmc-container narrow">
    <div class="rmc-tour-heading rmc-heading rmc-heading--m">
        Personalize Your Journey
    </div>

    <div class="cards">
        <div *ngIf="showExtendYourJourney" class="card">
            <rmc-configure-extra-nights></rmc-configure-extra-nights>
        </div>

        <div *ngif="isShowTransfer" class="card">
            <rmc-configure-transfer></rmc-configure-transfer>
        </div>

        <div *ngIf="showInsurance" class="card">
            <rmc-configure-insurance></rmc-configure-insurance>
        </div>

        <div class="card">
            <rmc-configure-help></rmc-configure-help>
        </div>
    </div>
</div>

<rmc-sidebar></rmc-sidebar>
