<div class="rmc-form-element" [ngClass]="{
    'rmc-form-element--disabled': disabled,
    'rmc-field-error': isError(),
    'rmc-field-success': isSuccess()
}">
    <label class="rmc-form-element__label" *ngIf="label">
        {{label}}
        <span class="rmc-form-element__optional" *ngIf="!required">optional</span>
    </label>

    <div class="rmc-form-element__control">
        <ng-content></ng-content>

        <svg *ngIf="isSuccess()" class="success-checkmark">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/checkmark.svg#check"></use>
        </svg>
    </div>

    <div *ngIf="isRequiredMessage()" class="rmc-form-element__help">
        {{requiredMessage || 'This field is required.'}}
    </div>
    <div *ngIf="isErrorMessage()" class="rmc-form-element__help">
        <div *ngFor="let error of errors; trackBy: trackByIndex">
            {{error}}
        </div>
    </div>
</div>
