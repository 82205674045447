import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { environment } from '../../../environments/environment';
import { PicklistOption, PicklistOptionLabelPart, PicklistOptionModel } from '../../helpers/picklist';

import { PicklistService } from '../../services/picklist.service';

@Component({
    selector: 'rmc-picklist',
    templateUrl: './picklist.component.html',
    styleUrls: ['./picklist.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PicklistComponent implements OnChanges {

    @Input()
    disabled = false;

    @Input()
    options: Array<PicklistOption>;

    @Input()
    value: string;
    @Output()
    valueChange: EventEmitter<string> = new EventEmitter<string>();

    disabledAutocomplete: number = Math.random();
    isOpen = false;
    label: string;
    optionModels: Array<PicklistOptionModel>;
    query = '';
    selectIndex = 0;

    constructor(
        private elementRef: ElementRef,
        private picklistService: PicklistService,
    ) { }

    ngOnChanges() {
        if (this.value) {
            const selectedOption: PicklistOption = this.options.find((option: PicklistOption): boolean => {
                return option.value === this.value;
            });

            if (selectedOption) {
                this.label = selectedOption.label;
            }
        }

        this.buildOptionModels();
    }

    private buildOptionModels(): void {
        this.optionModels = this.options.filter((option: PicklistOption): boolean => {
            return this.isSearch()
                ? this.picklistService.testLabel(option.label, this.query)
                : true;
        }).map((option: PicklistOption): PicklistOptionModel => {
            const labelParts: Array<PicklistOptionLabelPart> = this.isSearch()
                ? this.picklistService.splitLabel(option.label, this.query)
                : this.picklistService.getDefaultLabelParts(option.label);

            return {
                label: option.label,
                labelParts,
                value: option.value,
            };
        });
    }

    private checkScroll(): void {
        setTimeout(() => {
            const element: HTMLElement = this.elementRef.nativeElement;
            const list: HTMLElement = element.querySelector('.options');
            const selected: HTMLElement = element.querySelector('.option--selected');

            const listRect: ClientRect = list.getBoundingClientRect();
            const selectedRect: ClientRect = selected.getBoundingClientRect();

            if (selectedRect.top < listRect.top) {
                list.scrollTop -= listRect.top - selectedRect.top;
            } else if (selectedRect.bottom > listRect.bottom) {
                list.scrollTop += selectedRect.bottom - listRect.bottom;
            }
        });
    }

    closePicklist(): void {
        this.isOpen = false;
        this.query = '';
        this.buildOptionModels();
    }

    isSearch(): boolean {
        return this.query.length > 0;
    }

    onInputKey(event: KeyboardEvent): void {
        switch (event.key) {
            case environment.keyCodes.arrowDown:
                event.preventDefault();

                ++this.selectIndex;
                if (this.selectIndex >= this.optionModels.length) {
                    this.selectIndex = 0;
                }

                this.checkScroll();

                break;
            case environment.keyCodes.arrowUp:
                event.preventDefault();

                --this.selectIndex;
                if (this.selectIndex < 0) {
                    this.selectIndex = this.optionModels.length - 1;
                }

                this.checkScroll();

                break;
            case environment.keyCodes.enter:
                if (this.isOpen) {
                    this.selectOption(this.selectIndex);
                } else {
                    this.openPicklist();
                }

                break;
            case environment.keyCodes.escape:
                this.closePicklist();
                break;
            case environment.keyCodes.tab:
                setTimeout(() => { // let the default behavior run firstly
                    this.closePicklist(); // and then close the picklist
                });
                break;
        }
    }

    onOptionHover(index: number): void {
        this.selectIndex = index;
    }

    openPicklist(): void {
        if (this.disabled) {
            return;
        }

        if (!this.isOpen) {
            if (this.value) {
                this.selectIndex = this.options.findIndex((option: PicklistOption): boolean => {
                    return option.value === this.value;
                });
            } else {
                this.selectIndex = 0;
            }
        }

        this.isOpen = true;
        this.setFocus('.search-input');
    }

    search(): void {
        this.selectIndex = 0;
        this.buildOptionModels();

        if (this.optionModels.length) {
            const optionModelMatch: PicklistOptionModel = this.optionModels.find((optionModel: PicklistOptionModel): boolean => {
                return optionModel.label === this.query;
            });

            if (optionModelMatch) {
                this.setValue(optionModelMatch.value);
            } else {
                this.setValue(undefined);
            }
        } else {
            this.setValue(undefined);
        }
    }

    selectOption(index: number): void {
        const optionModel: PicklistOptionModel = this.optionModels[index];

        this.isOpen = false;
        this.label = optionModel.label;

        this.setValue(optionModel.value);
    }

    private setFocus(selector: string): void {
        setTimeout(() => {
            const element: HTMLElement = this.elementRef.nativeElement;
            const input: HTMLInputElement = element.querySelector(selector);
            input.focus();
        });
    }

    private setValue(value: string): void {
        if (value !== this.value) {
            this.value = value;
            this.valueChange.emit(this.value);
        }
    }

    trackByIndex(index: number): number {
        return index;
    }

    trackOptionModel(index: number, optionModel: PicklistOptionModel): string {
        return optionModel.value;
    }
}
