import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { PhrasesService } from '../services/phrases.service';

@Pipe({
    name: 'phrase'
})
export class PhrasePipe implements PipeTransform {

    constructor(
        private phrasesService: PhrasesService,
        private sanitizer: DomSanitizer
    ) { }

    transform(label: string): SafeHtml {
        let phrase = this.phrasesService.getPhrase(label);
        return this.sanitizer.bypassSecurityTrustHtml(phrase);
    }
}
