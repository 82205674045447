import { Component, OnInit } from '@angular/core';
import { debounce } from 'lodash';

import { environment } from '../../../environments/environment';
import { NewPhrase, Phrase } from '../../helpers/phrases';

import { PhrasesService } from '../../services/phrases.service';

const savedStatusTimer = 700;

@Component({
    selector: 'rmc-edit-phrase-modal',
    templateUrl: './edit-phrase-modal.component.html',
    styleUrls: ['./edit-phrase-modal.component.scss']
})
export class EditPhraseModalComponent implements OnInit {

    private debouncedSave: (phrase: Phrase) => void;
    private editorialNames: Map<string, string>;
    editorialOptions: Array<string>;
    isEditModal = false;
    isNewEditorialSetModal = false;
    label: string;
    newEditorialSet = '';
    newPhrase: NewPhrase;
    phrases: Array<Phrase>;
    status: string = null;
    statusSaved = 'saved';
    statusSaving = 'saving';

    constructor(
        private phrasesService: PhrasesService
    ) { }

    ngOnInit() {
        this.phrasesService.getPhraseEdit().subscribe((label: string) => {
            this.isEditModal = true;
            this.label = label;
            this.init();
        });

        this.debouncedSave = debounce(this.save, environment.autosaveInterval);
    }

    addEditorialSet(): void {
        this.isEditModal = false;
        this.isNewEditorialSetModal = true;
    }

    addPhrase(): void {
        this.newPhrase = {
            editorialSet: '',
            label: this.label,
            text: this.label
        };
    }

    createEditorialSet(): void {
        this.startSaving();

        this.phrasesService.createEditorialSet(this.newEditorialSet).then(() => {
            this.newEditorialSet = '';
            this.isNewEditorialSetModal = false;
            this.isEditModal = true;

            this.init();
            this.finishSaving();
        }).catch(() => {
            this.finishSaving();
        });
    }

    deletePhrase(index: number): void {
        this.startSaving();

        this.phrasesService.deletePhrase(this.phrases[index].id).then(() => {
            this.finishSaving();
        }).catch(() => {
            this.finishSaving();
        });

        this.phrases.splice(index, 1);
    }

    private finishSaving(): void {
        this.status = this.statusSaved;

        setTimeout(() => {
            this.status = null;
        }, savedStatusTimer);
    }

    getEditorialName(url: string): string {
        return this.editorialNames.get(url);
    }

    private init(): void {
        this.editorialNames = this.phrasesService.getEditorialNames();

        this.phrasesService.getLabelPhrases(this.label).then((phrases: Array<Phrase>) => {
            this.phrases = phrases;
            this.initEditorialOptions();
        });
    }

    private initEditorialOptions(): void {
        this.editorialOptions = Array.from(this.editorialNames.keys()).filter((editorialSet: string): boolean => {
            const usage: Phrase = this.phrases.find((phrase: Phrase): boolean => {
                return phrase.editorialSet === editorialSet;
            });

            return !usage;
        });
    }

    isAddButton(): boolean {
        return this.editorialOptions && this.editorialOptions.length && !this.newPhrase;
    }

    onNewEditorialChange(): void {
        if (this.newPhrase.editorialSet) {
            this.startSaving();

            this.phrasesService.createPhrase(this.newPhrase).then((phrase: Phrase) => {
                this.phrases.push(phrase);
                this.newPhrase = null;
                this.initEditorialOptions();

                this.finishSaving();
            }).catch(() => {
                this.finishSaving();
            });
        }
    }
    
    onNewEditorialSetModalClose(): void {
        this.isEditModal = true;
    }

    onPhraseChange(phrase: Phrase): void {
        this.debouncedSave(phrase);
    }

    private save(phrase: Phrase): void {
        this.startSaving();

        this.phrasesService.updatePhrase(phrase).then(() => {
            this.finishSaving();
        }).catch(() => {
            this.finishSaving();
        });
    }

    private startSaving(): void {
        this.status = this.statusSaving;
    }

    trackByIndex(index: number): number {
        return index;
    }

    trackPhrase(index: number, phrase: Phrase): string {
        return phrase.id;
    }
}
