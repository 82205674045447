<div class="rmc-sidebar__content" *ngIf="isReady">
    <div class="rmc-sidebar__body">
        <div class="rmc-sidebar__scrollable">
            <div class="rmc-sidebar__header">
                Extend Your Journey
                <img class="rmc-sidebar__close" src="/images/times.svg" (click)="discard()" />
            </div>

            <div class="heading">
                Your Dates: <span class="mute">{{ getDepartureDate() | date : 'longDate' }} – {{ getArrivalDate() | date : 'longDate' }}</span>

                <div *ngIf="showRates" class="update-tag" [ngClass]="{transparent: !hasSelectionChanged()}">
                    <svg class="icon-checkmark">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/checkmark.svg#check"></use>
                    </svg>
                    Updated
                </div>
            </div>

            <div class="extra-night-selections">

                <div class="extra-night-selection" *ngIf="hasChoices('extra_pre_night')">
                    <div class="add-extra-nights">
                        <div class="hero-image"
                            *ngIf="package"
                            [ngStyle]="{'background-image': 'url(\'/images/locations/' + package.departureLocation[0].toLowerCase().replace(' ', '-') + '.jpg\')'}"></div>
                        <div class="body">
                            <div *ngIf="showRates && !preNights" class="price-corner">
                                <div class="price-label">
                                    per night
                                    <span *ngIf="!isExtraNightSamePrice('extra_pre_night')">from</span>
                                </div>
                                <div class="rmc-price">
                                    <span class="rmc-price__value">{{ getMinimumExtraNightRate('extra_pre_night') | envCurrency: true }}</span>
                                    <span class="rmc-price__currency">{{currency.code}}</span>
                                </div>
                            </div>

                            <div *ngIf="showRates && preNights" class="rmc-card__price--selected rmc-card__price--sidebar">
                                {{ getSelectionRate('extra_pre_night', preNights) | envCurrency }} {{currency.code}}
                            </div>

                            <h3>
                                Extend Your Stay In
                                <span *ngIf="package">{{ package.departureLocation[0] }}</span>
                            </h3>

                            <p>
                                <rmc-phrase [label]="'EXTEND_STAY_IN_' + package.departureLocation[0].toUpperCase().replace(' ', '_')"></rmc-phrase>
                            </p>

                            <p *ngIf="showRates">
                                <span class="label">Your accomodation in <span *ngIf="package">{{ package.departureLocation[0] }}</span>:</span>
                                {{getExternalName('extra_pre_night', 'item')}} – {{getExternalName('extra_pre_night', 'service')}}
                            </p>

                            <a href="javascript:void(0)" class="rmc-button rmc-button--brand" (click)="setPreNights(1)" *ngIf="choices.extra_pre_night[1] && preNights !== 1">Add 1 extra night</a>
                            <a href="javascript:void(0)" class="rmc-button rmc-button--success" (click)="setPreNights(0)" *ngIf="choices.extra_pre_night[1] && preNights === 1">
                                <svg class="icon-checkmark">
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/checkmark.svg#check"></use>
                                </svg>
                                Added 1 extra night
                            </a>

                            <a href="javascript:void(0)" class="rmc-button rmc-button--neutral" (click)="setPreNights(2)" *ngIf="choices.extra_pre_night[2] && preNights !== 2">Add 2 extra nights</a>
                            <a href="javascript:void(0)" class="rmc-button rmc-button--success" (click)="setPreNights(0)" *ngIf="choices.extra_pre_night[2] && preNights === 2">
                                <svg class="icon-checkmark">
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/checkmark.svg#check"></use>
                                </svg>
                                Added 2 extra nights
                            </a>

                        </div>
                    </div>
                </div>

                <div class="extra-night-selection" *ngIf="hasChoices('extra_post_night')">
                    <div class="add-extra-nights">
                        <div class="hero-image"
                            *ngIf="package"
                            [ngStyle]="{'background-image': 'url(\'/images/locations/' + package.arrivalLocation[0].toLowerCase().replace(' ', '-') + '.jpg\')'}"></div>
                        <div class="body">
                            <div *ngIf="showRates && !postNights" class="price-corner">
                                <div class="price-label">
                                    per night
                                    <span *ngIf="!isExtraNightSamePrice('extra_post_night')">from</span>
                                </div>
                                <div class="rmc-price">
                                    <span class="rmc-price__value">{{ getMinimumExtraNightRate('extra_post_night') | envCurrency: true }}</span>
                                    <span class="rmc-price__currency">{{currency.code}}</span>
                                </div>
                            </div>

                            <div *ngIf="showRates && postNights" class="rmc-card__price--selected rmc-card__price--sidebar">
                                {{ getSelectionRate('extra_post_night', postNights) | envCurrency }} {{currency.code}}
                            </div>

                            <h3>
                                Extend Your Stay In
                                <span *ngIf="package">{{ package.arrivalLocation[0] }}</span>
                            </h3>

                            <p>
                                <rmc-phrase [label]="'EXTEND_STAY_IN_' + package.arrivalLocation[0].toUpperCase().replace(' ', '_')"></rmc-phrase>
                            </p>

                            <p *ngIf="showRates">
                                <span class="label">Your accomodation in <span *ngIf="package">{{ package.arrivalLocation[0] }}</span>:</span>
                                {{getExternalName('extra_post_night', 'item')}} – {{getExternalName('extra_post_night', 'service')}}
                            </p>

                            <a href="javascript:void(0)" class="rmc-button rmc-button--brand" (click)="setPostNights(1)" *ngIf="choices.extra_post_night[1] && postNights !== 1">Add 1 extra night</a>
                            <a href="javascript:void(0)" class="rmc-button rmc-button--success" (click)="setPostNights(0)" *ngIf="choices.extra_post_night[1] && postNights === 1">
                                <svg class="icon-checkmark">
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/checkmark.svg#check"></use>
                                </svg>
                                Added 1 extra night
                            </a>

                            <a href="javascript:void(0)" class="rmc-button rmc-button--neutral"
                            (click)="setPostNights(2)"
                            *ngIf="choices.extra_post_night[2] && postNights !== 2">
                                    Add 2 extra nights
                            </a>
                            <a href="javascript:void(0)" class="rmc-button rmc-button--success"
                            (click)="setPostNights(0)"
                            *ngIf="choices.extra_post_night[2] && postNights === 2">
                                    <svg class="icon-checkmark">
                                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/checkmark.svg#check"></use>
                                    </svg>
                                    Added 2 extra nights
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="rmc-sidebar__footer">
        <div class="rmc-sidebar__footer-actions">
            <a href="javascript:void(0)" (click)="discard()">Cancel</a>
        </div>

        <div *ngIf="showRates && activityPrices.pre && activityPrices.post" class="rmc-sidebar__footer-total">
            Total: {{ getExtendedJourneyRate() | envCurrency }} {{currency.code}}
        </div>

        <div class="rmc-sidebar__footer-submit">
            <button class="rmc-button rmc-button--brand" (click)="confirm()">
                <rmc-button-bouncer *ngIf="saveRequests"></rmc-button-bouncer>
                <span *ngIf="!saveRequests">Confirm</span>
            </button>
        </div>
    </div>
</div>
