import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { BookingUpdateEvent } from '../../helpers/booking';
import { dateToDateString } from '../../helpers/date';
import { PrePost } from '../../helpers/extensions';
import { Package } from '../../helpers/package';
import { Currency } from '../../helpers/prices';
import { ProductOption } from '../../helpers/products';

import { ActivitiesService } from '../../services/activities.service';
import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { ConfigurationService } from '../../services/configuration.service';
import { PromotionsService } from '../../services/promotions.service';

@Component({
    selector: 'rmc-sidebar-extend-journey',
    templateUrl: './sidebar-extend-journey.component.html',
    styleUrls: ['./sidebar-extend-journey.component.scss']
})
export class SidebarExtendJourneyComponent implements OnInit {

    activityPrices: PrePost<boolean> = {
        pre: false,
        post: false,
    };
    choices: any = {};
    currency: Currency;
    private initialActivities: PrePost<string>;
    private initialNightCount: PrePost<number>;
    isActivitiesEnabled: boolean = environment.activities;
    isReady = false;
    package: Package;
    private postActivity: string = null;
    postActivityOptions: Array<ProductOption>;
    postNights: number;
    private preActivity: string = null;
    private preActivityOption: ProductOption = null;
    private postActivityOption: ProductOption = null;
    preActivityOptions: Array<ProductOption>;
    preNights: number;
    private promotionNights: PrePost<number>;
    saveRequests = 0;
    showRates = false;

    constructor(
        private activitiesService: ActivitiesService,
        private appService: AppService,
        private bookingService: BookingService,
        private configurationService: ConfigurationService,
        private promotionsService: PromotionsService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;

        this.currency = this.bookingService.getCurrency();
        this.package = this.bookingService.getPackage();

        await this.init(true);
        this.bookingService.listenEvents([
            BookingUpdateEvent.ExtraPostNights,
            BookingUpdateEvent.ExtraPreNights,
            BookingUpdateEvent.PostActivity,
            BookingUpdateEvent.PreActivity,
            BookingUpdateEvent.Promotions,
        ]).subscribe(() => {
            if (!this.saveRequests) { // check if this change is external
                this.init();
            }
        });

        this.showRates = true;
    }

    async confirm(): Promise<void> {
        if (this.saveRequests) {
            return;
        }

        this.configurationService.close();
    }

    async discard(): Promise<void> {
        if (this.hasSelectionChanged()) {
            this.preNights = this.initialNightCount.pre;
            this.postNights = this.initialNightCount.post;
            this.preActivity = this.initialActivities.pre;
            this.postActivity = this.initialActivities.post;

            this.activitiesService.refreshOptions('pre_activity');
            this.activitiesService.refreshOptions('post_activity');
            this.save();
        }

        this.configurationService.close();
    }

    findActivityOption(options: Array<ProductOption>, productId: string): ProductOption {
        return options.find((option: ProductOption): boolean => {
            return option.product.id === productId;
        });
    }

    getArrivalDate(): Date {
        const arrivalDate: Date = this.bookingService.getArrivalDate();

        return new Date(
            arrivalDate.getFullYear(),
            arrivalDate.getMonth(),
            arrivalDate.getDate() + this.postNights,
        );
    }

    getDepartureDate(): Date {
        const departureDate: Date = this.bookingService.getDepartureDate();

        return new Date(
            departureDate.getFullYear(),
            departureDate.getMonth(),
            departureDate.getDate() - this.preNights,
        );
    }

    getExtendedJourneyRate() {
        let result = 0;
        result += this.getSelectionRate('extra_pre_night', this.preNights);
        result += this.getSelectionRate('extra_post_night', this.postNights);

        if (this.preActivityOptions && this.preActivity) {
            const preActivity: ProductOption = this.findActivityOption(this.preActivityOptions, this.preActivity);

            if (preActivity) {
                result += preActivity.price;
            }
        }

        if (this.postActivityOptions && this.postActivity) {
            const postActivity: ProductOption = this.findActivityOption(this.postActivityOptions, this.postActivity);

            if (postActivity) {
                result += postActivity.price;
            }
        }

        return result;
    }

    getExternalName(nightType: string, level: string): string {
        let choices = this.choices[nightType];
        if (!choices) {
            return null;
        }

        for (let _choice in choices) {
            if (!choices.hasOwnProperty(_choice)) {
                continue;
            }
            let choice = choices[_choice];
            let extras = choice.extra;
            if (!extras) {
                continue;
            }
            for (let extra of extras) {
                if (extra.entry_type != "price_category") {
                    continue;
                }
                if (level == 'item') {
                    return extra.item_external_name;
                }
                if (level == 'service') {
                    return extra.service_external_name;
                }
            }
        }
        return null;
    }

    getMinimumExtraNightRate(nightType: string) {
        let prices = [];
        for (let choice in this.choices[nightType]) {
            let extraNightPrice = this.choices[nightType][choice].price.prices_by_types[nightType];

            if (extraNightPrice.service == 0) {
                continue;
            }

            // @ts-ignore
            let perNightPrice = extraNightPrice.service / choice;
            prices.push(perNightPrice);
        }

        return Math.min(...prices);
    }

    getSelectionRate(type: string, count: number): number {
        let price;
        if (this.choices[type]) {
            price = this.choices[type][count].price.prices_by_types[type];
        }

        if (price) {
            return price.total - price.tax;
        } else {
            return 0;
        }
    }

    hasChoices(nightType: string): boolean {
        let choices = this.choices[nightType];
        return choices && Object.keys(choices).length > 1;
    }

    hasSelectionChanged(): boolean {
        const nightsChanged: boolean = (
            this.preNights !== this.initialNightCount.pre ||
            this.postNights !== this.initialNightCount.post
        );

        const activitiesChanges: boolean = environment.activities && (
            this.preActivity !== this.initialActivities.pre ||
            this.postActivity !== this.initialActivities.post
        );

        return nightsChanged || activitiesChanges;
    }

    private async init(firstInit: boolean = false): Promise<void> {
        this.preNights = this.bookingService.getPreNights();
        this.postNights = this.bookingService.getPostNights();

        if (firstInit) {
            this.initialNightCount = {
                post: this.postNights,
                pre: this.preNights,
            };
        }

        await this.bookingService.initPriceOptions();
        this.initExtraNights();

        if (environment.activities) {
            await this.initActivities(firstInit);
        }
    }

    private async initActivities(firstInit: boolean = false): Promise<void> {
        const optionPromises: Array<Promise<void>> = [];

        this.preActivity = this.activitiesService.getSelectionId('pre_activity');
        optionPromises.push(this.initPreActivityOptions());

        this.postActivity = this.activitiesService.getSelectionId('post_activity');
        optionPromises.push(this.initPostActivityOptions());

        if (firstInit) {
            this.initialActivities = {
                post: this.postActivity,
                pre: this.preActivity,
            };
        }

        await Promise.all(optionPromises);
    }

    private initExtraNights(): void {
        let preNightChoices = this.bookingService.getChoices().extra_pre_night;
        this.choices['extra_pre_night'] = {};
        for (let choice of preNightChoices) {
            if (choice.selections['extra_pre_night']) {
                let count = choice.selections['extra_pre_night']['count'];
                this.choices['extra_pre_night'][count] = choice;
            } else {
                this.choices['extra_pre_night'][0] = choice;
            }
        }

        let postNightChoices = this.bookingService.getChoices().extra_post_night;
        this.choices['extra_post_night'] = {};
        for (let choice of postNightChoices) {
            if (choice.selections['extra_post_night']) {
                let count = choice.selections['extra_post_night']['count'];
                this.choices['extra_post_night'][count] = choice;
            } else {
                this.choices['extra_post_night'][0] = choice;
            }
        }

        this.promotionNights = this.promotionsService.getExtraNightsSelection();
    }

    async initPostActivityOptions(): Promise<void> {
        this.activityPrices.post = false;
        this.postActivityOptions = null;

        if (this.postNights) {
            this.saveRequests++;

            const noPricePromise: Promise<Array<ProductOption>> = this.activitiesService.getOptions('post_activity', false);
            const pricePromise: Promise<Array<ProductOption>> = this.activitiesService.getOptions('post_activity', true);

            noPricePromise.then((noPriseOptions: Array<ProductOption>): void => {
                this.postActivityOptions = noPriseOptions;
            });

            Promise.all([
                noPricePromise,
                pricePromise,
            ]).then((results: Array<Array<ProductOption>>): void => {
                this.postActivityOptions = results[1];
                this.activityPrices.post = true;
                this.saveRequests--;
            }).catch((): void => {
                this.saveRequests--;
            });
        }
    }

    async initPreActivityOptions(): Promise<void> {
        this.activityPrices.pre = false;
        this.preActivityOptions = null;

        if (this.preNights) {
            this.saveRequests++;

            const noPricePromise: Promise<Array<ProductOption>> = this.activitiesService.getOptions('pre_activity', false);
            const pricePromise: Promise<Array<ProductOption>> = this.activitiesService.getOptions('pre_activity', true);

            noPricePromise.then((noPriseOptions: Array<ProductOption>): void => {
                this.preActivityOptions = noPriseOptions;
            });

            Promise.all([
                noPricePromise,
                pricePromise,
            ]).then((results: Array<Array<ProductOption>>): void => {
                this.preActivityOptions = results[1];
                this.activityPrices.pre = true;
                this.saveRequests--;
            }).catch((): void => {
                this.saveRequests--;
            });
        }
    }

    isPostActivitySelected(postActivity: string): boolean {
        return this.postActivity === postActivity;
    }

    isPreActivitySelected(preActivity: string): boolean {
        return this.preActivity === preActivity;
    }

    isExtraNightSamePrice(nightType: string): boolean {
        // Determines if all nights for the provided night type have the same price, and if
        // we need to show a `from` label or not.
        let averagePrice = null;
        for (let choice in this.choices[nightType]) {
            let extraNightPrice = this.choices[nightType][choice].price.prices_by_types[nightType];
            if (!extraNightPrice) continue;

            // @ts-ignore
            let perNightPrice = extraNightPrice.service / choice;
            if (averagePrice == null) {
                averagePrice = perNightPrice;
            }
            if (averagePrice != perNightPrice) {
                return false;
            }
        }
        return true;
    }

    async save(): Promise<void> {
        this.saveRequests++;
        await this.bookingService.setExtensions(
            this.preNights,
            this.postNights,
            {
                date: this.preActivityOption?.date,
                id: this.preActivity,
            },
            {
                date: dateToDateString(this.getArrivalDate()),
                // date: this.postActivityOption?.date,
                id: this.postActivity,
            },
        );

        this.saveRequests--;
    }

    async selectPostActivity(option: ProductOption): Promise<void> {
        this.postActivity = option ? option.product.id : null;
        this.postActivityOption = option;
        await this.save();
    }

    async selectPreActivity(option: ProductOption): Promise<void> {
        this.postActivityOptions = null;
        this.preActivity = option ? option.product.id : null;
        this.preActivityOption = option;

        this.activitiesService.refreshOptions('post_activity');
        await this.save();
        await this.initPostActivityOptions();
    }

    async setPostNights(postNights: number): Promise<void> {
        if (postNights < this.promotionNights.post) {
            this.promotionsService.showPromotionalNightsWarning();
            return;
        }

        this.postActivityOptions = null;
        this.postNights = postNights;
        if (!this.postNights) {
            this.postActivity = null;
        }

        this.activitiesService.refreshOptions('post_activity');
        await this.save();
        await this.initPostActivityOptions();
    }

    async setPreNights(preNights: number): Promise<void> {
        if (preNights < this.promotionNights.pre) {
            this.promotionsService.showPromotionalNightsWarning();
            return;
        }

        this.preActivityOptions = null;
        this.preNights = preNights;
        if (!this.preNights) {
            this.preActivity = null;
        }

        this.activitiesService.refreshOptions('pre_activity');
        await this.save();
        await this.initPreActivityOptions();
    }

    trackByIndex(index: number): number {
        return index;
    }
}
