<div class="card rmc-card rmc-card--thumbnail">
    <div class="rmc-card__thumbnail">
        <img *ngIf="isReady" class="thumbnail rmc-card__thumbnail-image" src="/images/airport-transfer.jpg">
    </div>

    <div *ngIf="isReady">
        <div *ngIf="selectionId" class="price rmc-card__price--selected">
            {{ selectionPrice | envCurrency }} {{currency.code}}
        </div>

        <div *ngIf="!selectionId" class="rmc-card__price">
            <div class="price-label">FROM</div>
            <div class="rmc-price">
                <span class="rmc-price__value">{{minPrice | envCurrency}}</span>
                <span class="rmc-price__currency">{{currency.code}}</span>
            </div>
        </div>
    </div>

    <div class="rmc-card__info">
        <div *ngIf="!isReady" class="rmc-card__title rmc-placeholder rmc-placeholder--max-20">&nbsp;</div>
        <div *ngIf="isReady" class="rmc-card__title">
            {{title}}
        </div>

        <div *ngIf="isReady">
            <div *ngFor="let option of transferOptions; trackBy: trackByIndex" class="option">
                <div class="option-label" (click)="select(option)">
                    <div class="option-radio" [ngClass]="{
                        'option-radio--selected': isSelected(option)
                    }"></div>

                    <span *ngIf="option.product">{{option.product.external_name}}</span><span *ngIf="!option.product">No Transfer</span>:
                    {{option.price | envCurrency}} {{currency.code}}

                </div>

            </div>
        </div>
    </div>
</div>
