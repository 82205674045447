import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BookingUpdateEvent } from '../helpers/booking';
import { GuestsUpdateEvent } from '../helpers/guests-details';
import { dateStringToDate } from '../helpers/date';
import { BookingDetails, bookingDetailsRequiredSchema, Selections } from '../helpers/selections';
import { basicTravellerFields, internationalTravellerFields, maxAge, maxDay, maxMonth, Traveller, TravellerError } from '../helpers/traveller';
import { isEmpty, isObjectFilled, RequiredSchema } from '../helpers/validation';

import { BookingService } from './booking.service';
import { GeoService } from './geo.service';
import { NavigationService } from './navigation.service';

@Injectable({
    providedIn: 'root'
})
export class GuestDetailsService {

    public isFormValid = false;
    private accessibility: boolean;
    private editGuestSubject: Subject<Array<number>> = new Subject<Array<number>>();
    private guestChangeSubject: Subject<Array<GuestsUpdateEvent>> = new Subject<Array<GuestsUpdateEvent>>();
    private editGuestObservable: Observable<Array<number>> = this.editGuestSubject.asObservable();
    private insuranceModalSubject: Subject<string> = new Subject<string>();
    private insuranceModalObservable: Observable<string> = this.insuranceModalSubject.asObservable();
    private requestsRunning = 0;
    private submitted = false;

    constructor(
        private bookingService: BookingService,
        private geoService: GeoService,
        private navigationService: NavigationService,
    ) {
        this.bookingService.listenEvents([
            BookingUpdateEvent.Accessibility,
        ]).subscribe(() => {
            if (!this.requestsRunning) { // check if this change is external
                this.init();
            }
        });
        this.bookingService.listenEvents([
            BookingUpdateEvent.BookingDetails,
        ]).subscribe(() => {
            console.log('BookingUpdateEvent.BookingDetails');
            this.isFormValid = this.isDetailsFilled();
        });
        this.bookingService.listenEvents([
            BookingUpdateEvent.BookingDetailsInit,
        ]).subscribe(() => {
            console.log('BookingUpdateEvent.BookingDetailsInit');
            this.isFormValid = this.isDetailsFilled();
        });
    }

    /**
     * Opens 'Edit Guest' modal.
     * @param roomIndex Room index.
     * @param guestIndex Guest index.
     */
    editGuest(roomIndex: number, guestIndex: number): void {
        this.editGuestSubject.next([roomIndex, guestIndex]);
    }

    /**
     * Returns guest data by room index and guest index.
     * @param roomIndex Room index.
     * @param guestIndex Guest index.
     * @returns Guest object.
     */
    getGuest(roomIndex: number, guestIndex: number): Traveller {
        const rooms: Array<Array<Traveller>> = this.getGuestsByRooms();
        const traveller: Traveller = rooms[roomIndex][guestIndex];

        return traveller;
    }

    /**
     * Returns required fields schema for the guest form.
     * @returns Required fields schema.
     */
    getGuestSchema(): RequiredSchema {
        const schema: RequiredSchema = basicTravellerFields;

        if (this.bookingService.isInternational()) {
            Object.assign(schema, internationalTravellerFields);
        }

        return schema;
    }

    /**
     * Returns list of guests by rooms.
     * @returns List of guests by rooms.
     */
    getGuestsByRooms(): Array<Array<Traveller>> {
        return this.bookingService.getOccupancy();
    }

    /**
     * Returns form title for the guest specified by room index and guest index.
     * @param roomIndex Room index.
     * @param guestIndex Guest index.
     * @returns Form title for the guest specified.
     */
    getTitle(roomIndex: number, guestIndex: number): string {
        const rooms: Array<Array<Traveller>> = this.getGuestsByRooms();
        const room: Array<Traveller> = rooms[roomIndex];
        const traveller: Traveller = room[guestIndex];

        const roleIndexes: Array<number> = [];

        for (let i = 0; i < room.length; ++i) {
            if (room[i].role === traveller.role) {
                roleIndexes.push(i);
            }
        }

        let title: string = traveller.role.slice(0, 1).toUpperCase() + traveller.role.slice(1);

        if (roleIndexes.length > 1) {
            const guestRoleIndex: number = roleIndexes.findIndex((roleIndex: number): boolean => {
                return roleIndex === guestIndex;
            });

            title += ` #${guestRoleIndex + 1}`;
        }

        if (rooms.length > 1) {
            title += ` Room #${roomIndex + 1}`;
        }

        return title;
    }

    /**
     * Initializes service.
     */
    init(): void {
        this.accessibility = this.bookingService.getSelectionCommons().requires_accessibility_assistance;
    }

    /**
     * Checks if billing details form is filled.
     * @returns If billing details form is filled.
     */
    private isBillingFilled(): boolean {
        const bookingDetails: BookingDetails = this.bookingService.getBookingDetails();
        const requiredSchema: RequiredSchema = Object.assign({}, bookingDetailsRequiredSchema);
        if (bookingDetails.country) {
            Object.assign(requiredSchema, {
                province: this.geoService.isBillingProvinceRequired(bookingDetails.country),
            });
        }
        return isObjectFilled(bookingDetails, requiredSchema);
    }

    /**
     * Checks if all guest forms are filled.
     * @returns If all guest forms are filled.
     */
    isDetailsFilled(): boolean {
        const rooms: Array<Array<Traveller>> = this.getGuestsByRooms();

        for (const room of rooms) {
            for (const traveller of room) {
                if (!this.isGuestFilled(traveller)) {
                    return false;
                }
            }
        }

        return this.isBillingFilled();
    }

    /**
     * Checks if guest form is filled for the guest specified.
     * @param traveller Guest object.
     * @returns If guest form is filled.
     */
    private isGuestFilled(traveller: Traveller): boolean {
        if (!isObjectFilled(traveller, this.getGuestSchema())) {
            return false;
        }

        if (
            (this.isInsuranceAdded() && isEmpty(traveller.country)) ||
            (this.geoService.isHomeCountry(traveller.country) && isEmpty(traveller.province))
        ) {
            return false;
        }
        return true;
    }

    /**
     * Checks if insurance is added to the booking.
     * @returns If insurance is added.
     */
    isInsuranceAdded(): boolean {
        const selections: Selections = this.bookingService.getSelections();

        return Boolean(selections.insurance);
    }

    /**
     * Checks if this service has any API requests running.
     * @returns If this service has any API requests running.
     */
    isLoading(): boolean {
        return this.requestsRunning > 0;
    }

    /**
     * Checks if guest details form has been submitted.
     * @returns If guest details form has been submitted.
     */
    isSubmitted(): boolean {
        return this.submitted;
    }

    /**
     * Returns 'Edit Guest' modal open event.
     * @returns 'Edit Guest' modal open event.
     */
    onEditGuest(): Observable<Array<number>> {
        return this.editGuestObservable;
    }

    /**
     * Returns 'Insurance selection has been removed' modal open event.
     * @returns 'Insurance selection has been removed' modal open event.
     */
    onInsuranceModal(): Observable<string> {
        return this.insuranceModalObservable;
    }

    /**
     * Opens 'Insurance selection has been removed' modal.
     * @param province Province that is not eligible for Allianz insurance.
     */
    openInsuranceModal(province: string): void {
        this.insuranceModalSubject.next(province);
    }

    /**
     * Client-side guest validation.
     * @param traveller Guest object.
     * @returns Guest validation errors.
     */
    private preValidateGuest(traveller: Traveller): TravellerError {
        const travellerError: TravellerError = {};

        if (traveller.date_of_birth) {
            const today: Date = new Date();
            const [year, month, day]: Array<number> = traveller.date_of_birth.split('-').map(
                (x) => { return Number(x) || null; }
            );
            const errors: Array<string> = [];
            if (year < today.getFullYear() - maxAge || year == null) {
                errors.push('Whoops, looks like you entered an invalid year.');
            }
            if (month > maxMonth || month == null) {
                errors.push('Whoops, looks like you entered an invalid month.');
            }
            if (day > maxDay || day == null) {
                errors.push('Whoops, looks like you entered an invalid day.');
            }
            if (errors.length) {
                travellerError.date_of_birth = errors;
            }
        }

        if (traveller.passport_expiry) {
            const today: Date = new Date();
            const [year, month, day]: Array<number> = traveller.passport_expiry.split('-').map(
                (x) => { return Number(x) || null; }
            );
            const errors: Array<string> = [];

            if (year == null) {
                errors.push(`Whoops, looks like you entered an invalid year.`);
            }

            if (month > maxMonth || month == null) {
                errors.push('Whoops, looks like you entered an invalid month.');
            }

            if (day > maxDay || day == null) {
                errors.push('Whoops, looks like you entered an invalid day.');
            }

            if (!errors.length) {
                var d = new Date(traveller.passport_expiry);
                if (!isNaN(d.getTime()) && d.getTime() < today.getTime()) {
                    errors.push('Passport expiry date must be in the future');
                }
            }

            if (errors.length) {
                travellerError.passport_expiry = errors;
            }
        }

        return travellerError;
    }

    /**
     * Returns accessibility flag value.
     * @returns Accessibility flag value.
     */
    requiresAccessibilityAssistance(): boolean {
        return this.accessibility;
    }

    /**
     * Scrolls browser view to the first error field.
     */
    scrollToError(): void {
        setTimeout(() => {
            // scroll to the first error
            const errorField: HTMLElement = document.querySelector('.rmc-field-error');
            if (errorField) {
                errorField.scrollIntoView();
                const errorInput: HTMLElement = errorField.querySelector('.rmc-input, .rmc-select');
                if (errorInput) {
                    errorInput.focus();
                }
            }
        });
    }

    /**
     * Validates 'Guest Details' form and navigates user to the Review page.
     */
    async submitDetails(roomsFromForm: Array<Array<Traveller>> = null): Promise<void> {

        this.submitted = true;

        const errorField: HTMLElement = document.querySelector('.rmc-field-error');
        if (!errorField && this.isDetailsFilled()) {
            // form is valid
            this.navigationService.navigate('/review');
        } else {
            // form is invalid, scroll to the first error
            this.scrollToError();
        }

    }

    /**
     * Validates 'Edit Guest' form data and saves the changes.
     * @param roomIndex Room index.
     * @param guestIndex Guest index.
     * @param traveller Guest data.
     */
    async submitGuest(roomIndex: number, guestIndex: number, traveller: Traveller): Promise<TravellerError> {
        let errors = await this.updateGuest(roomIndex, guestIndex, traveller);
        if (!_.isEmpty(errors) || !this.isGuestFilled(traveller)) {
            return Promise.reject(errors);
        }
        return null;
    }

    /**
     * Saves accessibility flag value.
     * @param value Accessibility flag value.
     */
    async updateAccessibility(value: boolean): Promise<void> {
        this.accessibility = value;

        this.requestsRunning++;
        await this.bookingService.updateAccessibility(value).catch(() => {});
        this.requestsRunning--;
    }

    /**
     * Saves guest form data.
     * @param roomIndex Room index.
     * @param guestIndex Guest index.
     * @param traveller Guest data.
     */
    async updateGuest(roomIndex: number, guestIndex: number, traveller: Traveller): Promise<TravellerError> {
        const preError: TravellerError = this.preValidateGuest(traveller);

        if (Object.keys(preError).length) {
            return preError;
        }

        const travellerToSave: Traveller = JSON.parse(JSON.stringify(traveller));

        // clean up traveller data (null values could cause API error)
        for (const field in travellerToSave) {
            if (isEmpty(travellerToSave[field])) {
                delete travellerToSave[field];
            }
        }

        this.requestsRunning++;
        return this.bookingService.updateTraveller(roomIndex, guestIndex, travellerToSave).then((): TravellerError => {
	    this.guestChangeSubject.next([GuestsUpdateEvent.EndChangeCommit])
            this.requestsRunning--;
            return {};
        }).catch((error: TravellerError): TravellerError => {
	    this.guestChangeSubject.next([GuestsUpdateEvent.EndChangeCommit])
            this.requestsRunning--;
            return error;
        });
    }

    guestUpdateGuard(): void {
	this.guestChangeSubject.next([GuestsUpdateEvent.BeginChangeCommit])
    }

        /**
     * Listens bunch of events and trigges their handler.
     * @param targetEvents Events to listen.
     * @returns Event observable.
     */
    listenEvents(targetEvents: Array<GuestsUpdateEvent>): Observable<void> {
        const groupObserver: Subject<void> = new Subject<void>();

        // handle all the triggered events
        this.guestChangeSubject.subscribe((triggeredEvents: Array<GuestsUpdateEvent>) => {
            let matches = false;

            // check if any target event is triggered
            for (const triggeredEvent of triggeredEvents) {
                if (targetEvents.includes(triggeredEvent)) {
                    matches = true;
                    break;
                }
            }

            // if any target event is triggered -> then trigger target event handlers
            if (matches) {
                groupObserver.next();
            }
        });

        return groupObserver.asObservable();
    }

}
