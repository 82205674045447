<div class="rmc-card rmc-card--thumbnail" *ngIf="!isPricesReady || hasChoices()">
    <div class="rmc-card__thumbnail rmc-placeholder">
        <img *ngIf="isReady" class="rmc-card__thumbnail-image" src="/images/add-insurance.jpg">
    </div>

    <div class="rmc-card__price" *ngIf="isReady && isPricesReady && !hasSelection()">
        <div class="price-label">FROM</div>
        <div class="rmc-price">
            <span class="rmc-price__value">{{ getMinimumRate() | envCurrency: true }}</span>
            <span class="rmc-price__currency">{{currency.code}}</span>
        </div>
    </div>
    
    <div class="rmc-card__price--selected" *ngIf="isReady && isPricesReady && hasSelection()">
        {{ getTotalRate() | envCurrency }} {{currency.code}}
    </div>
    
    <div class="rmc-card__info">
            <div *ngIf="!isReady" class="rmc-card__title rmc-placeholder rmc-placeholder--max-20">&nbsp;</div>
        <div *ngIf="isReady" class="rmc-card__title">
            <rmc-phrase label="HEADLINE_CONFIGURE_INSURANCE"></rmc-phrase>
        </div>
    
        <div *ngIf="!isReady" class="rmc-card__description rmc-placeholder">&nbsp;</div>
        <div *ngIf="isReady" class="rmc-card__description">
            <rmc-phrase label="BODY_CONFIGURE_INSURANCE"></rmc-phrase>
        </div>
    
        <div *ngIf="!isReady" class="rmc-placeholder rmc-placeholder--button">&nbsp;</div>
        <div *ngIf="isReady" class="rmc-card__call-to-action">
            <button class="configure-button rmc-button rmc-button--neutral slds-m-bottom--large" type="button" (click)="openInsurance()">
                <rmc-phrase *ngIf="!hasSelection()" label="ACTION_CONFIGURE_INSURANCE"></rmc-phrase>
                <rmc-phrase *ngIf="hasSelection()" label="ACTION_EDIT_CONFIGURE_INSURANCE"></rmc-phrase>
            </button>
            <img class="provider-logo slds-m-bottom--large" src="/images/allianz-logo.png">
        </div>
    </div>
</div>
