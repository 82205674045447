import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Booking } from '../../helpers/booking';
import { Package } from '../../helpers/package';
import { Currency, Prices } from '../../helpers/prices';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';

@Component({
    selector: 'rmc-confirmation-page',
    templateUrl: './confirmation-page.component.html',
    styleUrls: ['./confirmation-page.component.scss']
})
export class ConfirmationPageComponent implements OnInit {

    bookingsUrl: string = `${environment.communitiesUrl}${environment.savedTripsPath}my-bookings`;
    currency: Currency;
    insuranceConditions = environment.insuranceConditions;
    isReady = false;
    package: Package;
    arrivalDate: Date;
    departureDate: Date;
    dateFormat: string = 'EEEE MMMM d, y';
    dueDateFormat: string = environment.dateFormat;
    serviceCode: string;

    public booking: Booking = null;
    public serviceLevelLabel: string = null;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        await this.bookingService.initPriceOptions();
        await this.getServiceLevelLabel();
        this.init();
        this.isReady = true;
        this.booking = this.bookingService.getBooking();
        this.currency = this.bookingService.getCurrency();
        this.serviceCode = this.bookingService.getServiceCode();
    }

    init() {
        this.package = this.bookingService.getPackage();

        this.departureDate = this.bookingService.getDepartureDate(true);
        this.arrivalDate = this.bookingService.getArrivalDate(true);
    }

    getGuests() {
        let guests = [];
        for (let unit of this.booking.selections.commons.occupancies) {
            for (let passenger of unit) {
                let name = passenger.first_name;
                if (passenger.middle_name) {
                    name += ' ' + passenger.middle_name;
                }
                name += ' ' + passenger.last_name;
                guests.push(name);
            }
        }
        return guests.join(', ');
    }

    getPrices(): Prices {
        return this.bookingService.getPrices();
    }

    getAddOnNetRate(addOnName): number {
        let price = this.getPrices().raw.prices_by_types[addOnName];
        if (!price) {
            return null;
        }
        return price.service + price.fee + price.promotion;
    }

    getFinalDate(): Date {
        return this.getPrices().getFinalDate();
    }

    getPaidAmount(): number {
        return this.bookingService.getPaidAmount();
    }

    async getServiceLevelLabel(): Promise<void> {
        this.serviceLevelLabel = await this.bookingService.getServiceLevelName();
    }
}
