import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { PicklistOption } from '../../helpers/picklist';

@Component({
    selector: 'rmc-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent implements OnChanges {

    @Input()
    disabled = false;

    @Input()
    options: Array<PicklistOption>;

    @Input()
    type = 'single';

    @Input()
    value: string;
    @Output()
    valueChange: EventEmitter<string> = new EventEmitter<string>();

    constructor() { }

    ngOnChanges() {
    }

    isSelected(option: PicklistOption): boolean {
        return option.value === this.value;
    }

    select(option: PicklistOption): void {
        this.value = option.value;
        this.valueChange.emit(this.value);
    }

    trackOption(index: number, option: PicklistOption): string {
        return option.value;
    }
}
