import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { CaptchaResult } from '../../helpers/captcha';
import { EmailError, EmailForm, emailRequiredSchema } from '../../helpers/email';
import { BookingDetails } from '../../helpers/selections';
import { RequiredSchema } from '../../helpers/validation';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { EmailService } from '../../services/email.service';

declare var grecaptcha: any;

@Component({
    selector: 'rmc-email-trip-modal',
    templateUrl: './email-trip-modal.component.html',
    styleUrls: ['./email-trip-modal.component.scss']
})
export class EmailTripModalComponent implements OnInit {

    bookingDetails: BookingDetails;
    emailData: EmailForm;
    emailRequiredSchema: RequiredSchema = emailRequiredSchema;
    errors: EmailError;
    isOpen: boolean = false;
    isRecaptchaOpen: boolean;
    isSaving: boolean;
    saved: boolean;
    submitted: boolean;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private elementRef: ElementRef,
        private emailService: EmailService,
        private renderer: Renderer2,
    ) { }

    ngOnInit() {
        this.emailService.onTripModal().subscribe(() => {
            this.appService.incrementModal();
            this.isOpen = true;

            // init fields
            this.bookingDetails = this.bookingService.getBookingDetails();
            this.emailData = {
                sender: this.bookingDetails.first_name,
                sender_email: this.bookingDetails.email,
            };
            this.errors = {};
            this.isRecaptchaOpen = false;
            this.isSaving = false;
            this.saved = false;
            this.submitted = false;
        });
    }

    closeModal(): void {
        this.isOpen = false;
        this.appService.decrementModal();
    }

    onChange(field: string): void {
        delete this.errors[field];
    }

    private async sendEmail(captcha: CaptchaResult): Promise<void> {
        this.isSaving = true;

        await this.emailService.sendEmail(this.emailData, captcha).then(() => {
            this.saved = true;
        }).catch((response: HttpErrorResponse) => {
            this.errors = response.error;
        });

        this.isSaving = false;
    }

    submit(): void {
        this.submitted = true;

        if (this.isSaving || !this.emailService.isFormFilled(this.emailData)) {
            return;
        }

        this.isSaving = true;
        grecaptcha.ready(async () => {
            const token: string = await grecaptcha.execute(environment.recaptchaKey_v3, {action: 'email'});
            this.isSaving = false;

            this.submitCaptcha({
                provider: 'recaptcha_v3',
                solution: token,
            });
        });
    }

    private async submitCaptcha(captcha: CaptchaResult): Promise<void> {
        await this.sendEmail(captcha);

        if (this.saved) {
            this.closeModal();
        } else if (this.errors.captcha) {
            this.isRecaptchaOpen = true;
            this.isSaving = true;

            setTimeout(() => {
                const component: HTMLElement = this.elementRef.nativeElement;
                const recaptchaElement: HTMLElement = component.querySelector('.recaptcha');

                grecaptcha.render(recaptchaElement, {
                    callback: (token: string) => {
                        this.isRecaptchaOpen = false;
                        this.isSaving = false;

                        this.submitCaptcha({
                            provider: 'recaptcha_v2',
                            solution: token,
                        });
                    },
                    sitekey: environment.recaptchaKey_v2,
                });
            });
        }
    }
}
