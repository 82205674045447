import { Component, OnInit } from '@angular/core';

import { BookingUpdateEvent } from '../../helpers/booking';
import { DinnerOption, PrePost, Transfer, TransferDetails } from '../../helpers/extensions';
import { PicklistOption } from '../../helpers/picklist';
import { Selections } from '../../helpers/selections';
import { removeEmptyFields } from '../../helpers/validation';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { ConfigurationService } from '../../services/configuration.service';
import { PromotionsService } from '../../services/promotions.service';
import { TransferService } from '../../services/transfer.service';

@Component({
    selector: 'rmc-sidebar-promotions',
    templateUrl: './sidebar-promotions.component.html',
    styleUrls: ['./sidebar-promotions.component.scss']
})
export class SidebarPromotionsComponent implements OnInit {

    arrivalDate: string;
    arrivalTime: string;
    dinnerLocationId: string = null;
    dinnerOptions: Array<DinnerOption>;
    dinnerPicklistOptions: Array<PicklistOption>;
    hideDinner = false;
    hideTransfer = false;
    isDinnerAvailable = false;
    isTransfersAvailable = false;
    isReady = false;
    isSaving = false;
    label: string;
    nightsAvailable = 0;
    postNights = 0;
    postNightsAvailable = 0;
    preNights = 0;
    preNightsAvailable = 0;
    transfer: string;
    transferDetails: TransferDetails = {};
    private transferOptions: PrePost<Transfer>;
    transferPicklistOptions: Array<PicklistOption>;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private configurationService: ConfigurationService,
        private promotionsService: PromotionsService,
        private transferService: TransferService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        await this.promotionsService.init();

        this.isReady = true;
        this.label = this.promotionsService.getPromotionLabel();

        this.init();
        this.bookingService.listenEvents([
            BookingUpdateEvent.ExtraPostNights,
            BookingUpdateEvent.ExtraPreNights,
            BookingUpdateEvent.PostTransfer,
            BookingUpdateEvent.PreTransfer,
            BookingUpdateEvent.Promotions,
        ]).subscribe(() => {
            if (!this.isSaving) { // check if this change is external
                this.init();
            }
        });
    }

    close(): void {
        this.configurationService.close();
    }

    async confirm(): Promise<void> {
        if (this.isSaving) {
            return;
        }

        let transfer: Transfer;

        if (this.transfer) {
            transfer = this.transferOptions[this.transfer];

            transfer.flight_number = this.transferDetails.flightNumber;
            transfer.is_flight_booked = !this.transferDetails.notBooked;

            if (this.transferDetails.date && this.transferDetails.time) {
                transfer.date = `${this.transferDetails.date}T${this.transferDetails.time}`;
            }

            removeEmptyFields(transfer);
        } else {
            transfer = null;
        }

        this.isSaving = true;

        await this.promotionsService.setPromotion(
            {
                post: this.postNights,
                pre: this.preNights,
            },
            this.dinnerLocationId,
            transfer,
        ).catch(() => {});

        this.bookingService.triggerEvents([
            BookingUpdateEvent.ExtraPostNights,
            BookingUpdateEvent.ExtraPreNights,
            BookingUpdateEvent.PostTransfer,
            BookingUpdateEvent.PreTransfer,
            BookingUpdateEvent.Promotions,
        ]);

        this.isSaving = false;
        this.close();
    }

    decreasePostNights(): void {
        if (this.postNights > 0) {
            this.postNights--;
        }
    }

    decreasePreNights(): void {
        if (this.preNights > 0) {
            this.preNights--;
        }
    }

    increasePostNights(): void {
        if (this.postNights < this.postNightsAvailable) {
            this.postNights++;

            if (this.preNights + this.postNights > this.nightsAvailable) {
                this.preNights--;
            }
        }
    }

    increasePreNights(): void {
        if (this.preNights < this.preNightsAvailable) {
            this.preNights++;

            if (this.preNights + this.postNights > this.nightsAvailable) {
                this.postNights--;
            }
        }
    }

    private init(): void {
        if (this.promotionsService.isPromotionsAvailable()) {
            this.initPromotions();
        } else {
            this.close();
        }
    }

    private async initDinnerOptions(): Promise<void> {
        this.dinnerOptions = await this.promotionsService.getDinnerOptions();

        this.dinnerPicklistOptions = this.dinnerOptions.map((dinnerOption: DinnerOption): PicklistOption => {
            return {
                label: dinnerOption.name,
                value: dinnerOption.id,
            };
        });

        this.dinnerLocationId = this.promotionsService.getDinnerSelectionId();
        if (!this.dinnerLocationId) {
            this.hideDinner = true;
        }
    }

    private initExtraNightsOptions(): void {
        const promotionNights: PrePost<number> = this.promotionsService.getExtraNightsSelection();

        this.preNights = promotionNights.pre;
        this.postNights = promotionNights.post;
    }

    private initPromotions(): void {
        if (this.promotionsService.hasDinnerOptions()) {
            this.isDinnerAvailable = true;
            this.initDinnerOptions();
        }

        this.nightsAvailable = this.promotionsService.getExtraNightsTotalLimit();
        this.preNightsAvailable = this.promotionsService.getExtraNightsLimit('pre');
        this.postNightsAvailable = this.promotionsService.getExtraNightsLimit('post');

        if (this.nightsAvailable) {
            this.initExtraNightsOptions();
        }

        if (this.promotionsService.hasTransferOptions()) {
            this.isTransfersAvailable = true;
            this.initTransferOptions();
        }
    }

    private initTransferOptions(): void {
        this.transferOptions = this.promotionsService.getTransferOptions();
        this.transferPicklistOptions = [];

        if (this.transferOptions.pre) {
            this.transferPicklistOptions.push({
                label: 'At the start of my trip',
                value: 'pre',
            });
        }

        if (this.transferOptions.post) {
            this.transferPicklistOptions.push({
                label: 'At the end of my trip',
                value: 'post',
            });
        }

        this.transfer = this.promotionsService.getTransferSelection();
        if (this.transfer) {
            const selections: Selections = this.bookingService.getSelections();
            const key = `${this.transfer}_transfer`;
            const transfer: Transfer = selections[key];

            this.transferDetails = this.transferService.getDetailsFromSelection(transfer);
        } else {
            this.hideTransfer = true;
        }
    }

    onDinnerChange(): void {
        this.hideDinner = false;
    }

    onHideDinner(): void {
        if (this.hideDinner) {
            this.dinnerLocationId = null;
        }
    }

    onHideTransfer(): void {
        if (this.hideTransfer) {
            this.transfer = null;
        }
    }

    onTransferChange(): void {
        this.hideTransfer = false;
    }
}
