<rmc-breadcrumbs></rmc-breadcrumbs>
<rmc-tour-header></rmc-tour-header>

<div class="rmc-container narrow">
    <div class="rmc-tour-heading rmc-heading rmc-heading--m slds-m-bottom--medium">
        Guest Details
    </div>

    <div class="rmc-grid">
        <div class="rmc-col slds-size--1-of-1 slds-large-size--7-of-12">
            <rmc-guests-details></rmc-guests-details>
        </div>

        <div class="rmc-col slds-size--1-of-1 slds-large-size--5-of-12">
            <rmc-price-breakdown-card></rmc-price-breakdown-card>
            <rmc-cancel-policy></rmc-cancel-policy>
            <rmc-contact-us></rmc-contact-us>
        </div>
    </div>
</div>
