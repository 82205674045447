<div class="app" [ngClass]="{
    'app--checkout': hasCheckout
}">
    <div class="main">
        <rmc-app-header></rmc-app-header>
        <router-outlet></router-outlet>
    </div>

    <div class="footer">
        <rmc-app-footer></rmc-app-footer>
    </div>
</div>

<rmc-edit-guest-modal></rmc-edit-guest-modal>
<rmc-edit-phrase-modal></rmc-edit-phrase-modal>
<rmc-email-trip-modal></rmc-email-trip-modal>
<rmc-insurance-modal></rmc-insurance-modal>
<rmc-itinerary-modal></rmc-itinerary-modal>
<rmc-managed-modal></rmc-managed-modal>
<rmc-no-seats-modal></rmc-no-seats-modal>

<rmc-message-modal
    *ngIf="tripSavedModal"
    button="Close"
    (close)="closeSavedModal()"
>
    Your trip has been saved. To return your trip configuration at a later date, visit <a [href]="savedTripsUrl">My Saved Trip</a>
</rmc-message-modal>

<rmc-message-modal
    *ngIf="promotionalNightsWarning"
    button="Okay"
    heading="Extra nights cannot be removed"
    (close)="closePromotionalNightsWarning()"
>
    You have added extra nights as a part of a promotion, please remove the promotion instead.
</rmc-message-modal>
