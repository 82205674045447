import { Component, OnInit } from '@angular/core';

import { formatEnumeration } from '../../helpers/occupancy';
import { Currency, Prices } from '../../helpers/prices';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { ConfigurationService } from '../../services/configuration.service';
import { GuestDetailsService } from '../../services/guest-details.service';
import { NavigationService } from '../../services/navigation.service';
import { PhrasesService } from '../../services/phrases.service';
import { PromotionsService } from '../../services/promotions.service';
import { ReviewService } from '../../services/review.service';

@Component({
    selector: 'rmc-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

    currency: Currency;
    isOptionsOpened = false;
    isPromotionsModal = false;
    isPricesLoading = false;
    isPricesReady = false;
    isReady = false;
    path: string;
    aeroplanStatus: string;

    constructor(
        private appService: AppService,
        public bookingService: BookingService,
        private configurationService: ConfigurationService,
        private guestDetailsService: GuestDetailsService,
        private navigationService: NavigationService,
        private phrasesService: PhrasesService,
        private promotionsService: PromotionsService,
        private reviewService: ReviewService,
    ) { }

    async ngOnInit() {
        this.path = this.navigationService.getTopLevelRoute();

        await this.appService.onInit();
        this.isReady = true;
        this.currency = this.bookingService.getCurrency();
        this.aeroplanStatus = this.bookingService.getAeroplanStatus()

        await Promise.all([
            this.bookingService.initPriceOptions(),
            this.promotionsService.init(),
        ]);
        this.isPricesReady = true;
    }

    closeOptions(): void {
        this.isOptionsOpened = false;
    }

    closePromotionsModal(openPromotions: boolean): void {
        this.isPromotionsModal = false;

        if (openPromotions) {
            this.configurationService.openPromotions();
        } else {
            this.navigationService.navigate('/guest-details');
        }
    }

    getPhrase(label: string): string {
        return this.phrasesService.getPhrase(label);
    }

    getPrices(): Prices {
        return this.bookingService.getPrices();
    }

    getPromotionsModalHeading(): string {
        const options: Array<string> = [];
        const nightsAvailable: number = this.promotionsService.getExtraNightsTotalLimit();

        if (nightsAvailable) {
            options.push(nightsAvailable === 1 ? 'night' : 'nights');
        }

        if (this.promotionsService.hasTransferOptions()) {
            options.push('transfer');
        }

        if (this.promotionsService.hasDinnerOptions()) {
            options.push('dinner');
        }

        return `You have not added your free ${formatEnumeration(options, true)}.`;
    }

    isCheckingAvailability(): boolean {
        return this.reviewService.isCheckingAvailability();
    }

    isLoading(): boolean {
        return this.isPricesLoading || this.guestDetailsService.isLoading() || this.reviewService.isLoading();
    }

    requiresAccessibilityAssistance(): boolean {
        return this.guestDetailsService.requiresAccessibilityAssistance();
    }

    submitDetails(): void {
        if (!this.isLoading() && !this.bookingService.getSelections().commons.requires_accessibility_assistance) {
            this.guestDetailsService.submitDetails();
        }
    }

    submitExtensions(): void {
        if (this.isLoading()) {
            return;
        }

        if (this.promotionsService.isPromotionsAvailable() && !this.promotionsService.hasSelection()) {
            this.isPromotionsModal = true;
        } else {
            this.navigationService.navigate('/guest-details');
        }
    }

    submitReview(): void {
        if (!this.isLoading()) {
            this.reviewService.submitReview();
        }
    }

    reserveBooking(): void {
        if (!this.isLoading()) {
            this.reviewService.reserveBooking();
        }
    }

    toggleOptions(): void {
        this.isOptionsOpened = !this.isOptionsOpened;
    }
}
