import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { BookingUpdateEvent } from '../../helpers/booking';
import { Currency } from '../../helpers/prices';

import { ConfigurationService } from '../../services/configuration.service';
import { AppService } from "../../services/app.service";
import { BookingService } from '../../services/booking.service';

@Component({
    selector: 'rmc-sidebar-insurance',
    templateUrl: './sidebar-insurance.component.html',
    styleUrls: ['./sidebar-insurance.component.scss']
})
export class SidebarInsuranceComponent implements OnInit {

    currency: Currency;
    insuranceConditions = environment.insuranceConditions;
    isReady = false;
    isSaving = false;
    plan: string;
    priceByInsuranceType: any;
    showRates = false;

    items: Array<Array<any>> = [
        ['Pre-Existing Conditions Coverage up to $25,000 CAD per person or up to $50,000 CAD per booking (view full policy for more details)', true, true],
        ['Coverage for any reason up to 75% of nonrefundable paid travel arrangements (view full policy for more details)', false, true],
        ['Trip Cancellation 100% up to $100,000 CAD', true, true],
        ['Trip Interruption 100% up to $100,000 CAD', true, true],
        ['Baggage & Personal Effects up to $1,000 CAD', true, true],
        ['Delayed Baggage up to $200 CAD', true, true],
        ['Emergency Hospital Care up to $2,000,000 CAD if covered under a Canadian Government healthcare', true, true],
        ['Up to $500,000 CAD if not covered under a Canadian government healthcare', true, true],
        ['Emergency Dental Up to $1,000 CAD', true, true],
        ['Accidental Death and Dismemberment up to $10,000 CAD', true, true],
        ['Global Assistance Help Line available 24/7', true, true],
        ['Purchase your insurance today and decide later. Receive a full refund on your policy if cancelled within 10 days of purchase.', true, true],
    ];

    constructor(
        private configurationService: ConfigurationService,
        private bookingService: BookingService,
        private appService: AppService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.currency = this.bookingService.getCurrency();
        this.isReady = true;
        
        await this.bookingService.initPriceOptions();
        this.showRates = true;

        this.init();
        this.bookingService.listenEvents([
            BookingUpdateEvent.Insurance,
        ]).subscribe(() => {
            if (!this.isSaving) { // check if this change is external
                this.init();
            }
        });
    }

    close(): void {
        this.configurationService.close();
    }

    getInsuranceRate() {
        if (!this.plan) {
            return 0;
        }

        let price = this.priceByInsuranceType[this.plan]['insurance'];

        if (price) {
            return price.service + price.fee + price.promotion;
        } else {
            return 0;
        }
    }

    hasSelectionChanged() {
        return this.plan !== this.bookingService.getInsurancePlan();
    }

    private init(): void {
        this.plan = this.bookingService.getInsurancePlan();

        let choices = this.bookingService.getChoices().insurance;
        this.priceByInsuranceType = {};
        for (let choice of choices) {
            let insuranceType = undefined;
            if (choice.selections.insurance) {
                insuranceType = choice.selections.insurance.insurance_type;
            }
            this.priceByInsuranceType[insuranceType] = choice.price.prices_by_types;
        }
    }

    setInsurancePlan(newPlan: string) {
        this.plan = newPlan;
    }

    trackByIndex(index: number): number {
        return index;
    }

    async updateSelection(): Promise<void> {
        if (this.isSaving) {
            return;
        }

        this.isSaving = true;
        await this.bookingService.setInsurancePlan(this.plan).catch(() => {});
        this.isSaving = false;

        this.configurationService.close();
    }
}
