<div class="rmc-backdrop"></div>

<div class="rmc-modal">
    <div class="rmc-modal__container">
        <div class="rmc-modal__content">
            <img *ngIf="closeButton" class="rmc-modal__close" src="/images/times.svg" (click)="closeModal()">

            <ng-content></ng-content>
        </div>
    </div>
</div>
