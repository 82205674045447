import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Transfer, TransferDetails } from '../../helpers/extensions';
import { Currency } from '../../helpers/prices';
import { ProductOption } from '../../helpers/products';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { TransferService } from '../../services/transfer.service';

@Component({
    selector: 'rmc-transfer',
    templateUrl: './transfer.component.html',
    styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {

    @Input()
    selection: Transfer;
    @Output()
    selectionChange: EventEmitter<Transfer> = new EventEmitter<Transfer>();

    @Input()
    title: string;

    @Input()
    transferOptions: Array<ProductOption>;

    arrivalDate: string;
    arrivalTime: string;
    currency: Currency;
    isReady = false;
    minPrice: number;
    notBooked = false;
    selectionId: string;
    selectionPrice: number;
    transferDetails: TransferDetails;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private transferService: TransferService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;

        this.currency = this.bookingService.getCurrency();
        this.selectionId = this.selection.id;

        if (this.selectionId) {
            this.transferDetails = this.transferService.getDetailsFromSelection(this.selection);
        } else {
            this.transferDetails = {};
        }

        for (const option of this.transferOptions) {
            if (option.product) {
                if (this.minPrice) {
                    this.minPrice = Math.min(this.minPrice, option.price);
                } else {
                    this.minPrice = option.price;
                }
            }

            if (this.isSelected(option)) {
                this.selectionPrice = option.price;
            }
        }
    }

    isSelected(option: ProductOption): boolean {
        if (option.product) {
            return this.selectionId === option.product.id;
        } else {
            return !this.selectionId;
        }
    }

    onDetailsChange(): void {
        this.selection.flight_number = this.transferDetails.flightNumber;
        this.selection.is_flight_booked = !this.transferDetails.notBooked;

        if (this.transferDetails.date && this.transferDetails.time) {
            this.selection.date = `${this.transferDetails.date}T${this.transferDetails.time}`;
        }

        this.selectionChange.emit(this.selection);
    }

    select(option: ProductOption): void {
        this.selectionId = option.product && option.product.id;
        this.selectionPrice = option.price;

        this.selection.id = this.selectionId;
        this.selectionChange.emit(this.selection);
    }

    trackByIndex(index: number): number {
        return index;
    }
}
