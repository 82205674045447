import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { BookingUpdateEvent } from '../../helpers/booking';
import { PrePost } from '../../helpers/extensions';
import { Package } from 'src/app/helpers/package';
import { Currency, Prices } from '../../helpers/prices';
import { ProductOption } from 'src/app/helpers/products';
import { Selections } from '../../helpers/selections';

import { ActivitiesService } from '../../services/activities.service';
import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { GuestDetailsService } from '../../services/guest-details.service';
import { PromotionsService } from '../../services/promotions.service';
import { ReviewService } from '../../services/review.service';
import { TransferService } from '../../services/transfer.service';

@Component({
    selector: 'rmc-price-breakdown',
    templateUrl: './price-breakdown.component.html',
    styleUrls: ['./price-breakdown.component.scss']
})
export class PriceBreakdownComponent implements OnInit {

    @Input()
    dropdown: boolean;

    private activitiesPromise: Promise<Array<Array<ProductOption>>>;
    arrivalLocation: string;
    currency: Currency;
    dateFormat: string = environment.dateFormat;
    departureLocation: string;
    freeTransferType: string;
    hasFreeDinner: boolean;
    isActivitiesEnabled: boolean = environment.activities;
    isActivitiesReady = false;
    isPricesLoading = false;
    isPricesReady = false;
    isPromotionsSelected = false;
    isReady = false;
    isTransfersReady = false;
    private package: Package;
    paidPostNights: number;
    paidPreNights: number;
    postActivityOptions: Array<ProductOption>;
    postTransferTitle: string;
    preActivityOptions: Array<ProductOption>;
    preTransferTitle: string;
    promotionName: string;
    promotionNights: PrePost<number>;
    private transfersPromise: Promise<void>;

    constructor(
        private activitiesService: ActivitiesService,
        private appService: AppService,
        public bookingService: BookingService,
        private guestDetailsService: GuestDetailsService,
        private promotionsService: PromotionsService,
        private reviewService: ReviewService,
        private transferService: TransferService,
    ) { }

    async ngOnInit() {
        await this.appService.onInit();
        this.isReady = true;
        this.currency = this.bookingService.getCurrency();
        this.package = this.bookingService.getPackage();

        this.initAddons();
        this.bookingService.listenEvents([
            BookingUpdateEvent.ExtraPostNights,
            BookingUpdateEvent.ExtraPreNights,
            BookingUpdateEvent.Insurance,
            BookingUpdateEvent.Occupancy,
            BookingUpdateEvent.PostActivity,
            BookingUpdateEvent.PostTransfer,
            BookingUpdateEvent.PreActivity,
            BookingUpdateEvent.PreTransfer,
            BookingUpdateEvent.Promotions,
            BookingUpdateEvent.TravellerDetails,
        ]).subscribe(() => {
            this.initAddons();
        });

        await Promise.all([
            this.bookingService.initPriceOptions(),
            this.promotionsService.init(),
            this.initActivities(),
            this.initTransfers(),
        ]);
        this.isPricesReady = true;
    }

    findActivityOption(options: Array<ProductOption>, productId: string): ProductOption {
        return options.find((option: ProductOption): boolean => {
            return option.product.id === productId;
        });
    }

    removePromotionCode() {
        this.bookingService.promotionCode = null;
        this.applyPromotionCodeChange();
    }

    applyPromotionCodeChange() {
        this.bookingService.promotionCodeChanged();
    }

    getActivityTitle(type: string): string {
        if (!this.isActivitiesReady) {
            return null;
        }

        const id: string = this.activitiesService.getSelectionId(type);
        const options: Array<ProductOption> = (type === 'pre_activity') ? this.preActivityOptions : this.postActivityOptions;
        const productOption: ProductOption = this.findActivityOption(options, id);

        return productOption.product.item.name;
    }

    getPostNightsLabel(promotional: boolean): string {
        return `Extra Nights in ${this.package.arrivalLocation[0]} (${promotional ? this.promotionNights.post : this.paidPostNights})`;
    }

    getPreNightsLabel(promotional: boolean): string {
        return `Extra Nights in ${this.package.departureLocation[0]} (${promotional ? this.promotionNights.pre : this.paidPreNights})`;
    }

    getPrices(): Prices {
        return this.bookingService.getPrices();
    }

    getSelectionPrice(type: string) {
        let price = this.getPrices()?.raw.prices_by_types[type];
        if (!price) return 0;

        return price.service + price.fee + price.promotion;
    }

    private async initActivities(): Promise<void> {
        const selections: Selections = this.bookingService.getSelections();

        this.activitiesPromise = Promise.all([
            selections.pre_activity ? this.activitiesService.getOptions('pre_activity') : null,
            selections.post_activity ? this.activitiesService.getOptions('post_activity') : null,
        ]);

        const results: Array<Array<ProductOption>> = await this.activitiesPromise;
        [this.preActivityOptions, this.postActivityOptions] = results;
        this.isActivitiesReady = true;
    }

    private async initAddons(): Promise<void> {
        this.isPromotionsSelected = this.promotionsService.hasSelection();
        this.promotionName = this.promotionsService.getPromotionLabel();
        this.promotionNights = this.promotionsService.getExtraNightsSelection();

        const selections: Selections = this.bookingService.getSelections();
        this.paidPreNights = selections.extra_pre_night ? (selections.extra_pre_night.count - this.promotionNights.pre) : 0;
        this.paidPostNights = selections.extra_post_night ? (selections.extra_post_night.count - this.promotionNights.post) : 0;

        this.hasFreeDinner = Boolean(this.promotionsService.getDinnerSelectionId());
        this.freeTransferType = this.promotionsService.getTransferSelection();
    }

    private async initTransfers(): Promise<void> {
        this.transfersPromise = this.transferService.initAccommodations();
        await this.transfersPromise;

        this.preTransferTitle = this.transferService.getPreTransferTitle();
        this.postTransferTitle = this.transferService.getPostTransferTitle();
        this.isTransfersReady = true;
    }

    isAddonsAdded(): boolean {
        return (
            this.paidPreNights ||
            this.paidPostNights ||
            this.getSelectionPrice('pre_transfer') ||
            this.getSelectionPrice('post_transfer') ||
            this.getSelectionPrice('insurance')
        );
    }

    isLoading(): boolean {
        return this.isPricesLoading || this.guestDetailsService.isLoading() || this.reviewService.isLoading();
    }

    async removeFreeDinner(): Promise<void> {
        this.isPricesLoading = true;

        await this.promotionsService.removeDinner();

        this.bookingService.triggerEvents([
            BookingUpdateEvent.Promotions,
        ]);

        this.isPricesLoading = false;
    }

    async removeFreePostNights(): Promise<void> {
        this.isPricesLoading = true;

        await this.promotionsService.removeNights('post');

        this.bookingService.triggerEvents([
            BookingUpdateEvent.ExtraPostNights,
            BookingUpdateEvent.Promotions,
        ]);

        this.isPricesLoading = false;
    }

    async removeFreePreNights(): Promise<void> {
        this.isPricesLoading = true;

        await this.promotionsService.removeNights('pre');

        this.bookingService.triggerEvents([
            BookingUpdateEvent.ExtraPreNights,
            BookingUpdateEvent.Promotions,
        ]);

        this.isPricesLoading = false;
    }

    async removeFreeTransfer(): Promise<void> {
        this.isPricesLoading = true;

        await this.promotionsService.removeTransfer();

        this.bookingService.triggerEvents([
            BookingUpdateEvent.PostTransfer,
            BookingUpdateEvent.PreTransfer,
            BookingUpdateEvent.Promotions,
        ]);

        this.isPricesLoading = false;
    }

    async removeInsurance(): Promise<void> {
        this.isPricesLoading = true;
        await this.bookingService.setInsurancePlan(null);
        this.isPricesLoading = false;
    }

    async removePaidPostNights(): Promise<void> {
        this.isPricesLoading = true;
        await this.bookingService.setExtensions(this.bookingService.getPreNights(), this.promotionNights.post);
        this.isPricesLoading = false;
    }

    async removePaidPreNights(): Promise<void> {
        this.isPricesLoading = true;
        await this.bookingService.setExtensions(this.promotionNights.pre, this.bookingService.getPostNights());
        this.isPricesLoading = false;
    }

    async removePostTransfer(): Promise<void> {
        this.isPricesLoading = true;
        await this.bookingService.removePostTransfer();
        this.isPricesLoading = false;
    }

    async removePostActivity(): Promise<void> {
        this.isPricesLoading = true;

        await this.bookingService.setExtensions(
            this.bookingService.getPreNights(),
            this.bookingService.getPostNights(),
            null,
            {
                id: null,
            },
        );

        this.isPricesLoading = false;
    }

    async removePreActivity(): Promise<void> {
        this.isPricesLoading = true;

        await this.bookingService.setExtensions(
            this.bookingService.getPreNights(),
            this.bookingService.getPostNights(),
            {
                id: null,
            },
            null,
        );

        this.isPricesLoading = false;
    }

    async removePreTransfer(): Promise<void> {
        this.isPricesLoading = true;
        await this.bookingService.removePreTransfer();
        this.isPricesLoading = false;
    }
}
