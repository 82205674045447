import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { environment } from '../../environments/environment';

@Directive({
    selector: '[rmcSldsIcon]'
})
export class SldsIconDirective implements OnInit {

    @Input()
    rmcSldsIcon: string;

    @Input()
    sprite = 'utility';

    constructor(
        private elementRef: ElementRef
    ) { }

    ngOnInit() {
        const element: HTMLElement = this.elementRef.nativeElement;
        const href = `${environment.sldsConfig.svgPath}${this.sprite}-sprite/svg/symbols.svg#${this.rmcSldsIcon}`;

        element.innerHTML = `
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${href}"></use>
        `;
    }
}
