import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';

import { BookingService } from './booking.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    backUrl: string;
    businessUnit: string;

    constructor(
        private bookingService: BookingService,
        private location: Location,
        private router: Router,
    ) {}

    /**
     * Returns first path part (host/this-part/...).
     */
    getTopLevelRoute(): string {
        const matches: RegExpMatchArray = this.location.path().match(/^\/?([^\/\?]*)/);
        return (matches && matches.length) ? matches[1] : '';
    }

    /**
     * Checks if the page has checkout button (it requires additional padding on mobile).
     * @returns If the page has checkout button.
     */
    isCheckoutRoute(): boolean {
        const path: string = this.location.path();
        const hasCheckout: boolean = (
            path.indexOf('/configure') === 0 ||
            path.indexOf('/guest-details') === 0 ||
            path.indexOf('/review') === 0
        );

        return hasCheckout;
    }

    /**
     * Checks if the route provided matches the active route.
     * @param route Route to check.
     */
    isRoute(route: string): boolean {
        const path: string = this.location.path();
        const result: boolean = path.indexOf(route) === 0;

        return result;
    }

    /**
     * Navigates user to the path provided.
     * @param path URL path string.
     */
    navigate(path: string): void {
        const extras: NavigationExtras = {
            queryParams: {
                booking: this.bookingService.getBookingId(),
                backUrl: this.backUrl,
                business_unit: this.businessUnit
            }
        };

        this.router.navigate([path], extras);
    }

}
