<div
    class="picklist"
    [ngClass]="{
        'picklist--open': isOpen,
        'picklist--search': isSearch()
    }"
    (clickOutside)="closePicklist()"
    exclude=".picklist"
>
    <button
        class="selection rmc-input"
        [disabled]="disabled"
        (click)="openPicklist()"
        (focus)="openPicklist()"
    >
        {{value ? label : ''}}
    </button>

    <input
        [autocomplete]="disabledAutocomplete"
        class="search-input rmc-input"
        type="text"
        [(ngModel)]="query"
        (ngModelChange)="search()"
        (keydown)="onInputKey($event)"
    >

    <img class="search-icon" src="/images/magnifier.svg" width="24" height="24">
    <img class="picklist-icon" src="/images/dropdown-caret.svg" width="21" height="12">

    <div class="options">
        <div
            *ngFor="let optionModel of optionModels; let i = index; trackBy: trackOptionModel"
            class="option"
            [ngClass]="{
                'option--selected': i === selectIndex
            }"
            (click)="selectOption(i)"
            (mouseenter)="onOptionHover(i)"
            (mousemove)="onOptionHover(i)"
        >
            <span
                *ngFor="let labelPart of optionModel.labelParts; trackBy: trackByIndex"
                class="option-part"
                [ngClass]="{
                    'option-part--match': labelPart.match,
                    'option-part--other': !labelPart.match
                }"
            >{{labelPart.text}}</span>
        </div>
    </div>
</div>
