import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TransferDetails } from '../../helpers/extensions';

@Component({
    selector: 'rmc-transfer-details',
    templateUrl: './transfer-details.component.html',
    styleUrls: ['./transfer-details.component.scss']
})
export class TransferDetailsComponent implements OnInit {

    @Input()
    details: TransferDetails;
    @Output()
    detailsChange: EventEmitter<TransferDetails> = new EventEmitter<TransferDetails>();

    constructor() { }

    ngOnInit() {
    }

    onChange(): void {
        this.detailsChange.emit(this.details);
    }
}
