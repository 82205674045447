<rmc-modal *ngIf="isOpen" (close)="close()">
    <div class="rmc-modal__header">
        Edit Guest Details
    </div>
    <div class="rmc-modal__body">
        <div class="modal-col rmc-form">
            <rmc-guest
                [autosave]="true"
                [guestIndex]="guestIndex"
                [roomIndex]="roomIndex"
                [submitted]="true"
                [traveller]="traveller"
            ></rmc-guest>
        </div>

        <div class="slds-p-bottom--medium">
            <button class="rmc-button rmc-button--brand" (click)="submit()">
                <rmc-button-bouncer *ngIf="isLoading()"></rmc-button-bouncer>
                <span *ngIf="!isLoading()">Confirm</span>
            </button>
        </div>
    </div>
</rmc-modal>
