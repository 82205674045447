import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { BookingUpdateEvent } from '../..//helpers/booking';
import { Currency, Prices } from '../../helpers/prices';
import { BookingDetails, CruiseDetails, PaymentDetails } from '../../helpers/selections';
import { Traveller } from '../../helpers/traveller';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { GuestDetailsService } from '../../services/guest-details.service';
import { NavigationService } from '../../services/navigation.service';
import { ReviewService } from '../../services/review.service';

@Component({
    selector: 'rmc-review-details',
    templateUrl: './review-details.component.html',
    styleUrls: ['./review-details.component.scss']
})
export class ReviewDetailsComponent implements OnInit {

    arrivalDate: Date;
    bookingDetails: BookingDetails;
    conditionsCheckbox: boolean;
    conditionsUrl: string = environment.conditionsUrl;
    cruiseDetails: Array<CruiseDetails>;
    currency: Currency;
    dateFormat: string = environment.dateFormat;
    departureDate: Date;
    paymentDetails: PaymentDetails;
    private paymentDetailsRequestCounter = 0;
    isPricesReady = false;
    isReady = false;
    offersCheckbox = false;
    rooms: Array<Array<Traveller>>;
    serviceLevelLabel: string;
    tourName: string;
    aeroplanStatus: string;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private guestDetailsService: GuestDetailsService,
        private navigationService: NavigationService,
        private reviewService: ReviewService,
    ) { }

    async ngOnInit() {
        this.conditionsCheckbox = this.reviewService.conditionsCheckbox;

        await this.appService.onInit();
        this.isReady = true;
        this.currency = this.bookingService.getCurrency();

        this.initBookingDetails();
        this.bookingService.listenEvents([
            BookingUpdateEvent.BookingDetails,
        ]).subscribe(() => {
            this.initBookingDetails();
        });

        this.initCruiseDetails();
        this.bookingService.listenEvents([
            BookingUpdateEvent.CruiseDetails,
        ]).subscribe(() => {
            this.initCruiseDetails();
        });

        this.initPaymentDetails();
        this.bookingService.listenEvents([
            BookingUpdateEvent.PaymentDetails,
        ]).subscribe(() => {
            if (!this.paymentDetailsRequestCounter) { // check if this change is external
                this.initPaymentDetails();
            }
        });

        this.tourName = this.bookingService.getPackage().externalName;
        this.departureDate = this.bookingService.getDepartureDate(true);
        this.arrivalDate = this.bookingService.getArrivalDate(true);
        this.serviceLevelLabel = await this.bookingService.getServiceLevelName();

        this.initOccupancy();
        this.bookingService.listenEvents([
            BookingUpdateEvent.Occupancy,
            BookingUpdateEvent.TravellerDetails,
        ]).subscribe(() => {
            this.initOccupancy();
        });

        await this.bookingService.initPriceOptions();
        this.isPricesReady = true;
    }

    getPrices(): Prices {
        return this.bookingService.getPrices();
    }

    hasRequests(): boolean {
        return Object.keys(this.cruiseDetails).length > 0;
    }

    private initBookingDetails(): void {
        this.bookingDetails = this.bookingService.getBookingDetails();
    }

    private initCruiseDetails(): void {
        this.cruiseDetails = this.bookingService.getCruiseDetails() || [];
    }

    private initOccupancy(): void {
        this.rooms = this.guestDetailsService.getGuestsByRooms();
        this.aeroplanStatus = this.bookingService.getAeroplanStatus();
    }

    private initPaymentDetails(): void {
        this.paymentDetails = this.bookingService.getPaymentDetails();
    }

    cruiseDetailsExists(details) {
        return details && Object.keys(details).length > 0;
    }

    isCheckingAvailability(): boolean {
        return this.reviewService.isCheckingAvailability();
    }

    isLoading(): boolean {
        return this.reviewService.isLoading();
    }

    isSubmitted(): boolean {
        return this.reviewService.isSubmitted();
    }

    openDetails(): void {
        this.navigationService.navigate('/guest-details');
    }

    openItineraryModal(): void {
        this.reviewService.openItineraryModal();
    }

    async selectDeposit(): Promise<void> {
        this.paymentDetails.deposit_type = 'minimum';

        this.paymentDetailsRequestCounter++;
        await this.bookingService.updatePaymentDetails(this.paymentDetails).then(() => {
            this.paymentDetailsRequestCounter--;
        }, () => {
            this.paymentDetailsRequestCounter--;
        });
    }

    async selectTotal(): Promise<void> {
        this.paymentDetails.deposit_type = 'full';

        this.paymentDetailsRequestCounter++;
        await this.bookingService.updatePaymentDetails(this.paymentDetails).then(() => {
            this.paymentDetailsRequestCounter--;
        }, () => {
            this.paymentDetailsRequestCounter--;
        });
    }

    setConditions(): void {
        this.reviewService.conditionsCheckbox = this.conditionsCheckbox;
    }

    submit(): void {
        if (!this.isLoading()) {
            this.reviewService.submitReview();
        }
    }

    async reserve(): Promise<void> {
        if (!this.isLoading()) {
            await this.reviewService.reserveBooking();
        }
    }


    trackByIndex(index: number): number {
        return index;
    }
}
