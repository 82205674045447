import { Component, Input, OnInit } from '@angular/core';
import { debounce } from 'lodash';

import { environment } from '../../../environments/environment';
import { BookingUpdateEvent } from '../../helpers/booking';
import { PicklistOption } from '../../helpers/picklist';
import { BookingDetails, BookingDetailsError, bookingDetailsRequiredSchema } from '../../helpers/selections';
import { RequiredSchema } from '../../helpers/validation';

import { GuestsUpdateEvent } from '../../helpers/guests-details';
import { GuestDetailsService } from '../../services/guest-details.service';
import { BookingService } from '../../services/booking.service';
import { GeoService } from '../../services/geo.service';

@Component({
    selector: 'rmc-guests-details-billing',
    templateUrl: './guests-details-billing.component.html',
    styleUrls: ['./guests-details-billing.component.scss']
})
export class GuestsDetailsBillingComponent implements OnInit {

    @Input()
    submitted: boolean;

    billing_input_disabled: boolean = false;
    bookingDetails: BookingDetails;
    bookingDetailsRequestCounter = 0;
    countryOptions: Array<PicklistOption>;
    private debouncedSave: () => void;
    errors: BookingDetailsError = {};
    loaders: {[field: string]: boolean} = {};
    provinceOptions: Array<PicklistOption>;
    requiredSchema: RequiredSchema = bookingDetailsRequiredSchema;

    constructor(
        private bookingService: BookingService,
        private geoService: GeoService,
	private guestDetailsService: GuestDetailsService
    ) { }

    ngOnInit() {
        this.initDetails();
        this.bookingService.listenEvents([
            BookingUpdateEvent.BookingDetails,
        ]).subscribe(() => {
            if (!this.bookingDetailsRequestCounter) { // check if this change is external
                this.initDetails();
            }
        });

	this.guestDetailsService.listenEvents([GuestsUpdateEvent.BeginChangeCommit]).subscribe(() => {
		this.billing_input_disabled = true;
	});
	this.guestDetailsService.listenEvents([GuestsUpdateEvent.EndChangeCommit]).subscribe(() => {
		this.billing_input_disabled = false;
	});


        this.countryOptions = this.geoService.getCountryOptions();
        this.initProvinceOptions();

        this.debouncedSave = debounce(this.save, environment.autosaveInterval);
    }

    private initDetails(): void {
        this.bookingDetails = this.bookingService.getBookingDetails();
    }

    private async initProvinceOptions(): Promise<void> {
        this.provinceOptions = null;

        if (this.bookingDetails.country) {
            this.loaders.province = true;
            this.provinceOptions = await this.geoService.getProvinceOptions(this.bookingDetails.country);
            this.loaders.province = false;
        }
    }

    isHomeCountry(): boolean {
        return this.geoService.isHomeCountry(this.bookingDetails.country);
    }

    isProvinceRequired(): boolean {
        return this.geoService.isBillingProvinceRequired(this.bookingDetails.country);
    }

    onChange(field: string): void {
        this.loaders[field] = true;
        this.debouncedSave();
    }

    onCountryChange(): void {
        this.onChange('country');
        this.initProvinceOptions();

        if (this.bookingDetails.province) {
            this.bookingDetails.province = null;
            this.onChange('province');
        }
    }

    private async save(): Promise<void> {
        this.bookingDetailsRequestCounter++;
        this.bookingService.saveInProgress = true;

        await this.bookingService.updateBookingDetails(this.bookingDetails).then(() => {
            this.errors = {};
        }).catch((errors: BookingDetailsError) => {
            this.errors = errors;
        });

        this.loaders = {};
        this.bookingDetailsRequestCounter--;
        this.bookingService.saveInProgress = false;
    }
}
