<rmc-modal [closeButton]="false">
    <div class="rmc-modal__header">
        {{heading}}
    </div>
    <div class="body rmc-modal__body rmc-modal__body--message">
        <div class="rmc-help__info">
            <ng-content></ng-content>
        </div>

        <a href="javascript:void(0)" class="rmc-help__chat-button rmc-button rmc-button--neutral" type="button" (click)="openChat()">
            Chat Now
        </a>

        <a class="rmc-help__call-us" href="tel:{{servicePhone}}">
            <rmc-phrase label="ACTION_QUESTIONS_CALL"></rmc-phrase>
        </a>
    </div>
</rmc-modal>
