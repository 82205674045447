import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';

@Component({
    selector: 'rmc-no-seats-modal',
    templateUrl: './no-seats-modal.component.html',
    styleUrls: ['./no-seats-modal.component.scss']
})
export class NoSeatsModalComponent implements OnInit {

    isModalOpen = false;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        public navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.bookingService.onNoSeatsModal().subscribe(() => {
            this.isModalOpen = true;
            this.appService.incrementModal();
        });
    }
}
