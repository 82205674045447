import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { BookingService } from './booking.service';
import { GeoService } from './geo.service';
import { GuestDetailsService } from './guest-details.service';
import { NavigationService } from './navigation.service';
import { PhrasesService } from './phrases.service';
import { PromotionsService } from './promotions.service';
import { StaticDataService } from './static-data.service';
import { Observable, Subject } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { BookingUpdateEvent } from '../helpers/booking';

type PromiseCallback = (value?: any) => void;

@Injectable({
    providedIn: 'root'
})
export class AppService {

    private initPromise: Promise<void>;
    private modalCount = 0;
    private rejectCallback: PromiseCallback;
    private resolveCallback: PromiseCallback;

    private modalCountChangeSource: Subject<number> = new Subject<number>();
    private modalCountChange: Observable<number> = this.modalCountChangeSource.asObservable();

    constructor(
        private authService: AuthService,
        private bookingService: BookingService,
        private geoService: GeoService,
        private guestDetailsService: GuestDetailsService,
        private location: Location,
        private navigationService: NavigationService,
        private phrasesService: PhrasesService,
        private promotionsService: PromotionsService,
        private staticDataService: StaticDataService,
        private configService: ConfigurationService
    ) {
        this.initPromise = new Promise((resolve: PromiseCallback, reject: PromiseCallback) => {
            this.resolveCallback = resolve;
            this.rejectCallback = reject;
        });
    }

    /**
     * Checks if route is correct and allowed. Redirects if not.
     * @param initial If it's an initial (first) call or not.
     */
    checkRouteValidity(initial: boolean = false): void {
        const topLevelRoute: string = this.navigationService.getTopLevelRoute();

        if (this.bookingService.isPaid() && topLevelRoute !== 'confirmation') {
            return this.navigationService.navigate('/confirmation');
        }

        switch (topLevelRoute) {
            case 'review':
                if (this.guestDetailsService.requiresAccessibilityAssistance() || !this.guestDetailsService.isDetailsFilled()) {
                    return this.navigationService.navigate('/guest-details');
                }
                break;
            case 'confirmation':
                if (!this.bookingService.isPaid()) {
                    return this.navigationService.navigate('/review');
                }
                break;
        }

        setTimeout(() => { // wait if any navigation is in progress
            if (initial && !this.bookingService.getQueryParam('booking')) {
                const path: string = this.location.path().replace(/(\?.*)?$/i, '');
                const bookingId: string = this.bookingService.getBookingId();
                let fullPath = `${path}?booking=${bookingId}`;
                const backUrl: string = this.navigationService.backUrl;
                if (backUrl) {
                    fullPath = fullPath + `&backUrl=${encodeURIComponent(backUrl)}`;
                }
                const businessUnit: string = this.navigationService.businessUnit;
                if (businessUnit) {
                    fullPath = fullPath + `&business_unit=${encodeURIComponent(businessUnit)}`;
                }
                window.history.replaceState(null, null, fullPath);

                return;
            }

            window.dataLayer.push({
                event: 'ngRouterNavigationEnd',
                attributes: {
                    route: this.location.path(),
                }
            });
        });
    }

    /**
     * Decrements modal counter.
     */
    decrementModal(): void {
        this.modalCount = Math.max(this.modalCount - 1, 0);
        this.modalCountChangeSource.next(this.modalCount);
    }

    /**
     * Modal count change event.
     */
    getModalCountChange(): Observable<number> {
        return this.modalCountChange;
    }

    /**
     * Increments modal counter.
     */
    incrementModal(): void {
        this.modalCount++;
        this.modalCountChangeSource.next(this.modalCount);
    }

    /**
     * Initializes app.
     */
    async init(): Promise<void> {
        try {
            await this.authService.init();

            const bookingPromise: Promise<void> = this.bookingService.init().then(async () => {
                // pre-load services that require booking data
                this.bookingService.initPriceOptions();
                this.guestDetailsService.init();
                this.promotionsService.init();

                await Promise.all([
                    this.bookingService.initCurrency(),
                    this.bookingService.initPackage(),
                ]);
            });

            await Promise.all([
                bookingPromise,
                this.geoService.init(),
                this.phrasesService.init(),
                this.staticDataService.init(),
            ]);

            this.bookingService.triggerEvent(BookingUpdateEvent.BookingDetailsInit);

            this.resolveCallback();
        } catch (e) {
            this.rejectCallback(e);
        }

        // @ts-ignore: This is taken from global js snippet
        window.loadGTM(environment.gtmCode);
        // @ts-ignore: This is taken from global js snippet
        window.loadRecaptcha(environment.recaptchaKey_v3);
        // @ts-ignore: This is taken from global js snippet
        window.loadSnapengage(environment.snapengageId);

        return this.initPromise;
    }

    /**
     * Full app init promise.
     */
    onInit(): Promise<void> {
        return this.initPromise;
    }
}
