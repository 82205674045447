import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { PicklistOption } from '../../helpers/picklist';
import { Traveller } from '../../helpers/traveller';

import { BookingService } from '../../services/booking.service';
import { GeoService } from '../../services/geo.service';
import { GuestDetailsService } from "../../services/guest-details.service";
import { StaticDataService } from '../../services/static-data.service';

@Component({
    selector: 'rmc-review-details-room',
    templateUrl: './review-details-room.component.html',
    styleUrls: ['./review-details-room.component.scss']
})
export class ReviewDetailsRoomComponent implements OnInit {

    @Input()
    guests: Array<Traveller>;

    @Input()
    roomIndex: number;

    private countryOptions: Array<PicklistOption>;
    dateFormat: string = environment.dateFormat;
    private dietaryOptions: Array<PicklistOption>;
    isInternational: boolean;

    constructor(
        private bookingService: BookingService,
        private geoService: GeoService,
        private guestDetailsService: GuestDetailsService,
        private staticDataService: StaticDataService,
    ) { }

    ngOnInit() {
        this.countryOptions = this.geoService.getCountryOptions();
        this.dietaryOptions = this.staticDataService.getValue('dietary_meal_requests');

        this.isInternational = this.bookingService.isInternational();
    }

    editGuest(guestIndex: number): void {
        this.guestDetailsService.editGuest(this.roomIndex, guestIndex);
    }

    getDiet(traveller: Traveller): string {
        return this.getOptionLabel(this.dietaryOptions, traveller.dietary_requests);
    }

    getName(traveller: Traveller): string {
        const nameParts: Array<string> = [traveller.first_name];

        if (traveller.middle_name) {
            nameParts.push(traveller.middle_name);
        }
        nameParts.push(traveller.last_name);

        return nameParts.join(' ');
    }

    getNationality(traveller: Traveller): string {
        return this.getOptionLabel(this.countryOptions, traveller.nationality);
    }

    private getOptionLabel(options: Array<PicklistOption>, value: string): string {
        if (value) {
            const selectedOption: PicklistOption = options.find((option: PicklistOption): boolean => {
                return option.value === value;
            });

            if (selectedOption) {
                return selectedOption.label;
            }
        }

        return null;
    }

    trackByIndex(index: number): number {
        return index;
    }
}
