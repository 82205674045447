import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClickOutsideModule } from 'ng-click-outside';
import { MatTooltipModule } from '@angular/material/tooltip';

import './helpers/google-tag-manager';

import { AppComponent } from './components/app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { FormElementComponent } from './components/form-element/form-element.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { EnvCurrencyPipe } from './pipes/env-currency.pipe';
import { SldsIconDirective } from './directives/slds-icon.directive';
import { InitDirective } from './directives/init.directive';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { ConfigurePageComponent } from './components/configure-page/configure-page.component';
import { TourHeaderComponent } from './components/tour-header/tour-header.component';
import { ConfigureTransferComponent } from './components/configure-transfer/configure-transfer.component';
import { ConfigureExtraNightsComponent } from './components/configure-extra-nights/configure-extra-nights.component';
import { ConfigureInsuranceComponent } from './components/configure-insurance/configure-insurance.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarInsuranceComponent } from './components/sidebar-insurance/sidebar-insurance.component';
import { ConfigureHelpComponent } from './components/configure-help/configure-help.component';
import { PhrasePipe } from './pipes/phrase.pipe';
import { PhraseComponent } from './components/phrase/phrase.component';
import { EditPhraseModalComponent } from './components/edit-phrase-modal/edit-phrase-modal.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { GuestsPageComponent } from './components/guests-page/guests-page.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CancelPolicyComponent } from './components/cancel-policy/cancel-policy.component';
import { PriceBreakdownComponent } from './components/price-breakdown/price-breakdown.component';
import { GuestsDetailsComponent } from './components/guests-details/guests-details.component';
import { GuestsDetailsRoomComponent } from './components/guests-details-room/guests-details-room.component';
import { GuestsDetailsAccessibilityComponent } from './components/guests-details-accessibility/guests-details-accessibility.component';
import { GuestComponent } from './components/guest/guest.component';
import { GuestsDetailsBillingComponent } from './components/guests-details-billing/guests-details-billing.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { PicklistComponent } from './components/picklist/picklist.component';
import { ConfirmationPageComponent } from './components/confirmation-page/confirmation-page.component';
import { ButtonBouncerComponent } from './components/button-bouncer/button-bouncer.component';
import { SidebarExtendJourneyComponent } from './components/sidebar-extend-journey/sidebar-extend-journey.component';
import { ReviewPageComponent } from './components/review-page/review-page.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PricingAddonComponent } from './components/pricing-addon/pricing-addon.component';
import { ReviewDetailsComponent } from './components/review-details/review-details.component';
import { ReviewDetailsRoomComponent } from './components/review-details-room/review-details-room.component';
import { EditGuestModalComponent } from './components/edit-guest-modal/edit-guest-modal.component';
import { SidebarTransfersComponent } from './components/sidebar-transfers/sidebar-transfers.component';
import { TransferComponent } from './components/transfer/transfer.component';
import { InputTimeComponent } from './components/input-time/input-time.component';
import { SwitchComponent } from './components/switch/switch.component';
import { ConfigurePromotionsComponent } from './components/configure-promotions/configure-promotions.component';
import { SidebarPromotionsComponent } from './components/sidebar-promotions/sidebar-promotions.component';
import { ItineraryModalComponent } from './components/itinerary-modal/itinerary-modal.component';
import { InsuranceModalComponent } from './components/insurance-modal/insurance-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { TransferDetailsComponent } from './components/transfer-details/transfer-details.component';
import { ActivityComponent } from './components/activity/activity.component';
import { EmailTripModalComponent } from './components/email-trip-modal/email-trip-modal.component';
import { ManagedModalComponent } from './components/managed-modal/managed-modal.component';
import { LockModalComponent } from './components/lock-modal/lock-modal.component';
import { NoSeatsModalComponent } from './components/no-seats-modal/no-seats-modal.component';
import { PriceBreakdownCardComponent } from './components/price-breakdown-card/price-breakdown-card.component';

@NgModule({
    declarations: [
        AppComponent,
        AppHeaderComponent,
        FormElementComponent,
        EnvCurrencyPipe,
        SldsIconDirective,
        InitDirective,
        AppFooterComponent,
        ConfigurePageComponent,
        TourHeaderComponent,
        ConfigureTransferComponent,
        ConfigureExtraNightsComponent,
        ConfigureInsuranceComponent,
        SidebarComponent,
        SidebarInsuranceComponent,
        ConfigureHelpComponent,
        PhrasePipe,
        PhraseComponent,
        EditPhraseModalComponent,
        BreadcrumbsComponent,
        GuestsPageComponent,
        ContactUsComponent,
        CancelPolicyComponent,
        PriceBreakdownComponent,
        GuestsDetailsComponent,
        GuestsDetailsRoomComponent,
        GuestsDetailsAccessibilityComponent,
        GuestComponent,
        GuestsDetailsBillingComponent,
        InputDateComponent,
        PicklistComponent,
        ConfirmationPageComponent,
        ButtonBouncerComponent,
        SidebarExtendJourneyComponent,
        ReviewPageComponent,
        CheckboxComponent,
        PricingComponent,
        PricingAddonComponent,
        ReviewDetailsComponent,
        ReviewDetailsRoomComponent,
        EditGuestModalComponent,
        SidebarTransfersComponent,
        TransferComponent,
        InputTimeComponent,
        SwitchComponent,
        ConfigurePromotionsComponent,
        SidebarPromotionsComponent,
        ItineraryModalComponent,
        InsuranceModalComponent,
        ModalComponent,
        MessageModalComponent,
        TransferDetailsComponent,
        ActivityComponent,
        EmailTripModalComponent,
        ManagedModalComponent,
        LockModalComponent,
        NoSeatsModalComponent,
        PriceBreakdownCardComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule, // according to docs, you should import HttpClientModule after BrowserModule
        AppRoutingModule,
        FormsModule,
        ClickOutsideModule,
        BrowserAnimationsModule,
        MatTooltipModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
