import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { NavigationService } from './navigation.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    constructor(
        private location: Location,
        private navigationService: NavigationService
    ) { }

    /**
     * Closes configuration sidebar.
     */
    close(): void {
        this.navigationService.navigate('/configure');
    }

    /**
     * Checks if configuration sidebar is open.
     * @returns If configuration sidebar is open.
     */
    isOpen(): boolean {
        const path: string = this.location.path();
        const matches : RegExpMatchArray = path.match(/^\/configure\/(.+)/i);

        return Boolean(matches && matches.length > 1);
    }

    /**
     * Opens configuration sidebar.
     * @param configUrl Configuration to open.
     */
    private openConfig(configUrl: string): void {
        this.navigationService.navigate(`/configure/${configUrl}`);
    }

    /**
     * Opens 'Extend Journey' sidebar.
     */
    openExtendJourney(): void {
        this.openConfig('extend-journey');
    }

    /**
     * Opens 'Insurance' sidebar.
     */
    openInsurance(): void {
        this.openConfig('insurance');
    }

    /**
     * Opens 'Promotions' sidebar.
     */
    openPromotions(): void {
        this.openConfig('promotions');
    }

    /**
     * Opens 'Transfers' sidebar.
     */
    openTransfers(): void {
        this.openConfig('transfers');
    }
}
