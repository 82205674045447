import { Injectable } from '@angular/core';

import { ActivityOptionPromises } from '../helpers/activities';
import { Choice } from '../helpers/choices';
import { Activity } from '../helpers/extensions';
import { Product, ProductOption } from '../helpers/products';
import { Selections } from '../helpers/selections';

import { BookingService } from '../services/booking.service';
import { ProductsService } from '../services/products.service';

@Injectable({
    providedIn: 'root'
})
export class ActivitiesService {

    private optionWithoutPricePromises: ActivityOptionPromises = {
        post_activity: null,
        pre_activity: null,
    };
    private optionWithPricePromises: ActivityOptionPromises = {
        post_activity: null,
        pre_activity: null,
    };

    constructor(
        private bookingService: BookingService,
        private productsService: ProductsService,
    ) { }

    /**
     * Retrieves activities by IDs and builds <activity ID, activity> map.
     * @param activityIds Activity IDs to retrieve.
     * @returns <activity ID, activity> map.
     */
    private async getActivityMap(activityIds: Array<string>): Promise<Map<string, Product>> {
        const activities: Array<Product> = activityIds.length ? (await this.productsService.getProducts(activityIds)) : [];
        const activityMap: Map<string, Product> = new Map<string, Product>();

        for (const activity of activities) {
            activityMap.set(activity.id, activity);
        }

        return activityMap;
    }

    /**
     * Builds an array of unique activity product option IDs.
     * @param choices All activity product options.
     * @param type 'pre_activity'|'post_activity'
     * @returns Unique activity product option IDs.
     */
    private async getActivityProductIds(choices: Array<Choice>, type: string): Promise<Array<string>> {
        const ids: Set<string> = new Set<string>();

        for (const choice of choices) {
            const selections: Selections = choice.selections;
            const activitySelection: Activity = selections[type];

            if (activitySelection) {
                ids.add(activitySelection.items[0].id);
            }
        }

        return Array.from(ids);
    }

    /**
     * Retrieves (if needed) and returns activity options.
     * @param type 'pre_activity'|'post_activity'
     * @param includePrices If prices are added to the response. Prices could be omitted for optimization purposes.
     * @returns Activity options.
     */
    async getOptions(type: string, includePrices: boolean = true): Promise<Array<ProductOption>> {
        const promises: ActivityOptionPromises = includePrices ? this.optionWithPricePromises : this.optionWithoutPricePromises;
        promises[type] = promises[type] || this.retrieveOptions(type, includePrices);

        if (includePrices && !this.optionWithoutPricePromises[type]) {
            this.optionWithoutPricePromises[type] = this.optionWithPricePromises[type];
        }

        return await promises[type];
    }

    /**
     * Returns activity product selection ID.
     * @param type 'pre_activity'|'post_activity'
     * @returns Activity product selection ID.
     */
    getSelectionId(type: string): string {
        const selections: Selections = this.bookingService.getSelections();

        return selections[type] ? selections[type].items[0].id : null
    }

    /**
     * Refreshes activity options.
     * @param type 'pre_activity'|'post_activity'
     */
    refreshOptions(type: string): void {
        this.optionWithoutPricePromises[type] = null;
        this.optionWithPricePromises[type] = null;
    }

    /**
     * Retrieves activity options.
     * @param type 'pre_activity'|'post_activity'
     * @param includePrices If prices are added to the response. Prices could be omitted for optimization purposes.
     * @returns Activity options.
     */
    private async retrieveOptions(type: string, includePrices: boolean = true): Promise<Array<ProductOption>> {
        const choices: Array<Choice> = (await this.bookingService.retrieveChoices([
            type,
        ], includePrices))[type];
        const activityIds: Array<string> = await this.getActivityProductIds(choices, type);
        const activityMap: Map<string, Product> = await this.getActivityMap(activityIds);
        const optionMap: Map<string, ProductOption> = new Map<string, ProductOption>();

        for (const choice of choices) {
            const selections: Selections = choice.selections;
            const activitySelection: Activity = selections[type];
            const activity: Product = activitySelection ? activityMap.get(activitySelection.items[0].id) : null;

            if (activity) {
                const date: string = activitySelection ? activitySelection.items[0].date : null;
                const option: ProductOption = {
                    product: activity,
                    date: date
                };

                if (includePrices) {
                    const price: any = choice.price.prices_by_types[type];
                    option.price = price.service + price.fee + price.promotion;
                }

                optionMap.set(activity.id, option);
            }
        }

        const options: Array<ProductOption> = Array.from(optionMap.values());

        return options;
    }
}
