import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { BookingUpdateEvent } from '../../helpers/booking';
import { GroupedItinerary, Itinerary, ServiceItem } from '../../helpers/itinerary';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { ItineraryService } from '../../services/itinerary.service';
import { ReviewService } from '../../services/review.service';

@Component({
    selector: 'rmc-itinerary-modal',
    templateUrl: './itinerary-modal.component.html',
    styleUrls: ['./itinerary-modal.component.scss']
})
export class ItineraryModalComponent implements OnInit {

    dateFormat: string = environment.dateFormat;
    dates: Array<string>;
    isModalOpen = false;
    groupedItinerary: GroupedItinerary;
    private itinerary: Itinerary;
    sectionOpen: Array<boolean>;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private itineraryService: ItineraryService,
        private reviewService: ReviewService,
    ) { }

    ngOnInit() {
        this.reviewService.onItineraryModal().subscribe(() => {
            this.isModalOpen = true;
            this.appService.incrementModal();

            this.init();
        });

        this.bookingService.listenEvents([
            BookingUpdateEvent.ExtraPostNights,
            BookingUpdateEvent.ExtraPreNights,
            BookingUpdateEvent.PostActivity,
            BookingUpdateEvent.PreActivity,
        ]).subscribe(() => {
            if (this.isModalOpen) {
                this.init();
            }
        });
    }

    close(): void {
        this.isModalOpen = false;
        this.appService.decrementModal();
    }

    closeSections(): void {
        for (let i = 0; i < this.sectionOpen.length; ++i) {
            this.sectionOpen[i] = false;
        }
    }

    expandSections(): void {
        for (let i = 0; i < this.sectionOpen.length; ++i) {
            this.sectionOpen[i] = true;
        }
    }

    getIcon(date: string): string {
        return this.itineraryService.getIcon(this.itinerary[date]);
    }

    async init(): Promise<void> {
        this.itinerary = await this.itineraryService.getItinerary();
        this.groupedItinerary = this.itineraryService.groupItinerary(this.itinerary);

        this.dates = Object.keys(this.itinerary);
        this.sectionOpen = this.dates.map((): boolean => {
            return false;
        });
    }

    isAllExpanded(): boolean {
        for (const isOpen of this.sectionOpen) {
            if (!isOpen) {
                return false;
            }
        }

        return true;
    }

    toggleSection(index: number): void {
        this.sectionOpen[index] = !this.sectionOpen[index];
    }

    trackByIndex(index: number): number {
        return index;
    }

    trackServiceItem(index: number, service: ServiceItem): string {
        return service.item.id;
    }
}
