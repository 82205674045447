<div *ngIf="isReady" class="rmc-sidebar__content">
    <div class="rmc-sidebar__body">
        <div class="rmc-sidebar__scrollable">
            <div class="rmc-sidebar__header">
                Airport Transfers
                <img class="rmc-sidebar__close" src="/images/times.svg" (click)="close()" />
            </div>

            <div class="transfers">
                <div *ngIf="transferOptions.pre.length > 1 || promotionalTransfer === 'pre'" class="transfer">
                    <rmc-transfer
                        [(selection)]="preTransfer"
                        [title]="preTransferTitle"
                        [transferOptions]="transferOptions.pre"
                    ></rmc-transfer>
                </div>

                <div *ngIf="transferOptions.post.length > 1 || promotionalTransfer === 'post'" class="transfer">
                    <rmc-transfer
                        [(selection)]="postTransfer"
                        [title]="postTransferTitle"
                        [transferOptions]="transferOptions.post"
                    ></rmc-transfer>
                </div>
            </div>
        </div>
    </div>

    <div class="rmc-sidebar__footer">
        <div class="rmc-sidebar__footer-actions">
            <a href="javascript:void(0)" (click)="close()">Cancel</a>
        </div>

        <div class="rmc-sidebar__footer-total">
            <div *ngIf="!showRates" class="total-placeholder rmc-placeholder">&nbsp;</div>
            <div *ngIf="showRates">Total: {{ getTotal() | envCurrency }} {{currency.code}}</div>
        </div>

        <div class="rmc-sidebar__footer-submit">
            <div *ngIf="!showRates" class="confirm-placeholder rmc-placeholder rmc-placeholder--button">&nbsp;</div>
            <button *ngIf="showRates" class="rmc-button rmc-button--brand" (click)="updateTransfers()" type="button">
                <rmc-button-bouncer *ngIf="isSaving"></rmc-button-bouncer>
                <span *ngIf="!isSaving">Confirm</span>
            </button>
        </div>
    </div>
</div>
