<rmc-modal [closeButton]="false">
    <div *ngIf="heading" class="rmc-modal__header">
        {{heading}}
    </div>
    <div class="rmc-modal__body rmc-modal__body--message">
        <div class="rmc-modal__message-text">
            <ng-content></ng-content>
        </div>

        <div>
            <button class="rmc-modal__message-action rmc-button rmc-button--brand" (click)="closeModal()">{{button}}</button>
        </div>
    </div>
</rmc-modal>
