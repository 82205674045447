import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Currency } from '../../helpers/prices';

import { BookingService } from '../../services/booking.service';

@Component({
    selector: 'rmc-pricing-addon',
    templateUrl: './pricing-addon.component.html',
    styleUrls: ['./pricing-addon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingAddonComponent implements OnInit {

    @Input()
    disabled: boolean;

    @Input()
    dropdown: boolean;

    @Input()
    label: string;

    @Input()
    loading = false;

    @Input()
    price: number;

    @Output()
    remove: EventEmitter<void> = new EventEmitter<void>();

    currency: Currency;

    constructor(
        private bookingService: BookingService,
    ) { }

    ngOnInit() {
        this.currency = this.bookingService.getCurrency();
    }

    removeAddon(): void {
        this.remove.emit();
    }
}
