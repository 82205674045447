import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

import { BookingService } from '../../services/booking.service';
import { SnapengageService} from '../../services/snapengage.service';

@Component({
    selector: 'rmc-lock-modal',
    templateUrl: './lock-modal.component.html',
    styleUrls: ['./lock-modal.component.scss']
})
export class LockModalComponent implements OnInit {

    @Input()
    heading: string;

    serviceCode: string;
    servicePhone: string;

    constructor(
        private bookingService: BookingService,
        private snapengageService: SnapengageService,
    ) { }

    ngOnInit() {
        this.serviceCode = this.bookingService.getServiceCode();
        this.servicePhone = environment.servicePhone;
    }

    openChat(): void {
        this.snapengageService.openChat();
    }
}
