import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'rmc-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    @HostListener('document:keydown.escape')
    onEscape() {
        if (this.sidebarOpen) {
            this.close();
        }
    }

    sidebarOpen = false;

    constructor(
        private configurationService: ConfigurationService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.checkSidebar();

        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                this.checkSidebar();
            }
        });
    }

    private checkSidebar(): void {
        this.sidebarOpen = this.configurationService.isOpen();
    }

    close(): void {
        this.configurationService.close();
    }
}
