<div class="rmc-form">
    <div class="slds-p-bottom--large">
        <rmc-form-element [required]="true">
            <rmc-checkbox [(value)]="details.notBooked" (valueChange)="onChange()">
                I have not booked my flights
            </rmc-checkbox>
        </rmc-form-element>
    </div>

    <div *ngIf="details.notBooked" class="slds-p-bottom--large">
        We will follow up with you closer to your departure date to get your flight information.
    </div>

    <div *ngIf="!details.notBooked">
        <div class="slds-p-bottom--large">
            <rmc-form-element
                label="Flight Arrival or Departure Date"
                [required]="true"
            >
                <rmc-input-date
                    [(value)]="details.date"
                    (valueChange)="onChange()"
                ></rmc-input-date>
            </rmc-form-element>
        </div>

        <div class="slds-p-bottom--large">
            <rmc-form-element
                label="Flight Arrival or Departure Time"
                [required]="true"
            >
                <rmc-input-time
                    [(value)]="details.time"
                    (valueChange)="onChange()"
                ></rmc-input-time>
            </rmc-form-element>
        </div>

        <div class="slds-p-bottom--large">
            <rmc-form-element
                label="Flight Number"
                [required]="true"
            >
                <input
                    class="rmc-input"
                    maxlength="9"
                    name="flightNumber"
                    type="text"
                    [(ngModel)]="details.flightNumber"
                    (ngModelChange)="onChange()"
                />
            </rmc-form-element>
        </div>
    </div>
</div>
