import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class StaticDataService {

    private data: {
        [name: string]: any;
    } = {};

    constructor(
        private apiService: ApiService,
    ) { }

    /**
     * Returns static data set.
     * @param name Data set name.
     * @returns Data set.
     */
    getValue(name: string): any {
        return this.data[name] ? JSON.parse(JSON.stringify(this.data[name])) : null;
    }

    /**
     * Pre-loads static data sets.
     */
    async init(): Promise<void> {
        const promises: Array<Promise<void>> = environment.staticData.map(async (name: string): Promise<void> => {
            const value: any = (await this.apiService.get(`${environment.apiUrl}products/_relay/passenger__${name}/`)).results;
            this.data[name] = value;
        });

        await Promise.all(promises);
    }
}
