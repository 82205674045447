import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { Account, accountApiToLocal, ApiAccountResponse, generateId } from '../helpers/auth';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private account: Account = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private router: Router
    ) { }

    /**
     * Returns account object if user is authorized.
     * @returns User account object.
     */
    getAccount(): Account {
        return this.account;
    }

    /**
     * Returns account object if user is authorized.
     * @returns User account object.
     */
    getClientId(): string {
        return localStorage.getItem(environment.clientIdStorage);
    }

    /**
     * Returns auth token if user is authorized.
     * @returns Auth token.
     */
    getToken(): string {
        return localStorage.getItem(environment.authStorage);
    }

    /**
     * Initializes service.
     */
    async init(): Promise<void> {
        if (!this.getClientId()) {
            const clientId: string = generateId(32);
            localStorage.setItem(environment.clientIdStorage, clientId);
        }

        const paramMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;
        const urlToken: string = paramMap.get(environment.authUrlParam);

        if (urlToken) {
            this.storeToken(urlToken);

            const cleanPath: string = this.router.url.split('?')[0];
            const cleanQuery: string = paramMap.keys.filter((key: string) => {
                return key !== environment.authUrlParam;
            }).map((key: string) => {
                return `${key}=${encodeURIComponent(paramMap.get(key))}`;
            }).join('&');
            const cleanUrl: string = cleanPath + (cleanQuery ? `?${cleanQuery}` : '');

            this.router.navigateByUrl(cleanUrl);
        }

        const token: string = this.getToken();

        if (token) {
            return this.apiService.get(`${environment.apiUrl}accounts/users/`).then((apiAccountResponse: ApiAccountResponse) => {
                this.account = accountApiToLocal(apiAccountResponse);
            }).catch((e) => {
                // If the token is expired or not valid for any other reason,
                // we are loggin the user out.
                if (e.status === 401) {
                    this.logout();
                }
            });
        }
    }

    /**
     * Checks if user is authorized.
     * @returns If user is authorized.
     */
    isAuthorized(): boolean {
        return Boolean(this.account);
    }

    /**
     * Redirects user to login page.
     */
    login(): void {
        const redirectParam: string = encodeURIComponent(location.href);
        location.href = `${environment.apiUrl}auth/login/?cid=${environment.channelId}&next=${redirectParam}`;
    }

    /**
     * Logs user out and reloads page.
     */
    logout(): void {
        this.account = null;
        localStorage.removeItem(environment.authStorage);

        location.reload();
    }

    /**
     * Saves auth token in LocalStorage.
     * @param token Auth token.
     */
    private storeToken(token: string): void {
        localStorage.setItem(environment.authStorage, token);
    }
}
