<div class="rmc-sidebar__content" *ngIf="isReady">
    <div class="rmc-sidebar__body">
        <div class="rmc-sidebar__scrollable">
            <div class="rmc-sidebar__header">
                Travel Insurance
                <img class="rmc-sidebar__close" src="/images/times.svg" (click)="close()" />
            </div>

            <table class="items-table">
                <thead>
                    <tr>
                        <th class="col--name">
                            <a [href]="insuranceConditions" target="_blank">View Full Policy</a>
                            <img class="provider-logo" src="/images/allianz-logo.png">
                        </th>
                        <th class="col--value">
                            <div class="col__title">All Inclusive Travel Plan</div>
                        </th>
                        <th class="col--value">
                            <div class="col__label">Recommended</div>
                            <div class="col__title">All Inclusive Travel Plan with Cancel Anytime</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items; trackBy: trackByIndex" class="col--name">
                        <td>
                            {{ item[0] }}
                        </td>
                        <td class="col--value">
                            <svg class="unchecked-icon rmc-icon--m slds-icon" rmcSldsIcon="close" *ngIf="!item[1]"></svg>
                            <img class="checked-icon" src="/images/checkmark-gold.png" *ngIf="item[1]">
                        </td>
                        <td class="col--value">
                            <svg class="unchecked-icon rmc-icon--m slds-icon" rmcSldsIcon="close" *ngIf="!item[2]"></svg>
                            <img class="checked-icon" src="/images/checkmark-gold.png" *ngIf="item[2]">
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td class="col--value">
                            <div *ngIf="!showRates" class="rmc-placeholder">&nbsp;</div>
                            <div *ngIf="showRates">
                                {{ priceByInsuranceType.standard.insurance.total | envCurrency }} {{currency.code}}
                                <sup>1</sup>
                            </div>
                        </td>
                        <td class="col--value">
                            <div *ngIf="!showRates" class="rmc-placeholder">&nbsp;</div>
                            <div *ngIf="showRates">
                                {{ priceByInsuranceType.plus.insurance.total | envCurrency }} {{currency.code}}
                                <sup>1</sup>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="col--value">
                            <a href="javascript:void(0)" class="rmc-button rmc-button--neutral" *ngIf="plan !== 'standard'" (click)="setInsurancePlan('standard')">Select <sup>2</sup></a>
                            <a href="javascript:void(0)" class="rmc-button rmc-button--success" *ngIf="plan === 'standard'" (click)="setInsurancePlan(null)">
                                <svg class="icon-checkmark">
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/checkmark.svg#check"></use>
                                </svg>
                                Selected
                                <sup>2</sup>
                            </a>
                        </td>
                        <td class="col--value">
                            <a href="javascript:void(0)" class="rmc-button rmc-button--neutral" *ngIf="plan !== 'plus'" (click)="setInsurancePlan('plus')">Select <sup>2</sup></a>
                            <a href="javascript:void(0)" class="rmc-button rmc-button--success" *ngIf="plan === 'plus'" (click)="setInsurancePlan(null)">
                                <svg class="icon-checkmark">
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/checkmark.svg#check"></use>
                                </svg>
                                Selected
                                <sup>2</sup>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="footnote">
                <p><sup>1</sup> Provided rates are based on travellers at the age of 65. Your individual rate will be displayed at checkout.</p>
                <p><sup>2</sup> Within Canada, insurance can only be purchased by residents of British Columbia and Ontario. For all other provinces and territories within Canada, insurance will be automatically declined.</p>
            </div>

        </div>
    </div>

    <div class="rmc-sidebar__footer">
        <div class="rmc-sidebar__footer-actions">
            <a href="javascript:void(0)" (click)="close()">Cancel</a>
        </div>

        <div class="rmc-sidebar__footer-total">
            <div *ngIf="!showRates" class="total-placeholder rmc-placeholder">&nbsp;</div>
            <div *ngIf="showRates">Total: {{ getInsuranceRate() | envCurrency }} {{currency.code}}</div>
        </div>

        <div class="rmc-sidebar__footer-submit">
            <div *ngIf="!showRates" class="confirm-placeholder rmc-placeholder rmc-placeholder--button">&nbsp;</div>
            <button *ngIf="showRates" class="rmc-button rmc-button--brand" [disabled]="!hasSelectionChanged()" (click)="updateSelection()" type="button">
                <rmc-button-bouncer *ngIf="isSaving"></rmc-button-bouncer>
                <span *ngIf="!isSaving">Confirm</span>
            </button>
        </div>
    </div>
</div>
