import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { dateStringToDate, dateToDateString } from '../helpers/date';
import { GroupedItinerary, GroupedItineraryDay, Itinerary, ServiceItem } from '../helpers/itinerary';

import { ApiService } from './api.service';
import { BookingService } from './booking.service';

@Injectable({
    providedIn: 'root'
})
export class ItineraryService {

    constructor(
        private apiService: ApiService,
        private bookingService: BookingService,
    ) { }

    /**
     * Selects day icon according to the activities.
     * @param dayItems Activities that are applied to this day.
     * @returns Icon name.
     */
    getIcon(dayItems: Array<ServiceItem>): string {
        let hasCar: boolean = false;
        let hasCruise: boolean = false;
        let hasRail: boolean = false;
        let hasCoach: boolean = false;

        for (const item of dayItems) {
            switch (item.item_type.code) {
                case 'Car_Rental':
                    hasCar = true;
                    break;
                case 'Cruise':
                    hasCruise = true;
                    break;
                case 'Rail':
                case 'Rail_Coastal_Passage':
                    hasRail = true;
                    break;
                case 'Transfer':
                    hasCoach = true;
                    break;
            }
        }

        if (hasCruise) {
            return 'cruise';
        } else if (hasRail) {
            return 'rail';
        } else if (hasCoach) {
            return 'coach';
        } else if (hasCar) {
            return 'car';
        } else {
            return 'free';
        }
    }

    /**
     * Returns trip itinerary.
     * @returns Trip itinerary.
     */
    async getItinerary(): Promise<Itinerary> {
        const serviceItems: Array<ServiceItem> = await this.getServiceItems();
        const itinerary: Itinerary = {};

        for (const item of serviceItems) {
            if (item.item_type.code === 'Verbiage') {
                continue;
            }

            const startDate: Date = dateStringToDate(item.date_from);
            let date: Date = startDate;

            let endDate: Date = dateStringToDate(item.date_to);
            if (item.item_type.code === 'Accommodation') {
                // don't display check-out date
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1);
            }
            const endTime: number = endDate.getTime();

            while(date.getTime() <= endTime) {
                const dateString: string = dateToDateString(date);

                if (!itinerary[dateString]) {
                    itinerary[dateString] = [];
                }
    
                itinerary[dateString].push(item);

                date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
            }
        }

        return itinerary;
    }

    /**
     * Returns trip activities.
     * @returns Trip activity list.
     */
    async getServiceItems(): Promise<Array<ServiceItem>> {
        const bookingId: string = this.bookingService.getBookingId();
        const serviceItems: Array<ServiceItem> = await this.apiService.get(`${environment.apiUrl}bookings/bookings/${bookingId}/services/`);

        return serviceItems.filter((item: ServiceItem): boolean => {
            return (!item.item_option && item.add_on) ? false : true;
        });
    }

    /**
     * Groups daily activities by type.
     * @param itinerary Trip itinerary.
     * @returns Grouped itiberary.
     */
    groupItinerary(itinerary: Itinerary): GroupedItinerary {
        const groupedItinerary: GroupedItinerary = {};

        for (const date in itinerary) {
            const groupedDay: GroupedItineraryDay = {
                accommodations: [],
                otherActivities: [],
                transfers: [],
            };

            for (const item of itinerary[date]) {
                switch (item.item_type.code) {
                    case 'Accommodation':
                        groupedDay.accommodations.push(item);
                        break;
                    case 'Transfer':
                        groupedDay.transfers.push(item);
                        break;
                    default:
                        groupedDay.otherActivities.push(item);
                        break;
                }
            }

            groupedItinerary[date] = groupedDay;
        }

        return groupedItinerary;
    }
}
