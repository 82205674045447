import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';

import { environment } from '../../../environments/environment';
import { Breadcrumb } from '../../helpers/navigation';

import { AppService } from '../../services/app.service';
import { BookingService } from '../../services/booking.service';
import { EmailService } from '../../services/email.service';
import { GuestDetailsService } from '../../services/guest-details.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
    selector: 'rmc-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

    breadcrumbs: Array<Breadcrumb> = [
        {
            label: 'Extras',
            selected: false,
            url: '/configure',
        },
        {
            label: 'Details',
            selected: false,
            url: '/guest-details',
        },
        {
            label: 'Review',
            selected: false,
            url: '/review',
        },
        {
            label: 'Payment',
            selected: false,
            url: '/payment',
        },
        {
            label: 'Confirmation',
            selected: false,
            url: '/confirmation',
        },
    ];

    isConfirmation = false;
    isReady = false;
    saveUrl: string;

    constructor(
        private appService: AppService,
        private bookingService: BookingService,
        private emailService: EmailService,
        private guestDetailsService: GuestDetailsService,
        public navigationService: NavigationService,
        private router: Router,
    ) { }

    async ngOnInit() {
        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                this.initUrl();
            }
        });

        await this.appService.onInit();
        this.isReady = true;

        const serviceCode: string = this.bookingService.getServiceCode();
        this.saveUrl = `${environment.communitiesUrl}${environment.savedTripsPath}save-trip?refcode=${serviceCode}`;

        this.initUrl();
    }

    isActive(breadcrumb: Breadcrumb): boolean {
        if (this.isConfirmation) {
            return breadcrumb.url === '/confirmation';
        }

        switch (breadcrumb.url) {
            case '/configure':
            case '/guest-details':
                return true;
            case '/review':
                return !this.guestDetailsService.requiresAccessibilityAssistance() && this.guestDetailsService.isDetailsFilled();
            default:
                return false;
        }
    }

    private initUrl(): void {
        this.isConfirmation = this.navigationService.isRoute('/confirmation');

        this.breadcrumbs.forEach((breadcrumb: Breadcrumb) => {
            breadcrumb.selected = this.navigationService.isRoute(breadcrumb.url);
        });
    }

    open(breadcrumb: Breadcrumb): void {
        if (this.isReady && this.isActive(breadcrumb)) {
            this.navigationService.navigate(breadcrumb.url);
        }
    }

    openEmailModal(): void {
        this.emailService.openTripModal();
    }

    trackBreadcrumb(index: number, breadcrumb: Breadcrumb): string {
        return breadcrumb.url;
    }
}
