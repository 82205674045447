<rmc-modal *ngIf="isOpen" (close)="closeModal()">
    <div class="rmc-modal__header">
        Email Plan to a friend
    </div>
    <div class="rmc-modal__body">
        <div class="rmc-modal__message-text">
            <div class="slds-m-bottom--large">
                We will send an email to your friend with the itinerary reflecting any selections you have made.<br>
                NOTE: Emailing will not save your plan and the email will not reflect any changes made after sending.
            </div>

            <div class="slds-m-bottom--large">
                <rmc-form-element
                    [disabled]="isSaving"
                    [errors]="errors?.recipient_email"
                    label="Friend’s Email"
                    [required]="emailRequiredSchema.recipient_email"
                    [submitted]="submitted"
                    [value]="emailData.recipient_email"
                >
                    <input
                        class="rmc-input"
                        name="recipient_email"
                        type="text"
                        [disabled]="isSaving"
                        [(ngModel)]="emailData.recipient_email"
                        (ngModelChange)="onChange('recipient_email')"
                    />
                </rmc-form-element>
            </div>

            <div class="slds-m-bottom--large">
                <rmc-form-element
                    [disabled]="isSaving"
                    [errors]="errors?.recipient_name"
                    label="Friend’s Name"
                    [required]="emailRequiredSchema.recipient_name"
                    [submitted]="submitted"
                    [value]="emailData.recipient_name"
                >
                    <input
                        class="rmc-input"
                        name="recipient_name"
                        type="text"
                        [disabled]="isSaving"
                        [(ngModel)]="emailData.recipient_name"
                        (ngModelChange)="onChange('recipient_name')"
                    />
                </rmc-form-element>
            </div>

            <div class="slds-m-bottom--large">
                <rmc-form-element
                    [disabled]="isSaving"
                    [errors]="errors?.sender_email"
                    label="Your Email"
                    [required]="emailRequiredSchema.sender_email"
                    [submitted]="submitted"
                    [value]="emailData.sender_email"
                >
                    <input
                        class="rmc-input"
                        name="sender_email"
                        type="text"
                        [disabled]="isSaving"
                        [(ngModel)]="emailData.sender_email"
                        (ngModelChange)="onChange('sender_email')"
                    />
                </rmc-form-element>

                <div class="input-hint">
                    Your Email will not be shared with any third party and will remain confidential to Rocky Mountaineer
                </div>
            </div>

            <div class="slds-m-bottom--large">
                <rmc-form-element
                    [disabled]="isSaving"
                    [errors]="errors?.sender"
                    label="Your Name"
                    [required]="emailRequiredSchema.sender"
                    [submitted]="submitted"
                    [value]="emailData.sender"
                >
                    <input
                        class="rmc-input"
                        name="sender"
                        type="text"
                        [disabled]="isSaving"
                        [(ngModel)]="emailData.sender"
                        (ngModelChange)="onChange('sender')"
                    />
                </rmc-form-element>
            </div>

            <div class="slds-m-bottom--large">
                <rmc-form-element
                    [disabled]="isSaving"
                    [errors]="errors?.custom_message"
                    label="Optional comment to add to Email"
                    [required]="emailRequiredSchema.custom_message"
                    [submitted]="submitted"
                    [value]="emailData.custom_message"
                >
                    <textarea
                        class="rmc-textarea"
                        name="custom_message"
                        [disabled]="isSaving"
                        [(ngModel)]="emailData.custom_message"
                        (ngModelChange)="onChange('custom_message')"
                    ></textarea>
                </rmc-form-element>
            </div>
        </div>

        <div class="actions slds-m-bottom--medium">
            <button class="rmc-modal__message-action rmc-button rmc-button--neutral" (click)="closeModal()">Close</button>

            <button class="rmc-modal__message-action rmc-button rmc-button--brand" (click)="submit()">
                <rmc-button-bouncer *ngIf="isSaving"></rmc-button-bouncer>
                <span *ngIf="!isSaving">Send Email</span>
            </button>
        </div>

        <div class="recaptcha-branding slds-m-bottom--medium">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>

        <div *ngIf="isRecaptchaOpen">
            <div class="rmc-backdrop"></div>
            <div class="recaptcha"></div>
        </div>
    </div>
</rmc-modal>
