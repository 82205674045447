import { Injectable } from '@angular/core';

import { PrePost, Transfer, TransferDetails } from '../helpers/extensions';
import { ServiceItem } from '../helpers/itinerary';
import { Product, ProductOption } from '../helpers/products';
import { Selections } from '../helpers/selections';

import { BookingService } from '../services/booking.service';
import { ItineraryService } from '../services/itinerary.service';
import { ProductsService } from '../services/products.service';
import { PromotionsService } from '../services/promotions.service';

@Injectable({
    providedIn: 'root'
})
export class TransferService {

    private accommodations: Array<string>;
    private accommodationsPromise: Promise<void>;

    constructor(
        private bookingService: BookingService,
        private itineraryService: ItineraryService,
        private productsService: ProductsService,
        private promotionsService: PromotionsService,
    ) { }

    /**
     * Returns transfer details from transfer selection.
     * @param transfer Transfer selection object.
     * @returns Transfer details object.
     */
    getDetailsFromSelection(transfer: Transfer): TransferDetails {
        const transferDetails: TransferDetails = {
            flightNumber: transfer.flight_number || '',
            notBooked: 'is_flight_booked' in transfer ? !transfer.is_flight_booked : false,
        };

        if (transfer.date) {
            [transferDetails.date, transferDetails.time] = transfer.date.split('T');
        }

        return transferDetails;
    }

    /**
     * Returns transfer options.
     * @returns Pre-transfer and post-transfer options.
     */
    async getOptions(): Promise<PrePost<Array<ProductOption>>> {
        const transferMap: Map<string, Product> = await this.getTransferMap();
        const transferOptions: PrePost<Array<ProductOption>> = {
            post: this.getTransferOptions('post', transferMap),
            pre: this.getTransferOptions('pre', transferMap),
        };

        return transferOptions;
    }

    /**
     * Returns a title for the post-transfer section.
     * @returns Title for the post-transfer section.
     */
    getPostTransferTitle(): string {
        const hotelTitle: string = this.accommodations.length ? ` from ${this.accommodations[this.accommodations.length - 1]}` : '';

        return `Transfer${hotelTitle} to airport`;
    }

    /**
     * Returns a title for the pre-transfer section.
     * @returns Title for the pre-transfer section.
     */
    getPreTransferTitle(): string {
        const hotelTitle: string = this.accommodations.length ? ` to ${this.accommodations[0]}` : '';

        return `Transfer from airport${hotelTitle}`;
    }

    /**
     * Returns product option IDs for the transfer type.
     * @param type 'pre_transfer'|'post_transfer'
     * @returns List of product IDs.
     */
    private getTransferProductIds(type: string): Array<string> {
        const choices: any = this.bookingService.getChoices();
        const ids: Set<string> = new Set<string>();
        for (const choice of choices[type]) {
            const selections: Selections = choice.selections;
            if (selections[type]) {
                ids.add(selections[type].id);
            }
        }

        return Array.from(ids);
    }

    /**
     * Returns <Transfer ID, Transfer Product> map.
     * @returns <Transfer ID, Transfer Product> map.
     */
    private async getTransferMap(): Promise<Map<string, Product>> {
        const preTransfers: Array<string> = this.getTransferProductIds('pre_transfer');
        const postTransfers: Array<string> = this.getTransferProductIds('post_transfer');
        const transferIds: Array<string> = Array.from(new Set([
            ...preTransfers,
            ...postTransfers,
        ]));
        const transfers: Array<Product> = transferIds.length ? (await this.productsService.getProducts(transferIds)) : [];
        const transferMap: Map<string, Product> = new Map<string, Product>();

        for (const transfer of transfers) {
            transferMap.set(transfer.id, transfer);
        }

        return transferMap;
    }

    /**
     * Returns transfer options for the transfer type.
     * @param type 'pre'|'post'
     * @param transferMap <Transfer ID, Transfer Product> map.
     * @returns List of transfer options.
     */
    private getTransferOptions(type: string, transferMap: Map<string, Product>): Array<ProductOption> {
        const key = `${type}_transfer`;
        const choices: any = this.bookingService.getChoices();
        const optionMap: Map<string, ProductOption> = new Map<string, ProductOption>();
        const promotionalTransfer: string = this.promotionsService.getTransferSelection();

        for (const choice of choices[key]) {
            const price: any = choice.price.prices_by_types[key];
            const selections: Selections = choice.selections;
            const transfer: Product = selections[key] ? transferMap.get(selections[key].id) : null;

            if (transfer) {
                optionMap.set(transfer.id, {
                    price: price.service + price.fee + price.promotion,
                    product: transfer,
                });
            } else if (type !== promotionalTransfer) {
                optionMap.set(null, {
                    price: 0,
                    product: null,
                });
            }
        }

        const options: Array<ProductOption> = Array.from(optionMap.values());

        return options;
    }

    /**
     * Initializes accomodation list to generate transfer titles.
     */
    async initAccommodations(): Promise<void> {
        this.accommodationsPromise = this.accommodationsPromise || this.itineraryService.getServiceItems().then((serviceItems: Array<ServiceItem>) => {
            this.accommodations = serviceItems.filter((serviceItem: ServiceItem): boolean => {
                return serviceItem.item_type.code === 'Accommodation';
            }).map((serviceItem: ServiceItem): string => {
                return serviceItem.item.external_name;
            });
        });

        await this.accommodationsPromise;
    }
}
